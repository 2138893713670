
import { toDate } from 'date-fns-tz';

import { LANG_DEFAULT_CODE } from '../constants/languages';
import { 
	COUPON_DISCOUNT_TYPE_FIXED, 
	COUPON_DISCOUNT_TYPE_PERCENTAGE, 
} from '../constants/promotions';
import { 
	STORAGE_USER_BUYLIST_CART,
	STORAGE_USER_CART,
} from '../constants/storage';
import {
	TX_CART_STATUS_ABANDONED,
	TX_CART_STATUS_ACTIVE,
	TX_CART_STATUS_COMPLETE,
	TX_CART_STATUS_INACTIVE,
	TX_null,
} from '../constants/strings';

// import { Order, OrderItemGrade } from './orders';
import {
	Product,
	Inventory,
} from './products';
import { Coupon } from './promotions';
import { User } from './users';

import { dateDelta } from '../utils/general';

import * as storage from '../persistance/storage';


export class Cart {

	constructor(props) {

		const { Order } = require('./orders');
		
		if(!props) { 
			props = {}; 
		}

		this.ABANDONED_THRESHOLD = 7;  // In days

		this.id = props.id || null;
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.isArchived = props.isArchived || props.is_archived || false;
		this.hasMerged = props.hasMerged || props.has_merged || false;
		this.lastUpdate = null;

		const lastUpdateValue = props.lastUpdate || props.last_update || null;
		if(lastUpdateValue) {
			this.lastUpdate = lastUpdateValue instanceof Date ? lastUpdateValue : toDate(lastUpdateValue);
		}

		const cartItems = [];
		const itemsArray = props.items || [];
		for(const item of itemsArray) {
			cartItems.push(new CartItem(item));
		}
		this.items = cartItems;

		const couponObj = props.coupon || null;
		this.coupon = couponObj ? new Coupon(couponObj) : null;

		const userObj = props.user || null;
		this.user = userObj ? new User(userObj) : null;

		const orderObj = props.order || null;
		this.order = orderObj ? new Order(orderObj) : null;
	}

	get status() {

		if(this.order) {
      return TX_CART_STATUS_COMPLETE;
    } 

    if(!this.lastUpdate) { 
    	// No status if no order and no update
    	return TX_null; 
    }

    const today = new Date();
    if(this.isArchived === false && dateDelta(this.lastUpdate, this.ABANDONED_THRESHOLD) >= today) {
      return TX_CART_STATUS_ACTIVE;
    } else if(this.isArchived === false && dateDelta(this.lastUpdate, this.ABANDONED_THRESHOLD) < today) {
      return TX_CART_STATUS_ABANDONED;
    } else {
      return TX_CART_STATUS_INACTIVE;
    }
	}

	get isBuylist() {
		return false;
	}

	isEmpty() {
		return this.items.length === 0;
	}

	toJson() {
		const itemsArray = [];
		for(const item of this.items) {
			itemsArray.push(item.toJson());
		}

		return {
			id: this.id,
			publicUuid: this.publicUuid,
			items: itemsArray,
		};
	}

	saveLocal() {
		storage.put(STORAGE_USER_CART, this.toJson());
	}

	hasPreorderItems() {
		for(const item of this.items) {
			if(!item.product.isReleased) {
				return true;
			}
		}
		return false;
	}

	static getLocal() {
		const cartJson = storage.get(STORAGE_USER_CART);
		if(!cartJson) {
			return new Cart();
		}
		return new Cart(cartJson);
	}

	getOrderedItems() {
		const sortedItems = [ ...this.items ];

		sortedItems.sort((a, b) => {
		  // First compare by product line name
		  const productLineNameComparison = a.product.productLine.name.localeCompare(b.product.productLine.name, 'en', { sensitivity: 'base' });
		  if (productLineNameComparison !== 0) return productLineNameComparison;

		  // Then compare by setName
		  const setNameComparison = a.product.setName.localeCompare(b.product.setName, 'en', { sensitivity: 'base' });
		  if (setNameComparison !== 0) return setNameComparison;

		  // If setName is the same, compare by name (case insensitive)
		  return a.product.name.localeCompare(b.product.name, 'en', { sensitivity: 'base' });
		});

		return sortedItems;
	}

	getInventoryQuantity(inventoryObj) {
		try {

			if(!this.items || !inventoryObj) { return 0; }
			for(const item of this.items) {
				if(item.inventory.id === inventoryObj.id) {
					return item.quantity;
				}
			}
			return 0;
		} catch(err) {
			return 0;
		}
	}

	get quantity() {
		let qty = 0;
		for(const item of this.items) {
			qty = qty + item.quantity;
		}
		return qty;
	}

	get subTotal() {
		let price = 0.0;
		for(const item of this.items) {
			if(item.inventory && item.inventory.sellPrice) {
				price = price + (item.quantity * item.inventory.sellPrice);
			}
		}
		return price;
	}

	get taxableTotal() {
		// This isn't necessarily the final taxable total, just that from the cart.  Be careful when using to know if more (ie shipping) need to be added
		return this.subTotal - this.couponDiscount > 0 ? this.subTotal - this.couponDiscount : 0;
	}

	get couponDiscount() {
		if(!this.coupon) { return 0; }

		if(this.coupon.discountType === COUPON_DISCOUNT_TYPE_FIXED) {
			return this.coupon.discountAmount;
		}

		if(this.coupon.discountType === COUPON_DISCOUNT_TYPE_PERCENTAGE) {
			return this.subTotal * (this.coupon.discountAmount/100);
		}

		return 0;
	}

	get lineItemCount() {
		return this.items.length;
	}
}

export class BuylistCart extends Cart {

	constructor(props) {	
		if(!props) { props = {}; }
		super(props);

		this.gradedTotal = parseFloat(props.gradedTotal) || parseFloat(props.graded_total) || null;
	}

	saveLocal() {
		storage.put(STORAGE_USER_BUYLIST_CART, this.toJson());
	}

	static getLocal() {
		const cartJson = storage.get(STORAGE_USER_BUYLIST_CART);
		if(!cartJson) {
			return new BuylistCart();
		}
		return new BuylistCart(cartJson);
	}

	get isBuylist() {
		return true;
	}

	get subTotal() {

		// Be careful calling this, as it should only get the subtotal pre-checkout in case prices change
		// For order totals, use the order model with the totals there 

		let price = 0.0;
		for(const item of this.items) {
			if(item.inventory && item.inventory.buyPrice) {
				price = price + (item.quantity * item.inventory.buyPrice);
			}
		}
		return price;
	}

	get totalGraded() {
		if(this.gradedTotal === null) {
			return this.subTotal;
		}
		return this.gradedTotal;
	}
}

export class CartItem {

	constructor(props) {

		const { OrderItemGrade } = require('./orders');
		
		if(!props) { 
			props = {}; 
		}

		this.id = props.id || null;
		this.price = parseFloat(props.price) || 0;
		this.quantity = parseInt(props.quantity) || 1;
		this.status = props.status || '';
		this.isPreorder = props.isPreorder || props.is_preorder || false;

		this.inventory = new Inventory(props.inventory || {});
		this.product = new Product(props.product || {});

		const gradesArray = props.grades || props.item_gradings ||[];
		const gradesModels = [];
		for(const gr of gradesArray) {
			gradesModels.push(new OrderItemGrade(gr));
		}
		this.grades = gradesModels;
	}

	isPreorderItem() {
		return this.isPreorder || this.product.isReleased;
	}

	shouldDisplayLanguage() {
    if(this.inventory.language && this.inventory.language.code !== LANG_DEFAULT_CODE) {
      return true;
    }
    if(this.product.foreignModel && this.product.foreignModel.alwaysDisplayLanguage) {
    	return true;
    }
    return false;
  }

  shouldDisplayFinish() {
    return this.product.hasFinish();
  }

  shouldDisplayCondition() {
    if(this.inventory.isSealed !== true) {
      return true;
    }
    return false;
  }

  shouldDisplayPrinting() {
    return this.product.hasPrinting();
  }

	toJson() {
		return {
			id: this.id,
			price: this.price,
			quantity: this.quantity,
			status: this.status,
			inventory: this.inventory,
			product: this.product,
		};
	}
}