import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MENU_BUY } from '../../../constants/menu';
import { TX_null } from '../../../constants/strings';

import useHeaderMenu from '../../../hooks/useHeaderMenu';

import styles from './_styles/HeaderShopMenu.module.scss';

const HeaderShopMenu = (props) => {

  // Internationalization
  const { t } = useTranslation();

  // Props
  const {
    cssBreak = 0,
  } = props;

  // Refs
  const menuBodyRef = useRef(null);

  // Hooks
  const {
    menu, 
    menuComponent, 
    closeMenu,
  } = useHeaderMenu();

  // Methods
  const menuOpen = useCallback(() => {
    const availableMenus = [
      MENU_BUY,
    ];
    return menu && availableMenus.map(mn => mn.key).includes(menu.key);
  }, [ menu ]);

  // Effects
  useEffect(() => {

    const checkClick = (evt) => {
      if (window.innerWidth > cssBreak && menuOpen()) {
        let targetElement = evt.target;
        do {
          if (targetElement === menuBodyRef.current) {
            return;
          }
          targetElement = targetElement.parentNode;
        } while (targetElement);
        closeMenu();
      }
    };

    document.addEventListener('click', checkClick, false);
    return () => {
      document.removeEventListener('click', checkClick, false);
    };
  }, [ closeMenu, cssBreak, menuOpen ]);

  return (
    <div className={styles.HeaderShopMenu}>
      <div
        className={`${styles.outerWrapper} ${menuOpen() ? styles.open : ''}`}
        ref={menuBodyRef}>
        <div className={styles.menuTitleWrapper}>
          {menu && menu.url ?
            <Link to={menu.url} onClick={closeMenu}>
              <div className={styles.menuTitle}>{menu ? t(menu.title || TX_null) : ''}</div>
            </Link> :
            <div className={styles.menuTitle}>{menu ? t(menu.title || TX_null) : ''}</div>
          }
        </div>
        <div className={styles.innerWrapper}>{menuComponent({ noTitle: true })}</div>
        <div className={styles.menuFooter}></div>
      </div>
    </div>
  );
};

export default HeaderShopMenu;