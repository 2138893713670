import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';
import { URL_PLAY_PRODUCT_LINE } from '../../../constants/urls';

import { stringFormat } from '../../../utils/formatting';
import { getOrderedPlayMenu } from '../../../utils/product';

import '../_styles/_headersubmenu.scss';

import * as headerActionCreators from '../../../actions/header';
let allActionCreators = Object.assign({}, headerActionCreators);

export class HeaderSubmenuPlay extends Component {

  getMenuLinks() {
    return getOrderedPlayMenu(this.props.productLine.menus);
  }

  closeMenu() {
    this.props.headerSetMenu(null);
  }

  render() {

  	const {t} = this.props;
    const links = this.getMenuLinks();
  	
    return <div className={'HeaderSubmenuPlay HeaderSubmenuCore'}>
      <div className='humSection'>
        <div className='humSectionTitle' style={this.props.hideTitle ? { display: 'none' } : {}}>{t(tx.TX_PLAY)}</div>
        <div className='humSectionBody'>
          {links.length === 0 ?
            <div className='noLinksItem'>{t(tx.TX_MENU_NO_ITEMS)}</div> :
            <>
              {links.map((link, i) => {
                return <NavLink 
                        key={i}
                        to={stringFormat(URL_PLAY_PRODUCT_LINE, { productLine: link.permalink })} 
                        className='humSectionLink' 
                        onClick={this.closeMenu.bind(this)}>
                          {link.name}
                       </NavLink>;
              })}
            </>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderSubmenuPlay));


