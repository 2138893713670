import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isLoggedIn } from '../../../utils/auth';

import AccountLoggedIn from './AccountLoggedIn';
import AccountLoggedOut from './AccountLoggedOut';

import styles from './_styles/HeaderElementAccount.module.scss';

let allActionCreators = Object.assign({});

export class HeaderElementAccount extends Component {

  render() {
    return <div className={styles.HeaderElementAccount}>
      {isLoggedIn() ?
        <AccountLoggedIn /> :
        <AccountLoggedOut />
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderElementAccount));


