import React from 'react';
import { Link } from 'react-router-dom';

import { URL_HOME } from '../../../constants/urls';

import { storeLogoUrl } from '../../../utils/website-config';

import styles from './_styles/HeaderElementLogo.module.scss';

export const HeaderElementLogo = () => {
  
  // Render
  return (
    <div className={styles.HeaderElementLogo}>
      <Link 
        to={URL_HOME}
        className={styles.logoLink}>
        <div 
          className={styles.logoElement} 
          data-testid='logo-element'
          style={{ backgroundImage: `url('${storeLogoUrl()}')` }} />
      </Link>
    </div>
  );
};

export default HeaderElementLogo;




