import React, { Component } from "react";
import { connect } from "react-redux";

import { CSS_TOGGLE_DURATION } from '../../constants/css';

import './style/_screen.scss';

let allActionCreators = Object.assign({});

export class MenuScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,
    };
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
  }

  componentDidUpdate(prevProps, prevState) {

    const shouldBeEnabled = this.shouldEnable();

    // Check cart open
    // To be implemented when cart droor implemented

    if(shouldBeEnabled === true && this.state.enabled === false) {
      this.enableScreen();
    } else if(shouldBeEnabled === false && this.state.enabled === true) {
      this.disableScreen();
    }
  }

  shouldEnable() {
    return !!this.props.header.menuActive || this.props.cart.minicartMenuScreenActive;
  }

  enableScreen() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, CSS_TOGGLE_DURATION / 4);
      document.body.style.overflow = 'hidden';
    });
  }

  disableScreen() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          shouldDisplay: false,
        }, () => {
          if(this.shouldEnable()) {
            this.enableScreen();
          }
        });
      }, CSS_TOGGLE_DURATION);
    });
  }

  getScreenClass() {
    let screenClass = 'MenuScreen';

    if(this.state.hasOpacity) {
      screenClass += ' withOp';
    }

    if(this.state.shouldDisplay) {
      screenClass += ' disp';
    }

    return screenClass;
  }

  render() {
    return <div className={this.getScreenClass()}></div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    header: state.header,
  };
}

export default connect(
  mapStateToProps,
  allActionCreators
)(MenuScreen);