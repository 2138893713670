import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as p from '../../../constants/permissions';
import * as tx from '../../../constants/strings';
import {
  URL_ACCOUNT,
  URL_ACCOUNT_ORDERS,
  URL_ACCOUNT_STORE_CREDIT,
  URL_ADMIN_CUSTOMERS, 
  URL_ADMIN_DASHBOARD, 
  URL_ADMIN_EVENTS, 
  URL_ADMIN_INVENTORY, 
  URL_ADMIN_MARKETING, 
  URL_ADMIN_ORDERS, 
  URL_ADMIN_SETTINGS, 
  URL_HOME, 
} from '../../../constants/urls';

import { logout } from '../../../utils/auth';
import { hasPermission, isAdmin } from '../../../utils/permissions';

import '../_styles/_headersubmenu.scss';

import * as headerActionCreators from '../../../actions/header';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, headerActionCreators, userActionCreators);

export class HeaderSubmenuUser extends Component {

	logoutUser() {
		this.props.usersLogout()
    .then(() => {
      window.location.href = URL_HOME;
    })
    .catch((errResp) => {
      console.error(errResp);
      window.location.href = URL_HOME;
    });
		logout();
	}

	render() {

		const {t} = this.props;
	
    return <div className={'HeaderSubmenuUser HeaderSubmenuCore'}>
      {isAdmin() ?
        <div className='humSection'>
          <div className='humSectionTitle'>{t(tx.TX_MENU_SECTION_MANAGE)}</div>
          <div className='humSectionBody'>
            <NavLink to={URL_ADMIN_DASHBOARD} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_DASHBOARD)}</NavLink>
            {hasPermission(p.P_MANAGE_SALES) ?
              <NavLink to={URL_ADMIN_ORDERS} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_ORDERS)}</NavLink> :
              null
            }
            {hasPermission(p.P_MANAGE_PRODUCTS) ?
              <NavLink to={URL_ADMIN_INVENTORY} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_CATALOG)}</NavLink> :
              null
            }
            {hasPermission(p.P_MANAGE_USERS) ?
              <NavLink to={URL_ADMIN_CUSTOMERS} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_CUSTOMERS)}</NavLink> :
              null
            }
            {hasPermission(p.P_MANAGE_EVENTS) ?
              <NavLink to={URL_ADMIN_EVENTS} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_MENU_ITEM_MANAGEEVENTS)}</NavLink> :
              null
            }
            {hasPermission(p.P_MANAGE_SALES) ?
              <NavLink to={URL_ADMIN_MARKETING} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_MARKETING)}</NavLink> :
              null
            }
            {hasPermission(p.P_MANAGE_PERMISSIONS) ?
              <NavLink to={URL_ADMIN_SETTINGS} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_SETTINGS)}</NavLink> :
              null
            }
          </div>
        </div> :
        null
      }

      <div className='humSection'>
        <div className='humSectionTitle'>{t(tx.TX_MENU_SECTION_ACCOUNT)}</div>
        <div className='humSectionBody'>
          <NavLink to={URL_ACCOUNT} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_MENU_ITEM_ACCOUNTDETAILS)}</NavLink>
          <NavLink to={URL_ACCOUNT_ORDERS} className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_MENU_ITEM_ORDERHISTORY)}</NavLink>
          <NavLink to={URL_ACCOUNT_STORE_CREDIT}  className='humSectionLink' onClick={() => this.props.headerSetMenu(null)}>{t(tx.TX_MENU_ITEM_STORECREDIT)}</NavLink>
          <div className='humSectionLink' onClick={this.logoutUser.bind(this)}>{t(tx.TX_LOGOUT)}</div>
        </div>
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderSubmenuUser));


