import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Papa from 'papaparse';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../../../constants/api';
// import { TC_HEADER_SORT_DIR_ASC } from '../../../../constants/tables';

import { getStoreLanguage } from '../../../../utils/language';
import { 
  getPageLimit, 
  getPageOffset,
} from '../../../../utils/request';
import * as tx from '../../../../constants/strings';

import LoadingIcon from '../../../Icons/LoadingIcon';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class BulkActionExportOrders extends Component {

  constructor(props) {
    super(props);

    this.state = {
      downloadPending: false,
      downloadData: [],
      downloadType: null,
    }

    this.DOWNLOAD_SELECTED = 'selected';
    this.DOWNLOAD_FILTERED = 'filtered';

    this.controller = null;
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getSortApiKey() {

    const { TC_HEADER_SORT_DIR_ASC } = require('../../../../constants/tables');
    
    if(this.props.sortDir === TC_HEADER_SORT_DIR_ASC) {
      return this.props.sortKey;
    } else {
      return `-${this.props.sortKey}`;
    }
  }

  getResultsCopy(resultsCount) {
    const t = this.props.t;
    try {
      if(resultsCount === 0) {
        return t(tx.TX_GALLERY_NO_RESULTS);
      } else if(resultsCount === 1) {
        return t(tx.TX_RESULTS_ONE);
      } else if(resultsCount > 0) {
        return t(tx.TX_RESULTS_NUM, { num: resultsCount.toLocaleString(this.getLanguage()) });
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  async fetchDownloadSlug(downloadType, pageNum) {

    const pageSize = downloadType === this.DOWNLOAD_SELECTED ? 20 : 100;

    const downloadData = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
      [API_KEY_SORT]: this.getSortApiKey(),
      is_export: 'True',
    };

    if(downloadType === this.DOWNLOAD_SELECTED) {

      const orderUuids = [];
      for(const odr of this.props.selectedRows) {
        orderUuids.push(odr.publicUuid);
      }
      downloadData['uuids'] = orderUuids.join(',');

    } else if(downloadType === this.DOWNLOAD_FILTERED) {

      const combinedFilters = Object.assign({}, this.props.filters, this.props.staticFilters);

      for(const ft in combinedFilters) {
        downloadData[ft] = combinedFilters[ft];
      }
    }

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    const downloadResp = await this.props.ordersAdminFetchPage(downloadData, controller.signal)
      .catch((errResp) => {

        if(controller.signal.aborted) { return null; }
        
        console.error(errResp);
        this.setState({
          downloadPending: false,
        });
      });

    if(!downloadResp) { return null; }

    return downloadResp || null;
  }

  async initiateDownload(downloadType) {

    if(!downloadType) { return null; }

    this.setState({
      downloadData: [],
      downloadPending: true,
      downloadType: downloadType,
    });

    const lines = [];
    const initData = await this.fetchDownloadSlug(downloadType, 1);

    if(!initData) { return null; }

    for(const ln of initData.data) {
      lines.push(ln.exportLine(this.props.t, this.getLanguage()));
    }

    if(initData.count > initData.data.length) {

      for(let i = 2; i <= Math.ceil(initData.count / initData.data.length); i++) {
        const newData = await this.fetchDownloadSlug(downloadType, i);

        if(!newData) { break; }
        for(const ln of newData.data) {
          lines.push(ln.exportLine(this.props.t, this.getLanguage()));
        }
      }
    }

    this.setState({
      downloadData: lines,
      downloadPending: false,
    });
  }

  downloadAction() {
    if(this.state.downloadPending || !this.state.downloadData) { return null; }

    const csvLines = [];
    for(const dataLine of this.state.downloadData) {
      csvLines.push(dataLine);
    }

    const now = Date.now();
    const template = Papa.unparse(csvLines);
    const templateData = new Blob([ template ], { type: 'text/csv;charset=utf-8;' });
    const templateURL = navigator.msSaveBlob ? navigator.msSaveBlob(templateData, `data-${this.state.downloadType}-${now}.csv`) : window.URL.createObjectURL(templateData);

    let tempLink = document.createElement('a');
    tempLink.href = templateURL;
    tempLink.setAttribute('download', `data-${this.state.downloadType}-${now}.csv`);
    tempLink.click();
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkActionExportOrders BulkActionModal'}>
      <div className='baWrapper'>
        
        {this.state.downloadType === null ?
          <div className='selectionSection'>
            <div className='exportBlock'>
              <div className='exportBlockLiner'>
                <button 
                  type='button'
                  disabled={this.props.selectedRows && this.props.selectedRows.length === 0}
                  className='exportButton'
                  onClick={() => this.initiateDownload(this.DOWNLOAD_SELECTED)}>
                  {t(tx.TX_EXPORT_SELECTED)}
                </button>
                <div className='exportResultsWrapper'>{this.getResultsCopy(this.props.selectedRows.length)}</div>
              </div>
            </div>
            <div className='exportBlock'>
              <div className='exportBlockLiner'>
                <button 
                  type='button'
                  disabled={this.props.resultsCount === 0}
                  className='exportButton'
                  onClick={() => this.initiateDownload(this.DOWNLOAD_FILTERED)}>
                  {t(tx.TX_EXPORT_FILTERED)}
                </button>
                <div className='exportResultsWrapper'>{this.getResultsCopy(this.props.resultsCount)}</div>
              </div>
            </div>
          </div> :
          <>
            {this.state.downloadPending ?
              <div className='pendingSection'>
                <div className='loadingBody'>
                  <div className='loadingIconLabel'>{t(tx.TX_INV_BULK_UPLOAD_DOWNLOAD_PREPARING)}</div>
                  <div className='loadingIconWrapper'>
                    <LoadingIcon />
                  </div>
                </div>
              </div> :
              <div className='availableSection'>
                <div className='completeBody'>
                  <div className='completeLabel'>{t(tx.TX_INV_BULK_UPLOAD_DOWNLOAD_READY)}</div>
                  <button className={'completeAction'} type='button' onClick={this.downloadAction.bind(this)}>{t(tx.TX_DOWNLOAD)}</button>
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkActionExportOrders));