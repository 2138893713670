

import { 
	STORE_CODE,
	STORE_WEBSITE_CONFIGS, 
} from '../constants/store'; 








export function storeHeaderComponent() {
	try {
		if(STORE_WEBSITE_CONFIGS[STORE_CODE] && STORE_WEBSITE_CONFIGS[STORE_CODE].header) {
			return STORE_WEBSITE_CONFIGS[STORE_CODE].header;
		}
	} catch(err) {
		console.error(err);
	}
	return null;
}

export function storeLogoUrl() {
    return `/store/${STORE_CODE || 'default'}/logo/logo.png`;
}







