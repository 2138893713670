import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import StaticImage from '../Image/StaticImage';
import ProgressiveImage from '../Image/ProgressiveImage';

import './style/_lorcana.scss';

let allActionCreators = Object.assign({});

export class LorcanaImage extends Component {

  render() {

    const {t} = this.props;
    const { LorcanaCardImages } = require('../../models/syncs/lorcana');

    // The thumbnail uses primary since most lorcana doesn't have thumbnail

    return <div className={'LorcanaImage'}>      
      <div className='lorcanaObjImageWrapper'>
        {this.props.thumbnail ?
          <StaticImage
            imgObj={{
              src: this.props.lorcanaObj.primaryImageSrc(), 
              alt: this.props.lorcanaObj.localizedName, 
              noTranslate: true,
            }}
            onImgLoad={this.props.onLoadLow} /> :
          <ProgressiveImage
            alt={this.props.lorcanaObj.imageAlt(t)}
            className='lorcanaObjImageImg'
            src={this.props.lorcanaObj.primaryImageSrc()}
            thumbnailSrc={this.props.lorcanaObj.thumbnailImageSrc()}
            loadDelay={this.props.loadDelay}
            placeholder={LorcanaCardImages.defaultThumbnailSrc()}
            onLoadLow={this.props.onLoadLow} />
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(LorcanaImage));