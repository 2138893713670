
import * as x from '../constants/actions';
import { CSS_SLIDE_DURATION } from '../constants/css';

const initialState = {
  menuActive: null,
  menuLastToggle: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_HEADER_SET_MENU:
      return headerSetMenu(state, action)
    default:
      return state;
  }
}


function headerSetMenu(state, action) {

  const toggling = !!state.menuActive !== !!action.payload;

  const ignoreTimeout = action.config ? !!action.config.ignoreTimeout : false;

  if(!ignoreTimeout && toggling && Date.now() < (CSS_SLIDE_DURATION + state.menuLastToggle)) {
    return Object.assign({}, state);
  }

  if(toggling) {
    return Object.assign({}, state, {
      menuActive: action.payload,
      menuLastToggle: Date.now(),
    });
  } else {
    return Object.assign({}, state, {
      menuActive: action.payload,
    });
  }
}