import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_USER } from '../../../constants/icons';
import { MENU_TYPE_USER } from '../../../constants/menu';

import HeaderHelloUser from './HeaderHelloUser';
import Icon from '../../Icons/Icon';

import styles from './_styles/AccountLoggedIn.module.scss';

import * as headerActionCreators from '../../../actions/header';
const allActionCreators = Object.assign({}, headerActionCreators);

export class AccountLoggedIn extends Component {

  handleClick() {
    this.props.headerSetMenu(MENU_TYPE_USER);
  }

  render() {
    return <div className={styles.AccountLoggedIn} onClick={this.handleClick.bind(this)}>
      <div className={styles.humWrapper}>
        <div className={styles.humBody}>
          <HeaderHelloUser />
        </div>
        <div className={styles.humArrow}>
          <div className={styles.arrowWrapper}></div>
        </div>
      </div>
      <div className={styles.mobileWrapper}>
        <Icon
          value={ICON_USER}
          iconClass={styles.iconClass} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountLoggedIn));


