import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../../../store';

import {
  CHECKOUT_STEP_AUTH,
  CHECKOUT_STEP_SELECT_PAYMENT_DETAILS,
  CHECKOUT_STEP_SHIPPING_ADDRESS,
} from '../../../../../constants/checkout';
import {
  ERROR_PAYMENT_METHOD_NOT_LOADED, 
} from '../../../../../constants/errors';
import * as tx from '../../../../../constants/strings';
import { 
  URL_CONTACT_US,
  URL_SUCCESS,
} from '../../../../../constants/urls';

// import { Order } from '../../../../../models/orders';
// import { SquareCCPaymentMethod } from '../../../../../models/payment-methods';

import {
  getAuthObj,
  isLoggedIn,
} from '../../../../../utils/auth';
import { 
  checkoutGetIsStoreCreditApplied,
  checkoutGetSelectedPaymentMethod,
  checkoutGetSelectedShippingMethod,
  getCartGrandTotal, 
  getCheckoutStepData, 
} from '../../../../../utils/checkout';
import { getCurrencyCode } from '../../../../../utils/currency';
import { isFormValid } from '../../../../../utils/form-validation';
import { formatServerError } from '../../../../../utils/formatting';
import { getStoreLanguage } from '../../../../../utils/language';

import { loadSquareCardPayments } from '../../../../../lib/square-card-payment';

import CartSummary from '../../../../Cart/CartSummary';
import Checkbox from '../../../../Input/Checkbox';
import LoadingIcon from '../../../../Icons/LoadingIcon';
import CheckoutStepPaymentDetailsBillingAddressInput from '../../steps/payment-details/CheckoutStepPaymentDetailsBillingAddressInput';

import * as checkoutActionCreators from '../../../../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class SquareCCPaymentInput extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputSameBilling: true,
      inputBillingAddress: {},

      errorBillingAddress: '',

      submitReqError: null,
      submitReqPending: false,

      saveSignal: 0,
      validationSignal: 0,

      squareLoading: true,
    };

    this.paymentMethod = checkoutGetSelectedPaymentMethod(this.props.checkout.stepData);
    this.shippingMethod = checkoutGetSelectedShippingMethod(this.props.checkout.stepData);
    this.billingAddress = this.getBillingAddress();
    this.shippingAddress = this.getShippingAddress();

    this.square = null;
    this.squarePayments = null;
    this.squareCard = null;

    this.checkoutColorRef = React.createRef();
    this.checkoutAccentRef = React.createRef();
    this.squareInputRef = React.createRef();

    this.checkNav = this.checkNav.bind(this);
    this.initCardPayments = this.initCardPayments.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.checkNav);
    loadSquareCardPayments(this.initCardPayments);
    this.unblock = history.block((blobj, action) => {
      if(this.state.submitReqPending === false) {
        return true;
      }
      return false;
    });
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    this.unblock();
    window.removeEventListener('beforeunload', this.checkNav);
    if(this.squareInterval) {
      clearInterval(this.squareInterval);
    }
    if(this.squareCard) {
      this.squareCard.destroy();
    }
  }

  checkNav(evt) {
    if(this.state.submitReqPending) {
      evt.preventDefault();
    }
    return '';
  }

  loadingStart(cb) {
    document.body.style.overflow = 'hidden';
    this.setState({
      submitReqError: null,
      submitReqPending: true,
    }, () => {
      if(cb) { cb(); }
    });
  }

  loadingEnd(err, cb) {
    document.body.style.overflow = 'visible';
    this.setState({
      submitReqError: err || null,
      submitReqPending: false,
    }, () => {
      if(cb) { cb(); }
    });
  }

  async initCardPayments() {

    if(!window.Square || !this.props.paymentMethod) {
      return null;
    }

    this.square = window.Square;

    const { SquareCCPaymentMethod } = require('../../../../../models/payment-methods');
    const squarePaymentModel = new SquareCCPaymentMethod();

    this.squarePayments = this.square.payments(squarePaymentModel.applicationId, squarePaymentModel.locationId);
    await this.squarePayments.setLocale(this.getLanguage());

    const squareStyle = this.getSquareStyleObj();
    this.squareCard = await this.squarePayments.card({
      style: squareStyle,
    });
    
    this.squareInterval = setInterval(async () => {
      if(this.squareInputRef && this.squareInputRef.current) {
        this.setState({
          squareLoading: false, 
        }, async () => {
          await this.squareCard.attach('#squareCardContainer')
            .catch((err) => {
              // Do nothing; error occurs if Stipe form already attached to page
              // Just don't need the error in the console
            });
          clearInterval(this.squareInterval);
        });
      }
    }, 200);
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getSquareStyleObj() {
    
    const inputContainer = {
      borderRadius: '0',
    };
    const inputContainerFocus = {};
    const messageIcon = {};

    if(this.checkoutColorRef && this.checkoutColorRef.current) {
      const foundColor = getComputedStyle(this.checkoutColorRef.current).color;
      if(foundColor) {
        inputContainer['borderColor'] = foundColor;
      }
    }

    if(this.checkoutAccentRef && this.checkoutAccentRef.current) {
      const foundAccent = getComputedStyle(this.checkoutAccentRef.current).color;
      if(foundAccent) {
        inputContainerFocus['borderColor'] = foundAccent;
        messageIcon['color'] = foundAccent;
      }
    }

    return {
      '.input-container': inputContainer,
      '.input-container.is-focus': inputContainerFocus,
      '.message-icon': messageIcon,
    };
  }

  toggleSameAsShipping() {
    this.setState({ inputSameBilling: !this.state.inputSameBilling });
  }

  validateAll() {
    // Nothing to validate in CC form- Square does it
    const errorObj = {};
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  handleSubmit(evt) {
    if(evt) { evt.preventDefault(); }

    if(this.validateAll()) {

      if(this.state.inputSameBilling) {
        this.completeSave();
      } else {
        this.setState({ saveSignal: Date.now() });
      }
    } else if(this.state.inputSameBilling === false) {
      this.setState({ validationSignal: Date.now() });
    }
  }

  getShippingAddress() {
    const stepData = getCheckoutStepData(CHECKOUT_STEP_SHIPPING_ADDRESS, this.props.checkout.stepData);
    if(stepData && stepData.data && stepData.data.shippingAddress) {
      return stepData.data.shippingAddress;
    }
    return null;
  }

  getBillingAddress() {
    if(this.state.inputSameBilling === true) {
      const stepData = getCheckoutStepData(CHECKOUT_STEP_SHIPPING_ADDRESS, this.props.checkout.stepData);
      if(stepData && stepData.data && stepData.data.shippingAddress) {
        return stepData.data.shippingAddress;
      }
    } else {
      const stepData = getCheckoutStepData(CHECKOUT_STEP_SELECT_PAYMENT_DETAILS, this.props.checkout.stepData);
      if(stepData && stepData.data && stepData.data.billingAddress) {
        return stepData.data.billingAddress;
      }
    }
    return null;
  }

  getNoteString() {
    const emailAddress = this.getOrderEmail();
    // Intentionally not translated; should be in the store language
    // TODO: translate (TX_ORDER_ONLINE_ORDER) based on store language, not user language
    return emailAddress ? `Online Order - ${emailAddress}` : `Online Order`;
  }

  getOrderEmail() {
    if(isLoggedIn()) {
      return getAuthObj().email_address;
    } else {
      const authStepData = getCheckoutStepData(CHECKOUT_STEP_AUTH, this.props.checkout.stepData);
      if(authStepData && authStepData.data && authStepData.data.isGuest) {
        return authStepData.data.guestUser.email;
      }
    }
    return '';
  }

  async completeSave() {

    const { Order } = require('../../../../../models/orders');

    this.loadingStart(async () => {

      const tokenResp = await this.fetchToken();
      if(!tokenResp) { return null; }

      const verificationResp = await this.fetchVerification(tokenResp.token);
      if(!verificationResp) { return null; }

      const paymentData = {
        source_id: tokenResp.token,
        verification_token: verificationResp.token,
        note: this.getNoteString(),
      }

      const orderData = {
        shipping_address_uuid: this.shippingAddress.publicUuid,
        shipping_method_uuid: this.shippingMethod.publicUuid,
        billing_address_uuid: this.billingAddress.publicUuid,
        payment_method_uuid: this.paymentMethod.publicUuid,
        cart_uuid: this.props.cart.currentCart.publicUuid,
        payment_record_uuid: null,
        status: this.props.cart.currentCart.hasPreorderItems() ? Order.preorderStatus : Order.initialStatus,
        use_store_credit: checkoutGetIsStoreCreditApplied(this.props.checkout.stepData),
      };

      const combinedData = Object.assign({}, orderData, paymentData);

      const orderResp = await this.props.checkoutPlaceOrder(combinedData)
        .catch((errResp) => {
          console.error(errResp);
          this.loadingEnd(formatServerError(errResp));
        });
      if(!orderResp || !orderResp.public_uuid) { return null; }

      this.props.checkoutClearStepData();

      history.push({
        pathname: URL_SUCCESS,
        state: { 
          orderUuid: orderResp.public_uuid, 
        },
      });
    });
  }

  async fetchToken() {
    try {
      const tokenResult = await this.squareCard.tokenize();
      if(tokenResult.status === 'OK') {
        return tokenResult;
      } else {
        if(tokenResult.errors) {
          this.loadingEnd();
        }
        return null;
      }
    } catch(err) {
      console.error(err);
      return null;
    }
  }

  async fetchVerification(token) {
    try {

      if(!this.billingAddress) { return null; }

      const verificationDetails = {
        amount: (getCartGrandTotal(this.props.cart.currentCart, this.props.checkout.stepData)).toString(),
        billingContact: {
          addressLines: this.billingAddress.streetArray,
          familyName: this.billingAddress.lastName,
          givenName: this.billingAddress.firstName,
          email: this.getOrderEmail(),
          country: this.billingAddress.country.key,
          phone: this.billingAddress.phone,
          region: this.billingAddress.region,
          city: this.billingAddress.city,
        },
        currencyCode: getCurrencyCode(),
        intent: 'CHARGE',
      };

      const verificationResults = await this.squarePayments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults;
    } catch(err) {
      console.error(err);
      this.loadingEnd();
      return null;
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'SquareCCPaymentInput CheckoutStepComponent'}>
      <div className='checkoutColor' ref={this.checkoutColorRef} />
      <div className='checkoutAccent' ref={this.checkoutAccentRef} />
      <div className='pmiLiner'>
        {this.state.squareLoading === true ?
          <div className='squareLoading'>
            <LoadingIcon iconClass='iconElement' />
          </div> :
          <>
            {this.square === null ?
              <div className='squareLoadError'>
                <div className='squareLoadErrorTitle'>{t(ERROR_PAYMENT_METHOD_NOT_LOADED)}</div>
                <div className='squareLoadErrorLinkWrapper'>
                  <Link to={URL_CONTACT_US}>{t(tx.TX_CONTACT_US)}</Link>
                </div>
              </div> :
              null
            }
          </>
        }
        <div className={this.state.submitReqError ? 'squareServerError present' : 'squareServerError'}>
          {this.state.submitReqError ? t(this.state.submitReqError) : ''}
        </div>
        <form 
          className={'checkoutStepInputForm'} 
          onSubmit={this.handleSubmit.bind(this)}
          style={{
            display: this.state.squareLoading === true || this.square === null ? 'none' : 'block',
          }}>
          <div className={'paymentSection'}>
            <div id='squareCardContainer' ref={this.squareInputRef}></div>
            <div className={'checkoutFormInput sameAsShippingWrapper'}>
              <div className='sameAsShippingInputWrapper'>
                <Checkbox 
                  name={t(tx.TX_BILLING_ADDRESS)}
                  id={'sameAsShippingInput'}
                  value={'same-as-shipping'}
                  checked={this.state.inputSameBilling}
                  onChange={this.toggleSameAsShipping.bind(this)} />
              </div>
              <label 
                htmlFor={'sameAsShippingInput'}
                className='sameAsShippingValueWrapper'>
                {t(tx.TX_CHECKOUT_BILLING_ADDRESS_SAME)}
              </label>
            </div>
          </div>
          {this.state.inputSameBilling === false ?
            <div className={'billingSection'}>
              <CheckoutStepPaymentDetailsBillingAddressInput
                config={this.props.config}
                setStepData={this.props.setStepData}
                saveSignal={this.state.saveSignal}
                saveComplete={this.completeSave.bind(this)}
                validationSignal={this.state.validationSignal} />
            </div> :
            null
          }
          <div className='checkoutSummaryWrapper'>
            <div className='summaryTitle'>{t(tx.TX_CART_SUMMARY_TITLE)}</div>
            <CartSummary
              cartOverride={null}
              cartLoading={this.state.cartLoading} />
          </div>
          <div className={'checkoutFormInput checkoutFormAction placeOrderAction'}>
            <button 
              type='submit'
              className='checkoutFormButton'
              disabled={this.state.submitReqPending}>
              {t(tx.TX_CHECKOUT_PLACE_ORDER)}
            </button>
          </div>
        </form>
      </div>
      <div className={`checkoutSubmitScreen ${this.state.submitReqPending ? 'active' : ''}`}>
        <div className='submitScreenLiner'>
          <div className='submitIconWrapper'>
            <LoadingIcon />
          </div>
          <div className='submitIconLabel'>{t(tx.TX_CHECKOUT_PLACING_ORDER)}</div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(SquareCCPaymentInput));