import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  LAYOUT_DEFAULT_HOME,
  LAYOUT_KEY_HOME,
  LAYOUT_STORE_DEFAULT_OVERRIDES,
} from '../../constants/pages';

import { Layout } from '../../models/pages';

// import HomeBanner from './blocks/HomeBanner';
import LayoutComponent from '../Layout/LayoutComponent';

import './style/_home.scss';

let allActionCreators = Object.assign({});

export class Home extends Component {

  getOverrideLayoutConfig() {
    const storeCode = process.env.REACT_APP_STORE_CODE;
    if(LAYOUT_STORE_DEFAULT_OVERRIDES[storeCode] && LAYOUT_STORE_DEFAULT_OVERRIDES[storeCode][LAYOUT_KEY_HOME]) {
      return LAYOUT_STORE_DEFAULT_OVERRIDES[storeCode][LAYOUT_KEY_HOME];
    }
    return LAYOUT_DEFAULT_HOME;
  }

  render() {
    return <div className='Home'>
      <div className='homeLayoutWrapper'>
        <LayoutComponent
          adminTheme={false}
          layout={new Layout(this.getOverrideLayoutConfig())}
          layoutLoading={false} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(Home);