import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  API_KEY_IS_BUYLIST, 
  API_KEY_LIMIT,
  API_KEY_PRODUCT_LINE, 
} from '../../../../constants/api';
import {
  TX_FEATURED_PRODUCTS,
  TX_GALLERY_NO_RESULTS,
  TX_VIEW,
} from '../../../../constants/strings';
import { URL_BUY_PRODUCT_LINE } from '../../../../constants/urls';

import { 
  formatServerError, 
  stringFormat, 
} from '../../../../utils/formatting';

import { productSearchInventory } from '../../../../actions/product';

import BlockElementHorizontalProduct from '../BlockElementHorizontalProduct';
import LoadingIcon from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockTypeProductsVertical.module.scss';

export const BlockFeaturedProductLineVertical = (props) => {
  
  const MAX_PRODUCTS = 8;

  // Dispatch
  const dispatch = useDispatch();

  // Props
  const {
    block,
    blockStyles,
  } = props;

  // State
  const [products, setProducts] = useState(null);
  const [productsError, setProductsError] = useState(null);
  const [productsLoading, setProductsLoading] = useState(false);
  
  const [productsCount, setProductsCount] = useState(MAX_PRODUCTS);

  // Redux state
  const enabledProductLines = useSelector(state => state.productLine.enabled);

  // Layout refs
  const bodyRef = useRef(null);
  const scrollRef = useRef(null);

  // API control refs
  const controllerRef = useRef(null);

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const featuredLine = () => {
    return enabledProductLines.find((blk) => blk.permalink === block.getConfigAttr('productLinePermalink')) || null;
  };

  const fetchProducts = useCallback(async () => {

    setProducts(null);
    setProductsLoading(true);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const searchFilters = {
      [API_KEY_PRODUCT_LINE]: [ block.getConfigAttr('productLinePermalink') ],
      [API_KEY_IS_BUYLIST]: false,
      [API_KEY_LIMIT]: MAX_PRODUCTS,
    };

    // Fetch data
    const resp = await dispatch(productSearchInventory(searchFilters, controller.signal))
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        setProductsError(formatServerError(errResp));
        setProductsLoading(false);
      });

    if(!resp) {
      return null;
    }

    setProducts(resp.data);
    setProductsLoading(false);

  }, [ dispatch, block ]);


  // Effects
  useEffect(() => {
    if(products === null) {
      fetchProducts();
    }
  }, [ products, fetchProducts ]);


  useEffect(() => {
    if(bodyRef && bodyRef.current && scrollRef && scrollRef.current) {
      if(scrollRef.current.getBoundingClientRect().height > bodyRef.current.getBoundingClientRect().height) {
        setProductsCount(productsCount - 1);
      }
    }
  }, [ products, productsCount ]);

  // Render
  return (
    <div className={`${styles.BlockFeaturedProductLineVertical} ${styles.BlockTypeProductsVertical}`}>
      <div className={styles.btpvLiner}>
        <div className={blockStyles.blockTitleUnderline}>
          <div className='FlexCenter'>{featuredLine() ? featuredLine().name : t(TX_FEATURED_PRODUCTS)}</div>
        </div>

        <div className={blockStyles.blockBodyVertical} ref={bodyRef}>
          {productsLoading ?
            <div className={blockStyles.verticalBodyIcon}>
              <LoadingIcon />
            </div> :
            <div className={blockStyles.blockBodyVerticalLiner} ref={scrollRef}>

              {productsError ?
                <div className={styles.errorWrapper}>{t(productsError)}</div> : null
              }

              {products && products.length > 0 ?
                <>
                  {products.map((prod, i) => {

                    if(i >= productsCount) {
                      return null;
                    }
                    i++;

                    return <div key={i} className={styles.elementWrapper}>
                      <BlockElementHorizontalProduct
                        isBuylist={false}
                        product={prod} />
                    </div>
                  })}
                </> :
                <div className={styles.noResults}>
                  {t(TX_GALLERY_NO_RESULTS)}
                </div>
              }
            </div>
          }
        </div>

        <div className={blockStyles.blockFooter}>
          <Link 
            className={blockStyles.blockAction}
            to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: block.getConfigAttr('productLinePermalink') })}>
            {t(TX_VIEW)}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlockFeaturedProductLineVertical;




