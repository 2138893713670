import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as i from '../../constants/icons';

import Add from './lib/Add';
import Buy from './lib/Buy';
import BuylistCart from './lib/BuylistCart';
import Calendar from './lib/Calendar';
import CalendarMonthly from './lib/CalendarMonthly';
import CalendarWeekly from './lib/CalendarWeekly';
import Cart from './lib/Cart';
import Check from './lib/Check';
import ChevronDown from './lib/ChevronDown';
import ChevronDownDouble from './lib/ChevronDownDouble';
import ChevronLeftDouble from './lib/ChevronLeftDouble';
import ChevronRightDouble from './lib/ChevronRightDouble';
import Close from './lib/Close';
import Coupon from './lib/Coupon';
import CreditCard from './lib/CreditCard';
import Currency from './lib/Currency';
import Detail from './lib/Detail';
import Download from './lib/Download';
import Edit from './lib/Edit';
import EditSimple from './lib/EditSimple';
import Email from './lib/Email';
import Empty from './lib/Empty';
import ErrorIcon from './lib/ErrorIcon';
import FileIcon from './lib/File';
import Filter from './lib/Filter';
import Flip from './lib/Flip';
import Format from './lib/Format';
import Guest from './lib/Guest';
import Hashtag from './lib/Hashtag';
import Home from './lib/Home';
import Hours from './lib/Hours';
import Integration from './lib/Integration';
import Inventory from './lib/Inventory';
import List from './lib/List';
import Location from './lib/Location';
import Marketing from './lib/Marketing';
import Menu from './lib/Menu';
import MenuDots from './lib/MenuDots';
import Message from './lib/Message';
import Notice from './lib/Notice';
import OpenExternal from './lib/OpenExternal';
import Pages from './lib/Pages';
import PaymentMethod from './lib/PaymentMethod';
import Phone from './lib/Phone';
import Pickup from './lib/Pickup';
import Play from './lib/Play';
import ProductLines from './lib/ProductLines';
import Refresh from './lib/Refresh';
import Search from './lib/Search';
import Sell from './lib/Sell';
import Settings from './lib/Settings';
import Shipping from './lib/Shipping';
import StoreCredit from './lib/StoreCredit';
import Trash from './lib/Trash';
import Upload from './lib/Upload';
import User from './lib/User';
import View from './lib/View';

import './style/_icons.scss';

let allActionCreators = Object.assign({});

export class Icon extends Component {

  static defaultProps = {
    onClick: () => {},
  }

  constructor(props) {
    super(props);

    this.iconWrapper = React.createRef();
  }

  getIconValue() {

    if(!this.props.value) { return null; }

    switch(this.props.value) {
      case i.ICON_ADD:
        return (<Add />);
      case i.ICON_BUY:
        return (<Buy />);
      case i.ICON_BUYLIST_CART:
        return (<BuylistCart />);
      case i.ICON_CALENDAR:
        return (<Calendar />);
      case i.ICON_CALENDAR_MONTHLY:
        return (<CalendarMonthly />);
      case i.ICON_CALENDAR_WEEKLY:
        return (<CalendarWeekly />);
      case i.ICON_CART:
        return (<Cart />);
      case i.ICON_CHECK:
        return (<Check />);
      case i.ICON_CHEVRON_DOWN:
        return (<ChevronDown />);
      case i.ICON_CHEVRON_DOWN_DOUBLE:
        return (<ChevronDownDouble />);
      case i.ICON_CHEVRON_LEFT_DOUBLE:
        return (<ChevronLeftDouble />);
      case i.ICON_CHEVRON_RIGHT_DOUBLE:
        return (<ChevronRightDouble />);
      case i.ICON_CLOSE:
        return (<Close />);
      case i.ICON_COUPON:
        return (<Coupon />);
      case i.ICON_CREDIT_CARD:
        return (<CreditCard />);
      case i.ICON_CURRENCY:
        return (<Currency />);
      case i.ICON_DETAIL:
        return (<Detail />);
      case i.ICON_DOWNLOAD:
        return (<Download />);
      case i.ICON_EDIT:
        return (<Edit />);
      case i.ICON_EDIT_SIMPLE:
        return (<EditSimple />);
      case i.ICON_EMAIL:
        return (<Email />);
      case i.ICON_EMPTY:
        return (<Empty />);
      case i.ICON_ERROR:
        return (<ErrorIcon />);
      case i.ICON_FILE:
        return (<FileIcon />);
      case i.ICON_FILTER:
        return (<Filter />);
      case i.ICON_FLIP:
        return (<Flip />);
      case i.ICON_FORMAT:
        return (<Format />);
      case i.ICON_GUEST:
        return (<Guest />);
      case i.ICON_HASHTAG:
        return (<Hashtag />);
      case i.ICON_HOME:
        return (<Home />);
      case i.ICON_HOURS:
        return (<Hours />);
      case i.ICON_INTEGRATION:
        return (<Integration />);
      case i.ICON_INVENTORY:
        return (<Inventory />);
      case i.ICON_LIST:
        return (<List />);
      case i.ICON_LOCATION:
        return (<Location />);
      case i.ICON_MARKETING:
        return (<Marketing />);
      case i.ICON_MENU:
        return (<Menu />);
      case i.ICON_MENU_DOTS:
        return (<MenuDots />);
      case i.ICON_MESSAGE:
        return (<Message />);
      case i.ICON_NOTICE:
        return (<Notice />);
      case i.ICON_OPEN_EXTERNAL:
        return (<OpenExternal />);
      case i.ICON_PAGES:
        return (<Pages />);
      case i.ICON_PAYMENT_METHOD:
        return (<PaymentMethod />);
      case i.ICON_PHONE:
        return (<Phone />);
      case i.ICON_PICKUP:
        return (<Pickup />);
      case i.ICON_PLAY:
        return (<Play />);
      case i.ICON_PRODUCT_LINE:
        return (<ProductLines />);
      case i.ICON_REFRESH:
        return (<Refresh />);
      case i.ICON_SEARCH:
        return (<Search />);
      case i.ICON_SELL:
        return (<Sell />);
      case i.ICON_SETTINGS:
        return (<Settings />);
      case i.ICON_SHIPPING:
        return (<Shipping />);
      case i.ICON_STORE_CREDIT:
        return (<StoreCredit />);
      case i.ICON_TRASH:
        return (<Trash />);
      case i.ICON_UPLOAD:
        return (<Upload />);
      case i.ICON_USER:
        return (<User />);
      case i.ICON_VIEW:
        return (<View />);
      default:
        return null
    }
  }

  render() {

    return <div className={this.props.iconClass ? 'Icon TitanIcon ' + this.props.iconClass : 'Icon TitanIcon'} onClick={this.props.onClick.bind(this)}>
      <div className={`iconSVGWrapper ${this.props.iconWrapperClass || ''}`} ref={this.iconWrapper}>
        {this.getIconValue()}
      </div>
      {this.props.iconLabel ?
        <div className={this.props.ellipsisLabel ? 'iconLabel EllipsisElement' : 'iconLabel'}>{this.props.iconLabel}</div> :
        null
      }
      <div className={`iconBottom ${this.props.bottomClass || ''}`}></div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(Icon);