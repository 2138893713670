import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { API_KEY_RETURN_TO_INVENTORY } from '../../../../constants/api';

import { isFormValid } from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import * as tx from '../../../../constants/strings';

import Checkbox from '../../../Input/Checkbox';

import '../../style/_vieworder.scss';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class OrderModalCancelOrder extends Component {

  constructor(props) {
    super(props);

    this.checkboxId = `omco-checkbox-toggle`;

    this.state = {
      
      returnInventory: true,

      requestPending: false,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_ORDER_CANCEL_ORDER_PROMPT;
  }

  validateAll() {
    const errorObj = {};
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const cancelData = {
        [API_KEY_RETURN_TO_INVENTORY]: this.props.order && !this.props.order.isBuylist ? this.state.returnInventory : false,
      }
      
      const cancelResp = await this.props.ordersCancelOrder(cancelData, this.props.order.publicUuid, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!cancelResp) { return null; }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  toggleReturnCheckbox() {
    this.setState({ returnInventory: !this.state.returnInventory });
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalCancelOrder OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'pickupsCreateForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          <div className='orderModalCopy'>{t(tx.TX_ORDER_CANCEL_ORDER_COPY)}</div>

          {this.props.order && !this.props.order.isBuylist ?
            <div className='orderModalToggleLine'>
              <div className='omToggleInputWrapper'>
                <Checkbox 
                  adminTheme={true}
                  name={t(tx.TX_ORDER_CANCEL_ORDER_RETURN_ITEMS)}
                  id={this.checkboxId}
                  value={'return-toggle'}
                  checked={this.state.returnInventory}
                  onChange={this.toggleReturnCheckbox.bind(this)} />
              </div>
              <label htmlFor={this.checkboxId}>
                <div className='omToggleCopy'>{t(tx.TX_ORDER_CANCEL_ORDER_RETURN_ITEMS)}</div>
              </label>
            </div> :
            null
          }

        </form>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalCancelOrder));