import React from 'react';

import { MENU_TYPE_BUY } from '../../constants/menu';

import { isLoggedIn } from '../../utils/auth';

import HeaderElementAccount from './elements/HeaderElementAccount';
import HeaderElementCart from './elements/HeaderElementCart';
import HeaderElementHamburger from './elements/HeaderElementHamburger';
import HeaderElementLogo from './elements/HeaderElementLogo';
import HeaderShopMenu from './menus/HeaderShopMenu';
import HeaderUserMenu from './menus/HeaderUserMenu';

import styles from './_styles/HeaderCenterLogo.module.scss';

export const HeaderCenterLogo = (props) => {
  
  // Render
  return (
    <div className={styles.HeaderCenterLogo}>
      <div className={styles.displayWrapper}>
        <div className={styles.hamburgerWrapper}>
          <HeaderElementHamburger
            menuType={MENU_TYPE_BUY} />
        </div>
        <div className={styles.logoWrapper}>
          <HeaderElementLogo />
        </div>
        <div className={styles.accountWrapper}>
          <HeaderElementAccount />
        </div>
        <div className={styles.cartWrapper}>
          <HeaderElementCart />
        </div>
      </div>
      {isLoggedIn() ?
        <HeaderUserMenu /> :
        null
      } 
      <HeaderShopMenu />
    </div>
  );
};

export default HeaderCenterLogo;




