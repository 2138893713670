import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  FILTER_PRODUCT_NAME, 
  FV_KEY_NAME, 
  FV_TYPE_CHECKBOXES, 
  FV_TYPE_DROPDOWN, 
  FV_TYPE_LORCANA_SET,
  FV_TYPE_MAGIC_SET, 
  FV_TYPE_POKEMON_SET, 
  FV_TYPE_RANGE_INPUT,
  FV_TYPE_STARWARS_SET, 
} from '../../../constants/filters';
import * as tx from '../../../constants/strings';

import {
  getFilterConfigByKey, 
  getFilterConfigKeys,
  getFilterDontTranslateTags, 
  getFilterMinKey,
  getFilterMaxKey,
  getFilterPlaceholder, 
  getFormattedDisplayValue, 
  getStaticFilterOptions,
  hasStaticFilterOptions, 
  isInputFilter,
} from '../../../utils/filters';
import {
  isVarArray, 
  isVarNumber, 
  isVarString, 
} from '../../../utils/general';

import FilterElementCheckboxes from './filters/FilterElementCheckboxes';
import FilterElementDropdown from './filters/FilterElementDropdown';
import FilterElementLorcanaSet from './filters/custom/FilterElementLorcanaSet';
import FilterElementMagicSet from './filters/custom/FilterElementMagicSet';
import FilterElementPokemonSet from './filters/custom/FilterElementPokemonSet';
import FilterElementRangeInput from './filters/FilterElementRangeInput';
import FilterElementStarWarsSet from './filters/custom/FilterElementStarWarsSet';
import LoadingIcon from '../../Icons/LoadingIcon';

import '../style/_productfilters.scss';

import * as productActionCreators from '../../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class ProductFilters extends Component {

  getLanguage() {
    
    const { i18n } = this.props;
    const { getStoreLanguage } = require('../../../utils/language');

    return getStoreLanguage(i18n);
  }

  hasFilters() {
    try {
      for(const key in this.props.filters) {
        if(this.props.filters[key] && (isVarNumber(this.props.filters[key]) || this.props.filters[key].length > 0)) {
          return true;
        }
      }
    } catch(err) {
      console.error(err);
      return false;
    }
    return false;
  }

  getFilterElement(filterElementConfig) {

    let filterOptions = [];
    if(hasStaticFilterOptions(filterElementConfig)) {
      filterOptions = getStaticFilterOptions(filterElementConfig);
    } else {
      filterOptions = this.getDynamicFilterOptions(filterElementConfig);
    }
    
    const filterKey = filterElementConfig.key;

    switch(filterElementConfig.type) {
      case FV_TYPE_CHECKBOXES:
        return <FilterElementCheckboxes
                  config={filterElementConfig}
                  options={filterOptions}
                  filterValues={this.props.filters[filterKey] ? this.props.filters[filterKey] : []}
                  setFilter={this.props.setFilter.bind(this)} />;
      case FV_TYPE_DROPDOWN:
        return <FilterElementDropdown
                  config={filterElementConfig}
                  options={filterOptions}
                  placeholder={getFilterPlaceholder(filterElementConfig)}
                  filterValue={this.props.filters[filterKey] ? this.props.filters[filterKey] : ''}
                  setFilter={this.props.setFilter.bind(this)} />;
      case FV_TYPE_RANGE_INPUT:
        return <FilterElementRangeInput
                  config={filterElementConfig}
                  options={filterOptions}
                  filters={this.props.filters}
                  setFilter={this.props.setFilter.bind(this)}
                  removeFilter={this.props.removeFilter.bind(this)}
                  isBuylist={!!this.props.isBuylist} />;
      case FV_TYPE_MAGIC_SET:
        return <FilterElementMagicSet
                  config={filterElementConfig}
                  options={null}
                  placeholder={getFilterPlaceholder(filterElementConfig)}
                  filterValue={this.props.filters[filterKey] ? this.props.filters[filterKey] : ''}
                  setFilter={this.props.setFilter.bind(this)}
                  setCustomFilterOptions={this.props.setCustomFilterOptions.bind(this)} />;
      case FV_TYPE_POKEMON_SET:
        return <FilterElementPokemonSet
                  config={filterElementConfig}
                  options={null}
                  placeholder={getFilterPlaceholder(filterElementConfig)}
                  filterValue={this.props.filters[filterKey] ? this.props.filters[filterKey] : ''}
                  setFilter={this.props.setFilter.bind(this)}
                  setCustomFilterOptions={this.props.setCustomFilterOptions.bind(this)} />;
      case FV_TYPE_LORCANA_SET:
        return <FilterElementLorcanaSet
                  config={filterElementConfig}
                  options={null}
                  placeholder={getFilterPlaceholder(filterElementConfig)}
                  filterValue={this.props.filters[filterKey] ? this.props.filters[filterKey] : ''}
                  setFilter={this.props.setFilter.bind(this)}
                  setCustomFilterOptions={this.props.setCustomFilterOptions.bind(this)} />;
      case FV_TYPE_STARWARS_SET:
        return <FilterElementStarWarsSet
                  config={filterElementConfig}
                  options={null}
                  placeholder={getFilterPlaceholder(filterElementConfig)}
                  filterValue={this.props.filters[filterKey] ? this.props.filters[filterKey] : ''}
                  setFilter={this.props.setFilter.bind(this)}
                  setCustomFilterOptions={this.props.setCustomFilterOptions.bind(this)} />;
      default:
        return null;
    }
  }

  hasDynamicFilterOptions(filterElementConfig) {
    if(!this.props.filterOptions) {
      return false;
    }
    const filterKey = filterElementConfig.key;
    if(this.props.filterOptions[filterKey] && this.props.filterOptions[filterKey].length > 0) {
      return true;
    }
    return false;
  }

  getDynamicFilterOptions(filterElementConfig) {
    if(!this.props.filterOptions) {
      return [];
    }

    const filterKey = filterElementConfig.key;    
    return this.props.filterOptions[filterKey] ? this.props.filterOptions[filterKey] : [];
  }

  isValidFilter(filterElementConfig) {
    
    // If a filter is dynamic, assume that component knows what it's doing
    if(filterElementConfig.dynamicOptions) {
      return true;
    }

    // If a generic filter doesn't have options, then it's not valid
    if(hasStaticFilterOptions(filterElementConfig) || isInputFilter(filterElementConfig)) {
      return true;
    } else {
      return this.hasDynamicFilterOptions(filterElementConfig);
    }
  }

  getDisplayValue(filterValue, filterElementConfig) {

    let filterOptions = [];
    if(hasStaticFilterOptions(filterElementConfig)) {
      filterOptions = getStaticFilterOptions(filterElementConfig);
    } else if(!isInputFilter(filterElementConfig)) {
      filterOptions = this.getDynamicFilterOptions(filterElementConfig);
    }

    for(const fv of filterOptions) {
      if(filterValue === fv.value.toString()) {
        return getFormattedDisplayValue(fv.display, filterElementConfig, { language: this.getLanguage() });
      }
    }
    return getFormattedDisplayValue(filterValue, filterElementConfig, { language: this.getLanguage() });
  }

  getFilterAppliedValues(filterElementConfig) {

    if(!this.props.filters) { return []; }

    const filterSelectedValue = this.props.filters[filterElementConfig.key];

    const filterMinValue = this.props.filters[getFilterMinKey(filterElementConfig, { isBuylist: !!this.props.isBuylist })];
    const filterMaxValue = this.props.filters[getFilterMaxKey(filterElementConfig, { isBuylist: !!this.props.isBuylist })];

    const resp = [];

    if(filterSelectedValue) {

      if(isVarString(filterSelectedValue)) {
        return [{ 
          key: filterElementConfig.key,
          value: filterSelectedValue,
        }];
      } else if(isVarArray(filterSelectedValue)) {

        const filterOptions = hasStaticFilterOptions(filterElementConfig)
                                ? getStaticFilterOptions(filterElementConfig)
                                : this.getDynamicFilterOptions(filterElementConfig);

        const returnValues = [];
        for(const fo of filterOptions) {
          const optionValue = fo.value.toString();
          if(filterSelectedValue.includes(optionValue)) {
            returnValues.push({
              key: filterElementConfig.key,
              value: optionValue,
            });
          }
        }
        return returnValues;
      }
    }

    if(filterMinValue || filterMinValue === 0) {
      resp.push({
        key: getFilterMinKey(filterElementConfig, { isBuylist: !!this.props.isBuylist }),
        value: filterMinValue,
        label: tx.TX_MIN,
      });
    } 

    if(filterMaxValue || filterMaxValue === 0) {
      resp.push({
        key: getFilterMaxKey(filterElementConfig, { isBuylist: !!this.props.isBuylist }),
        value: filterMaxValue,
        label: tx.TX_MAX,
      });
    }
    return resp;
  }

  removeFilterValue(filterValObj, filterElementConfig) {

    if(!filterValObj) { return null; }

    const filterKey = filterElementConfig.key;
    const filterVal = filterValObj.value;
    const currentValues = this.props.filters[filterKey];

    if(filterKey !== filterValObj.key) {
      // This is a range filter, remove keys alltogether
      this.props.removeFilter(filterValObj.key);
    } else {

      if(!currentValues) {
        return null;
      }

      let updatedValues = null;
      if(isVarString(currentValues)) {
        updatedValues = '';
      } else if(isVarArray(currentValues)) {
        
        updatedValues = [...currentValues];
        const idx = updatedValues.indexOf(filterVal);

        if(idx > -1) {
          updatedValues.splice(idx, 1);
        }
      }

      this.props.setFilter({
        [filterKey]: updatedValues,
      });

      if(filterKey === FV_KEY_NAME) {
        this.props.productSetNameValue('');
      }
    }
  }

  getOrderedAppliedFilters() {

    if(!this.props.filters) {
      return [];
    }

    const sortedFilters = [];

    // Check universal filters first; currently just name
    const nameKey = FILTER_PRODUCT_NAME.key;
    if(this.props.filters[nameKey]) {
      sortedFilters.push(nameKey);
    }

    for(const conf of this.props.config) {
      
      const filterKeys = getFilterConfigKeys(conf, { isBuylist: !!this.props.isBuylist });
      for(const key of filterKeys) {
        if(this.props.filters[key] || this.props.filters[key] === 0) {
          if(!sortedFilters.includes(conf.key)) {
            sortedFilters.push(conf.key);
            break;
          }
        }
      }
    }
    return sortedFilters;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductFilters'}>
      {this.props.loading ?

        <div className={'productFiltersWrapper productFiltersWrapperLoading'}>
          <div className={'filterLoading'}>
            <div className='filterLoadingWrapper'>
              <LoadingIcon />
            </div>
            <div className='filterLoadingLabel'>{t(tx.TX_FILTER_LOADING)}</div>
          </div>
        </div> :
    
        <div className='productFiltersWrapper'>
          
          <div className='pfAppliedWrapper'>
            <div className='pfFilterTitle'>{t(tx.TX_FILTER_APPLIED_FILTERS)}</div>
            {this.hasFilters() ?
              <div className='pfAppliedBody'>
                {this.getOrderedAppliedFilters().map((key, i) => {

                  const filterObj = getFilterConfigByKey(key);
                  if(!filterObj) {
                    return null;
                  }

                  const filterValues = this.getFilterAppliedValues(filterObj);
                  if(filterValues.length === 0) {
                    return null;
                  }

                  return <div className='pfAppliedElementWrapper' key={i}>
                    <div className='pfAppliedElementTitle'>{t(filterObj.label)}</div>
                    <div className='pfAppliedElementBody'>
                      {filterValues.map((filterValObj, j) => {
                        return <div className='pfAppliedItemWrapper' key={j}>
                          <div className='pfAppliedItemInnerWrapper'>
                            {filterValObj.label ?
                              <div className='pfAppliedTagLabel'>{t(filterValObj.label)}</div> :
                              null
                            }
                            <div className='pfAppliedTagBody'>
                              {getFilterDontTranslateTags(filterObj) ? this.getDisplayValue(filterValObj.value, filterObj) : t(this.getDisplayValue(filterValObj.value, filterObj))}
                            </div>
                            <div className='pfAppliedTagAction' onClick={() => this.removeFilterValue(filterValObj, filterObj)}>
                              <div className='pfTagX'>&times;</div>
                            </div>
                          </div>
                        </div>;
                      })}
                    </div>
                  </div>;
                })}
              </div> :
              <div className='pfAppliedBodyNull'>{t(tx.TX_FILTER_NO_CURRENT_FILTERS)}</div>
            }
          </div>

          <div className='pfFilters'>
            {this.props.config.map((filterConfig, i) => {
              if(this.isValidFilter(filterConfig) === false) {
                return null;
              }
              return <div key={i} className='individualFilterWrapper'>
                {this.getFilterElement(filterConfig)}
              </div>;
            })}
          </div>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductFilters));