import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ICON_CART } from '../../../constants/icons';

import useHeaderMenu from '../../../hooks/useHeaderMenu';
import usePrevious from '../../../hooks/usePrevious';

import { cartToggleMinicartSlider } from '../../../actions/cart';

import Icon from '../../Icons/Icon';

import styles from './_styles/HeaderElementCart.module.scss';

export const HeaderElementCart = (props) => {
  
  // Constants
  const PULSE_FREQUENCY = 1000; // CSS animation duration is 1s

  // Dispatch
  const dispatch = useDispatch();

  // State
  const [pulseActive, setPulseActive] = useState(false);

  // Hooks
  const { closeMenu } = useHeaderMenu();

  // Refs
  const pulseTimeoutRef = useRef();

  // Accessing Redux state
  const currentCart = useSelector(state => state.cart.currentCart);
  const currentBuylistCart = useSelector(state => state.cart.currentBuylistCart);
  const isBuylistMode = useSelector(state => state.cart.buylistMode);

  // Methods
  const handleToggle = () => {
    closeMenu();
    dispatch(cartToggleMinicartSlider());
  };

  const pulseBubble = () => {
    setPulseActive(true);

    pulseTimeoutRef.current = setTimeout(() => {
      setPulseActive(false);
    }, PULSE_FREQUENCY);
  };

  const cartQuantity = useCallback(() => {
    if(isBuylistMode) {
      return currentBuylistCart.quantity || 0;
    } else {
      return currentCart.quantity || 0;
    }
  }, [isBuylistMode, currentCart, currentBuylistCart]);

  // Previous data
  const prevCartQuantity = usePrevious(cartQuantity());

  // Effects
  useEffect(() => {
    if(cartQuantity !== prevCartQuantity) {
      pulseBubble();
    }
  }, [ cartQuantity, prevCartQuantity ]);

  useEffect(() => {
    return () => {
      if(pulseTimeoutRef.current) {
        clearTimeout(pulseTimeoutRef.current);
      }
    };
  }, []);

  // Render
  return (
    <div className={styles.HeaderElementCart} onClick={handleToggle}>
      <Icon 
        value={ICON_CART} 
        iconClass={styles.cartIconWrapper} />
      {cartQuantity() ?
        <div className={`${styles.cartBubble} ${pulseActive ? styles.pulse : ''}`}>{cartQuantity()}</div> :
        null
      }
    </div>
  );
};

export default HeaderElementCart;




