import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { SECTION_CONFIG_KEY_COUNT_PER_ROW } from '../../constants/pages';

import BlockComponent from './BlockComponent';

import styles from './style/SectionComponent.module.scss';

const allActionCreators = Object.assign({});

export class SectionComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollPos: 0,
      revealed: false,
    };

    this.sectionElement = React.createRef();
    
    this.checkPosThrottled = _.throttle(this.checkRevealed.bind(this), 50);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkPosThrottled, false);
    window.addEventListener('resize', this.checkPosThrottled, false);

    this.checkRevealed();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkPosThrottled, false);
    window.removeEventListener('resize', this.checkPosThrottled, false);
  }

  checkRevealed() {

    const VISIBLE_OFFSET = 75;

    if(this.state.revealed) {
      return null;
    }

    if(this.sectionElement && this.sectionElement.current) {
      
      const windowHeight = window.innerHeight;
      const elementTop = this.sectionElement.current.getBoundingClientRect().top;

      if (elementTop < windowHeight - VISIBLE_OFFSET) {
        this.setState({ revealed: true });
      }
    }
  }

  getSectionModel() {
    const { Section } = require('../../models/pages');
    return this.props.section || new Section();
  }

  getSectionClass() {

    const baseClass = styles.SectionComponent;

    const typeClass = this.props.section && this.props.section.type ? styles[this.props.section.type] : '';
    const revealedClass = this.state.revealed ? styles.revealed : '';
    const animationClass = this.props.section && this.props.section.animation ? styles[`ani-${this.props.section.animation}`] : styles['ani-default'];

    const effectClasses = this.getSectionModel().effects
                            .map(eff => styles[`effect-${eff}`])
                            .filter(Boolean)
                            .join(' ');

    return `${baseClass} ${typeClass} ${revealedClass} ${animationClass} ${effectClasses}`;
  }

  shouldHideBlock(blk) {
    if(blk.requiredProductLine) {
      if(this.props.productLine.enabled) {
        let foundPl = false;
        for(const pl of this.props.productLine.enabled) {
          if(blk.requiredProductLine === pl.permalink) {
            foundPl = true;
          }
        }
        if(!foundPl) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  blockCountClass(blk) {
    if(this.getSectionModel().getConfigAttr(SECTION_CONFIG_KEY_COUNT_PER_ROW)) {
      return `n${this.getSectionModel().getConfigAttr(SECTION_CONFIG_KEY_COUNT_PER_ROW)}`;
    }
    return `n${this.getSectionModel().blocks.length}`;
  }

  render() {
    return <div className={this.getSectionClass()} ref={this.sectionElement}>
      <div className={styles.sectionLiner} style={this.getSectionModel().style}>
        {this.getSectionModel().titleBlock ?
          <div className={styles.titleBlockWrapper}>
            <BlockComponent
              block={this.getSectionModel().titleBlock}
              blockIndex={0} />
          </div> :
          null
        }
        {this.getSectionModel().blocks.map((blk, i) => {

          if(this.shouldHideBlock(blk)) {
            return null;
          }

          return <div 
                    key={i} 
                    className={`${styles.sectionBlockWrapper} ${styles[this.blockCountClass()]}`}
                    style={this.getSectionModel().blockStyle}>
            <BlockComponent
              block={blk}
              blockIndex={i}
              blockCount={this.getSectionModel().blocks.length} />
          </div>;
        })}
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(SectionComponent));