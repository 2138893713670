import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_USER } from '../../../constants/icons';
import * as tx from '../../../constants/strings';
import { URL_LOGIN } from '../../../constants/urls';

import { Icon } from '../../Icons/Icon';

import styles from './_styles/AccountLoggedOut.module.scss';

const allActionCreators = Object.assign({});

export class AccountLoggedOut extends Component {

  render() {
    const {t} = this.props;
    return <div className={styles.AccountLoggedOut}>
      <Link to={URL_LOGIN}>  
        <div className={styles.desktopWrapper}>
          <Icon 
            value={ICON_USER} 
            iconClass={styles.accountIconWrapper} />
          <div className={styles.accountCopyWrapper}>
            <div className={styles.loggedOutWrapper}>{t(tx.TX_LOGIN_SHORT)}</div>
          </div>
        </div>
        <div className={styles.mobileWrapper}>
          <Icon
            value={ICON_USER}
            iconClass={styles.iconClass} />
        </div>
      </Link>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountLoggedOut));