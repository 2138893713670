import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './_styles/BlockTitleDefault.module.scss';

// Block configs; these should eventually be defined in TS once Next migration complete
// config: {
//   title: '',
//   titleStyle: {},
//   titleTranslate: false,
//   subTitle: '',
//   subTitleStyle: {},
//   subTitleTranslate: false,
//   copy: '',
//   copyStyle: '',
//   copyTranslate: false,
// },

export const BlockTitleDefault = (props) => {
  
  // Props
  const {
    block,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Content
  const title = block.getConfigAttr('title', '');
  const subTitle = block.getConfigAttr('subTitle', '');
  const copy = block.getConfigAttr('copy', '');

  // If no content, don't render block
  if(!title && !subTitle && !copy) {
    return null;
  }

  // Render
  return (
    <div className={styles.BlockTitleDefault}>
      <div className={styles.blockLiner}>
        {title ?
          <div className={`${styles.titleWrapper}`}>
            {block.getConfigAttr('titleTranslate', false) ? t(title) : title}
          </div> :
          null
        }
        {subTitle ?
          <div className={`${styles.subTitleWrapper}`}>
            {block.getConfigAttr('subTitleTranslate', false) ? t(subTitle) : subTitle}
          </div> :
          null
        }
        {copy ?
          <div className={`${styles.copyWrapper}`}>
            {block.getConfigAttr('copyTranslate', false) ? t(copy) : copy}
          </div> :
          null
        }
      </div>
    </div>
  );
};

export default BlockTitleDefault;




