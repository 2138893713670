import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_FLIP } from '../../constants/icons';

// import { MagicCardImages } from '../../models/syncs/magic';

import Icon from '../Icons/Icon';
import StaticImage from '../Image/StaticImage';
import ProgressiveImage from '../Image/ProgressiveImage';

import './style/_magic.scss';

let allActionCreators = Object.assign({});

export class MagicImage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      flipped: false,
      changePending: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.magicObj.id !== prevProps.magicObj.id && this.state.flipped) {
      this.setState({ changePending: true });
    }
  }

  toggleFlip(evt) {
    if(evt) { evt.stopPropagation(); }
    if(evt) { evt.preventDefault(); }
    this.setState({ flipped: !this.state.flipped });
    return false;
  }

  flippedFrontLoaded() {
    
    if(this.state.changePending) {
      this.setState({
        flipped: false, 
      })
    }

    if(this.props.onLoadLow) {
      this.props.onLoadLow();
    }
  }

  render() {

    const { t } = this.props;
    const { MagicCardImages } = require('../../models/syncs/magic');

    return <div className={'MagicImage'}>
      {this.props.magicObj.doubleSided ?
        <div className='magicImageWrapper'>
          <div className={this.state.flipped ? 'magicImageFlipBody flipped' : 'magicImageFlipBody'}>
            <div className={'magicImageFlipFace magicImageFlipFront'}>
              {this.props.thumbnail ?
                <StaticImage
                  imgObj={{
                    src: this.props.magicObj.thumbnailImageSrc(), 
                    alt: this.props.magicObj.localizedName, 
                    noTranslate: true,
                  }}
                  onImgLoad={this.flippedFrontLoaded.bind(this)} /> :
                <ProgressiveImage
                  alt={this.props.magicObj.imageAlt(t)}
                  className='magicImageImg'
                  src={this.props.magicObj.primaryImageSrc()}
                  thumbnailSrc={this.props.magicObj.thumbnailImageSrc()}
                  loadDelay={this.props.loadDelay}
                  placeholder={MagicCardImages.defaultPrimarySrc()}
                  onLoadPlaceholder={this.flippedFrontLoaded.bind(this)} />
              }
            </div>
            <div className={'magicImageFlipFace magicImageFlipBack'}>
              {this.props.thumbnail ?
                <StaticImage
                  imgObj={{
                    src: this.props.magicObj.thumbnailImageSrc(), 
                    alt: this.props.magicObj.localizedName, 
                    noTranslate: true,
                  }} /> :
                <ProgressiveImage
                  alt={this.props.magicObj.imageAlt(t)}
                  className='magicImageImg'
                  src={this.props.magicObj.primaryImageSrc(true)}
                  thumbnailSrc={this.props.magicObj.thumbnailImageSrc(true)}
                  loadDelay={this.props.loadDelay}
                  placeholder={MagicCardImages.defaultPrimarySrc()}
                  onLoadPlaceholder={this.flippedFrontLoaded.bind(this)} />
              }
            </div>
          </div>
          {this.props.noFlip === true ?
            <></> :
            <div className='magicImageFlipButton' onClick={this.toggleFlip.bind(this)}>
              <Icon 
                value={ICON_FLIP}  
                iconClass={'magicImageFlipButtonIcon'} 
                iconLabel={null} 
                ellipsisLabel={false} />
            </div>
          }
        </div> :
        <div className='magicImageWrapper'>
          {this.props.thumbnail ?
            <StaticImage
              imgObj={{
                src: this.props.magicObj.thumbnailImageSrc(), 
                alt: this.props.magicObj.localizedName, 
                noTranslate: true,
              }}
              onImgLoad={this.props.onLoadLow} /> :
            <ProgressiveImage
              alt={this.props.magicObj.imageAlt(t)}
              className='magicImageImg'
              src={this.props.magicObj.primaryImageSrc()}
              thumbnailSrc={this.props.magicObj.thumbnailImageSrc()}
              loadDelay={this.props.loadDelay}
              placeholder={MagicCardImages.defaultPrimarySrc()}
              onLoadPlaceholder={this.props.onLoadLow} />
          }
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(MagicImage));