
import React from 'react';

/**
 * Parses content and replaces placeholders with corresponding React elements.
 * Currently handles icons of the form {{mtgicon|className}}.
 *
 * @param {string} content - The content string to be parsed.
 * @returns {ParsedContent} - An array containing strings and React elements.
 */

// Right now, just parses for MTG icons, will need to generalize it, but for now, 
export const parseContent = (content) => {

  // Supported tags
  const supportedTags = [
    'mtgicon', 
    'link'
  ];
  
  // Regex to match placeholders like {{mtgicon|some-class-name}} and {{link|url|label}}
  // Currently only supports two parameters, can extend to more
  const regex = new RegExp(`{{(${supportedTags.join('|')})\\|([^|}]+)\\|?([^}]*)}}`, 'g');

  const parts = [];
  let lastIndex = 0;

  // Iterate over all matches in the content
  let match;
  while ((match = regex.exec(content)) !== null) {
    
    // Add preceding text, if any
    if (lastIndex < match.index) {
      parts.push(content.slice(lastIndex, match.index));
    }

    const type = match[1];
    const param1 = match[2].trim();
    const param2 = match[3]?.trim();

    switch (type) {
      case 'mtgicon':
        // Add the icon element
        parts.push(React.createElement('i', { className: param1, key: match.index }));
        break;
      case 'link':
        // Add the link element
        parts.push(
          React.createElement('a', {
            href: param1,
            target: '_blank',
            rel: 'noreferrer',
            key: match.index
          }, param2)
        );
        break;
      default:
        break;
    }

    // Update lastIndex to end of current match
    lastIndex = regex.lastIndex;
  }

  // Add any remaining text after the last match
  if (lastIndex < content.length) {
    parts.push(content.slice(lastIndex));
  }

  return parts;
}