import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { API_KEY_MESSAGE_USER } from '../../../../constants/api';
import { ERROR_RETURNS_NO_ITEMS_SELECTED } from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';

import { getCurrencyMinorCount } from '../../../../utils/currency';
import { 
  getDescriptionError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';

import '../../style/_vieworder.scss';

import OrderModalRefundStepInventory from './OrderModalRefundStepInventory';
import OrderModalRefundStepPayment from './OrderModalRefundStepPayment';

import * as paymentsActionCreators from '../../../../actions/payments';
const allActionCreators = Object.assign({}, paymentsActionCreators);

export class OrderModalRefundCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      inputStoreCreditOnly: this.isStoreCreditOnlyFixed() ? true : false,
      inputMessageUser: true,
      inputNote: '',

      errorNote: '',
      errorRows: '', 

      selectedRows: [],
      quantityModifiers: {},
      returnValues: {},

      refundTotal: 0,

      requestPending: false,
      requestError: null,

      modalStep: 0,
    };

    this.scrollWrapper = React.createRef();

    this.controller = null;
  }

  componentDidMount() {
    this.props.allowConfirm(false);
    this.props.setSuccessLabel(tx.TX_NEXT);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
    if(this.props.supplementalSignal && prevProps.supplementalSignal !== this.props.supplementalSignal) {
      this.lastStep();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getScrollbarWidth() {
    if(!this.scrollWrapper || !this.scrollWrapper.current) { return 0; }

    return this.scrollWrapper.current.innerWidth - this.scrollWrapper.current.clientWidth;
  }

  static confirmLabel() {
    return tx.TX_CREATE;
  }

  changeNote(evt) {
    this.setState({
      inputNote: evt.target.value,
    }, () => {
      if(this.state.errorNote) {
        this.validateNote();
      }
    });
  }

  validateNote() {
    this.setState({ errorNote: getDescriptionError(this.state.inputNote, true) });
  }

  validateAll() {

    if(!this.state.selectedRows || this.state.selectedRows.length === 0) {
      this.setState({ requestError: ERROR_RETURNS_NO_ITEMS_SELECTED });
      return false;
    }

    const errorObj = {
      errorNote: getDescriptionError(this.state.inputNote, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }

    if(this.state.modalStep === 0) {
      this.nextStep();
    } else if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const refundData = {
        refund_amount: Math.round(parseFloat(this.state.refundTotal)*getCurrencyMinorCount()),
        reason: this.state.inputNote,
        entire_order: false,
        refund_in_store_credit: this.state.inputStoreCreditOnly,
        [API_KEY_MESSAGE_USER]: this.state.inputMessageUser,
      };

      const refundItems = [];
      for(const ci of this.state.selectedRows) {
        refundItems.push({
          cart_item_id: ci.id,
          quantity: this.getLineQuantity(ci),
          returned_to_inventory: this.getLineReturnValue(ci),
        });
      }

      refundData['refund_items'] = refundItems;

      const refundResp = await this.props.paymentsRefundPayment(refundData, this.props.order.paymentRecord.publicUuid, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!refundResp) { return null; }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  isStoreCreditOnlyFixed() {
    return this.props.order && this.props.order.paymentMethod && this.props.order.paymentMethod.schema && this.props.order.paymentMethod.schema.refunds && this.props.order.paymentMethod.schema.refunds.storeCreditOnly ? this.props.order.paymentMethod.schema.refunds.storeCreditOnly : false;
  }

  toggleStoreCreditOnly() {
    if(this.isStoreCreditOnlyFixed()) {
      return null;
    }
    this.setState({ inputStoreCreditOnly: !this.state.inputStoreCreditOnly });
  }

  toggleMessageUser() {
    this.setState({ inputMessageUser: !this.state.inputMessageUser });
  }

  isRowSelected(cartItem) {

    if(!cartItem) { return false; }

    for(const item of this.state.selectedRows) {
      if(item.id === cartItem.id) {
        return true;
      }
    }
    return false;
  }

  getLineQuantity(cartItem) {
    if(!cartItem) { return 0; }

    if(this.isRowSelected(cartItem) && Object.keys(this.state.quantityModifiers).includes(cartItem.id.toString())) {
      return this.state.quantityModifiers[cartItem.id];
    }
    return cartItem.quantity;
  }

  getLineReturnValue(cartItem) {
    if(!cartItem) { return false; }

    if(this.isRowSelected(cartItem) && Object.keys(this.state.returnValues).includes(cartItem.id.toString())) {
      return this.state.returnValues[cartItem.id.toString()];
    }
    return false;
  }

  getTotalRefundPrice() {
    try {
      let totalRefund = 0;

      for(const item of this.state.selectedRows) {
        const itemQty = parseInt(this.getLineQuantity(item)) || 0;        
        totalRefund += itemQty*item.price;
      }

      return totalRefund;
    } catch(err) {
      return 0;
    }
  }

  nextStep() {
    this.setState({ 
      modalStep: this.state.modalStep + 1, 
    }, () => {
      this.props.setSuccessLabel(null);
      this.props.setCustomTitle(tx.TX_ORDER_REFUND_PAYMENT_TITLE);
      this.props.setSupplementalActionLabel(tx.TX_BACK);
      this.props.completeAction();
    });
  }

  lastStep() {
    this.setState({ 
      modalStep: 0, 
    }, () => {
      this.props.setSuccessLabel(tx.TX_NEXT);
      this.props.setCustomTitle(null);
      this.props.setSupplementalActionLabel(null);
      this.props.completeAction();
    });
  }

  syncData(data) {
    this.setState(data);
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalRefundCreate OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'refundCreateForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          {this.state.modalStep === 0 ?
            <OrderModalRefundStepInventory 
              syncData={this.syncData.bind(this)}
              selectedRows={this.state.selectedRows}
              quantityModifiers={this.state.quantityModifiers}
              returnValues={this.state.returnValues}
              refundTotal={this.state.refundTotal}
              {...this.props} /> :
            <OrderModalRefundStepPayment 
              inputStoreCreditOnly={this.state.inputStoreCreditOnly}
              toggleStoreCreditOnly={this.toggleStoreCreditOnly.bind(this)}
              inputMessageUser={this.state.inputMessageUser}
              toggleMessageUser={this.toggleMessageUser.bind(this)}
              inputNote={this.state.inputNote}
              errorNote={this.state.errorNote}
              changeNote={this.changeNote.bind(this)}
              validateNote={this.validateNote.bind(this)}
              refundTotal={this.state.refundTotal}
              {...this.props} />
          }

        </form>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalRefundCreate));