import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import { breaksToBrs } from '../../../utils/formatting';

let allActionCreators = Object.assign({});

export class ProductPageDetailsLorcana extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getProductName() {
    return this.props.productObj.foreignModel.name;
  }

  getProductTypeLine() {
    return this.props.productObj.foreignModel.subtitle || '';
  }

  getProductDescription() {

    const { breaksToBrs } = require('../../../utils/formatting');
    
    const txt = this.props.productObj.foreignModel.bodyText;
    return breaksToBrs(txt, this.descriptionBreakStyle);
  }

  getProductSecondaryDescription() {

    const { breaksToBrs } = require('../../../utils/formatting');
    
    const txt = this.props.productObj.foreignModel.flavorText;
    return breaksToBrs(txt, this.descriptionBreakStyle);
  }

  render() {

    if(!this.props.productObj || !this.props.productObj.foreignModel || !this.props.productObj.foreignModel.isLorcanaCard) { return null; }

    return <div className={'ProductPageDetailsLorcana ProductPageDetails'}>
      <div className='productPageDetailsWrapper'>
        <div className='ppLorcanaWrapper' lang={this.props.productObj.foreignModel.language}>
          <div className='ppLorcanaFaceWrapper'>
            <div className='ppHeaderWrapper'>
              <div className='ppmTitle' lang={this.props.productObj.foreignModel.language}>
                <span className='ppmTitleText'>{this.getProductName()}</span>
              </div>
              <div className='ppmTypeLine'>{this.getProductTypeLine()}</div>
            </div>
            <div className='ppBorderWrapper'>
              <div className='ppLorcanaDetails'>
                <div 
                  className='ppmDescription'
                  dangerouslySetInnerHTML={{ __html: this.getProductDescription() }} />
                <div 
                  className='ppmSecondaryDescription'
                  dangerouslySetInnerHTML={{ __html: this.getProductSecondaryDescription() }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetailsLorcana));