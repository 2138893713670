import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ProductDetailRowInventory from './ProductDetailRowInventory';
import ProductDetailRowMedia from './ProductDetailRowMedia';
import ProductDetailRowProduct from './ProductDetailRowProduct';

const allActionCreators = Object.assign({});

export class ProductDetailRow extends Component {

  render() {

    if(!this.props.dataRow) { return null; }

    return <div className={'ProductDetailRow CustomAdminTableRow'}>
      <div className='prLiner'>
        <div className='mediaBlock'>
          <div className='mediaLiner'>
            <ProductDetailRowMedia
              product={this.props.dataRow} />
          </div>
        </div>
        <div className='productBlock'>
          <ProductDetailRowProduct
            product={this.props.dataRow} />
        </div>
        <div className='inventoryBlock'>
          <ProductDetailRowInventory
            filters={this.props.filters}
            hotSwap={this.props.hotSwap}
            product={this.props.dataRow}
            idx={this.props.idx}
            pageSize={this.props.pageSize}
            tabStart={this.props.tabStart}
            refreshData={this.props.refreshData} />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailRow));