

import {  
  ICON_BUY, 
  ICON_PLAY, 
  ICON_SELL, 
  ICON_USER,
} from '../constants/icons';
import {
	TX_ACCOUNT,
	TX_BUY,
	TX_PLAY,
	TX_SELL,
} from '../constants/strings';
import {  
  URL_BUY_GENERAL, 
  URL_SELL_GENERAL, 
  URL_PLAY_GENERAL,
} from '../constants/urls';

import HeaderSubmenuBuy from '../components/Header/menus/HeaderSubmenuBuy';
import HeaderSubmenuPlay from '../components/Header/menus/HeaderSubmenuPlay';
import HeaderSubmenuSell from '../components/Header/menus/HeaderSubmenuSell';
import HeaderSubmenuUser from '../components/Header/menus/HeaderSubmenuUser';


export const MENU_TYPE_BUY = 'buy';
export const MENU_TYPE_SELL = 'sell';
export const MENU_TYPE_PLAY = 'play';
export const MENU_TYPE_USER = 'user';


export const MENU_BUY = {
	key: MENU_TYPE_BUY,
	title: TX_BUY,
	component: HeaderSubmenuBuy,
	icon: ICON_BUY,
	url: URL_BUY_GENERAL,
};

export const MENU_PLAY = {
	key: MENU_TYPE_PLAY,
	title: TX_PLAY,
	component: HeaderSubmenuPlay,
	icon: ICON_PLAY,
	url: URL_PLAY_GENERAL,
};

export const MENU_SELL = {
	key: MENU_TYPE_SELL,
	title: TX_SELL,
	component: HeaderSubmenuSell,
	icon: ICON_SELL,
	url: URL_SELL_GENERAL,
};

export const MENU_USER = {
	key: MENU_TYPE_USER,
	title: TX_ACCOUNT,
	component: HeaderSubmenuUser,
	icon: ICON_USER,
};

export const MENUS_ALL = [
	MENU_BUY,
	MENU_PLAY,
	MENU_SELL,
	MENU_USER,
];






