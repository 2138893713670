
import { ERR_5500, ERR_OBJECT } from '../constants/errors';
import { LANG_EN, LANG_ZH } from '../constants/languages';
// import {
// 	// PROD_PRICE_CLASS_CENTS, 
// 	// PROD_PRICE_CLASS_DECIMAL, 
// 	// PROD_PRICE_CLASS_DOLLAR, 
// 	// PROD_PRICE_CLASS_FREE, 
// 	// PROD_PRICE_CLASS_LESS_THAN_ONE, 
// 	// PROD_PRICE_CLASS_NEGATIVE, 
// 	// PROD_PRICE_CLASS_SYMBOL, 
// } from '../constants/product';

import { 
	getCurrencyDecimalSeparator,
	getCurrencyDecimalTruncation,
	getCurrencyPrecision,
	getCurrencySymbol,
} from './currency';
import { 
	getDigitString, 
	isVarArray,
	safeParseFloat,
	safeParseInt, 
	twoDigitInt, 
} from './general';


// Identify function; used as default
export function formatNull(val) { return val; }

export function formatServerError(errResp) {

	try {

		if(errResp.error && errResp.error.data) {
			return errResp.error.data;
		}

		if(errResp.code) {
			return ERR_OBJECT[errResp.code] ? ERR_OBJECT[errResp.code] : ERR_5500;
		}

		if(!errResp.error.code) { return ERR_5500; }

		return ERR_OBJECT[errResp.error.code] ? ERR_OBJECT[errResp.error.code] : ERR_5500;
	} catch(err) {
		console.error(err);
		return ERR_5500;
	}
}


export function formatExternalError(errResp, service = null) {

	// In the future, this can be used to display service-specific (ie Square) errors;
	// Right now, not needed, so just returns a general error, but when needed, should be expanded

	return ERR_5500;
}


export function shouldTranslateServerError(errResp) {
	if(errResp.error.data) {
		return false;
	}
	return true;
}


export function stringFormat(stringTemplate, params = {}) {
	let formatResp = stringTemplate;
	for(const param in params) {
		formatResp = formatResp.replaceAll(`{{${param}}}`, params[param]);
	}
	return formatResp;
}


export function stringToPermalink(unformatted, allowTrailingSpace = false, maxLength = 64) {

	if(!unformatted || unformatted.length === 0) {
		return '';
	}

	const spaces = new RegExp(/[\s]+/g);
	const notAllowed = new RegExp(/[^a-z0-9\-_]+/g);

	if(allowTrailingSpace) {
		return unformatted.toLowerCase().replace(spaces, '-').replace(notAllowed, '').substring(0, maxLength);
	} else {
		return unformatted.trim().toLowerCase().replace(spaces, '-').replace(notAllowed, '').substring(0, maxLength);
	}	
}

export function stringToDigits(testString) {
	return testString.replace( /[^0-9]/g, '');
}

export function serverDateTimeFull(dateString, language = LANG_EN) {
	if(!dateString) { return '' }
	try {

		const jsDate = new Date(Date.parse(dateString));
		const options = { 
			dateStyle: 'long',
			timeStyle: 'long',
		};

		return jsDate.toLocaleString(language, options);
	} catch(err) {
		console.error(err);
		return dateString;
	}
}

export function serverDateFull(dateString, language = LANG_EN) {
	if(!dateString) { return '' }
	try {
		const jsDate = new Date(Date.parse(dateString));
		const options = { 
			year: 'numeric', 
			month: 'long', 
			day: 'numeric',
		};

		return jsDate.toLocaleDateString(language, options);
	} catch(err) {
		return dateString;
	}
}

export function serverDateShort(dateString, language = LANG_EN) {
	if(!dateString) { return '' }
	try {
		const jsDate = new Date(Date.parse(dateString));
		const options = { 
			year: 'numeric', 
			month: 'short', 
			day: 'numeric',
		};

		return jsDate.toLocaleDateString(language, options);
	} catch(err) {
		return dateString;
	}
}

export function serverTimeFull(dateString, language = LANG_EN) {
	if(!dateString) { return '' }
	try {
		const jsDate = new Date(Date.parse(dateString));
		const options = { 
			timeStyle: 'medium',
		};

		return jsDate.toLocaleTimeString(language, options);
	} catch(err) {
		return dateString;
	}
}

export function dateShort(dateString, language = LANG_EN) {
	if(!dateString) { return '' }
	try {

		const jsDate = new Date(Date.parse(dateString));
		const options = { 
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
		};

		return jsDate.toLocaleString(language, options);
	} catch(err) {
		console.error(err);
		return dateString;
	}
}

export function timeShort(dateString, language = LANG_EN) {
	if(!dateString) { return '' }
	try {

		const jsDate = new Date(Date.parse(dateString));
		const options = { 
			timeStyle: 'short',
		};

		return jsDate.toLocaleString(language, options);
	} catch(err) {
		console.error(err);
		return dateString;
	}
}

export function serverDateFromJs(jsDate, language = LANG_EN) {
	if(!jsDate) { return '' }
	try {
		return `${jsDate.getFullYear()}-${twoDigitInt(jsDate.getMonth() + 1)}-${twoDigitInt(jsDate.getDate())}`;
	} catch(err) {
		console.error(err);
		return '';
	}
}

export function latinCount(testString) {
	if(!testString) {
		return 0;
	}
	const latinRegex = new RegExp(/[a-zA-Z]/g);
	return ((testString || '').match(latinRegex) || []).length;
}

export function getFullName(first = '', last = '', language = LANG_EN) {
	if(language.includes(LANG_ZH)) {
		if(latinCount(first + last) === 0) {
			return `${last}${first}`;
		} else {
			return `${last} ${first}`;
		}
	}
	return `${first} ${last}`;
}

// Used to replace newline with <br />, but switched to wrapping in divs to allow for css control
export function breaksToBrs(withBreaks, params = {}) {
	if(!withBreaks) {
		return '';
	}

	// return withBreaks;
	let respString = '';
	let styleString = '';

	if(params.margin) {
		styleString += `margin: ${params.margin}; `;
	}

	const lineArray = withBreaks.split(/(?:\r\n|\r|\n)/g);
	for(const ln of lineArray) {
		respString += ln === '' ? `<div style='${styleString}'>&nbsp;</div>` : `<div style='${styleString}'>${ln}</div>`;
	}
	return respString;
}

export function getPriceDollars(priceFloat, config = {}) {

	// Config schema
	// addTags: boolean; default false
	// language: LANG_ constant; default LANG_EN

	const {
		PROD_PRICE_CLASS_DOLLAR,
		PROD_PRICE_CLASS_LESS_THAN_ONE,
	} = require('../constants/product');

	const addTags = config.addTags ? true : false;
	const language = config.language ? config.language : LANG_EN;
	const lessThanOneClass = priceFloat < 1 && priceFloat > -1 ? ` ${PROD_PRICE_CLASS_LESS_THAN_ONE}` : '';

	if(addTags) {
		return `<span class='${PROD_PRICE_CLASS_DOLLAR}${lessThanOneClass}'>${Math.floor(priceFloat).toLocaleString(language)}</span>`;
	} else {
		return Math.floor(priceFloat).toLocaleString(language);
	}
}

export function getPriceCents(priceFloat, config = {}) {
	
	// Config schema
	// addTags: boolean; default false
	// language: LANG_ constant; default LANG_EN

	const {
		PROD_PRICE_CLASS_CENTS,
		PROD_PRICE_CLASS_LESS_THAN_ONE,
	} = require('../constants/product');

	const addTags = config.addTags ? true : false;
	const language = config.language ? config.language : LANG_EN;
	const lessThanOneClass = priceFloat < 1 && priceFloat > -1 ? ` ${PROD_PRICE_CLASS_LESS_THAN_ONE}` : '';

	const precision = getCurrencyPrecision();

	if(addTags) {
		return `<span class='${PROD_PRICE_CLASS_CENTS}${lessThanOneClass}'>${(parseFloat(priceFloat) - Math.floor(priceFloat)).toLocaleString(language, {minimumFractionDigits: precision, maximumFractionDigits: precision}).slice(-1*precision)}</span>`;
	} else {
		return (parseFloat(priceFloat) - Math.floor(priceFloat)).toLocaleString(language, {minimumFractionDigits: precision, maximumFractionDigits: precision}).slice(-1*precision);
	}
}

export function formatNegative(formattedPrice) {

	const {
		STORE_NEGATIVE_STYLE,
		STORE_NEGATIVE_STYLE_PARENTHESIS,
		STORE_NEGATIVE_STYLE_SIGNED, 
	} = require('../constants/store');

	switch(STORE_NEGATIVE_STYLE) {
		case STORE_NEGATIVE_STYLE_PARENTHESIS:
			return `(${formattedPrice})`;
		case STORE_NEGATIVE_STYLE_SIGNED:
			return `-${formattedPrice}`;
		default:
			return `(${formattedPrice})`;
	}
}

export function formatPrice(price, config = {}) {

	// Config schema
	// addTags: boolean; default false
	// language: LANG_ constant; default LANG_EN
	// omitSymbol: boolean; default false
	// zeroValue: string

	const {
		PROD_PRICE_CLASS_DECIMAL,
		PROD_PRICE_CLASS_LESS_THAN_ONE,
		PROD_PRICE_CLASS_FREE,
		PROD_PRICE_CLASS_NEGATIVE,
		PROD_PRICE_CLASS_SYMBOL,
	} = require('../constants/product');

	if(!price && parseFloat(price) !== 0) {
		return '';
	}

	const priceFloat = parseFloat(price);

	const addTags = config.addTags ? true : false;
	const language = config.language ? config.language : LANG_EN;
	const symbol = config.omitSymbol ? '' : getCurrencySymbol();
	const zeroValue = config.zeroValue && priceFloat === 0 ? config.zeroValue : '';

	const isNegative = priceFloat < 0;
	const lessThanOneClass = priceFloat < 1 && priceFloat > -1 ? ` ${PROD_PRICE_CLASS_LESS_THAN_ONE}` : '';

	const hideDecimals = getCurrencyDecimalTruncation();

	if(addTags) {

		if(zeroValue) {
			return `<span class='${PROD_PRICE_CLASS_FREE}'>${zeroValue}</span>`;
		}

		const componentConfig = {
			addTags: addTags, 
			language: language,
		};

		const dollarComponent = `<span class='${PROD_PRICE_CLASS_SYMBOL}${lessThanOneClass}'>${symbol}</span>${getPriceDollars(Math.abs(priceFloat), componentConfig)}`;
		if(hideDecimals) {
			return isNegative ? `<span class='${PROD_PRICE_CLASS_NEGATIVE}'>${formatNegative(dollarComponent)}</span>` : dollarComponent;
		}

		const priceAbs = `${dollarComponent}<span class='${PROD_PRICE_CLASS_DECIMAL}${lessThanOneClass}'>${getCurrencyDecimalSeparator()}</span>${getPriceCents(Math.abs(priceFloat), componentConfig)}`

		return isNegative ? `<span class='${PROD_PRICE_CLASS_NEGATIVE}'>${formatNegative(priceAbs)}</span>` : priceAbs;
	} else {

		if(zeroValue) {
			return zeroValue;
		}
		const precision = hideDecimals ? 0 : getCurrencyPrecision();

		const priceAbs = symbol + Math.abs(priceFloat).toLocaleString(language, {minimumFractionDigits: precision, maximumFractionDigits: precision});

		return isNegative ? formatNegative(priceAbs) : priceAbs;
	}	
}

export function formatPhone(phoneDigits, countryKey) {
	try {

		if(!phoneDigits) { return ''; }

		const { 
			GEO_COUNTRY_US_KEY,
			GEO_COUNTRY_CL_KEY, 
		} = require('../constants/geographies');

		const country = countryKey || GEO_COUNTRY_US_KEY;
		const digitString = phoneDigits.toString();

		switch(country) {
			case GEO_COUNTRY_US_KEY:
				if(digitString.length !== 10) {
					return phoneDigits;
				}
				return `${digitString.substring(0,3)}.${digitString.substring(3,6)}.${digitString.substring(6)}`;
			case GEO_COUNTRY_CL_KEY:
				if(digitString.substring(0, 2) !== '56') {
					return phoneDigits;
				}
				return `+${digitString.substring(0,2)} ${digitString.substring(2)}`;
			default:
				return phoneDigits;
		}
	} catch(err) {
		console.error(err);
		return phoneDigits;
	}
}

export function stringSplice(base, idx, rem, str) {
	return base.slice(0, idx) + str + base.slice(idx + Math.abs(rem));	
}

// Add <span> tags around needle if found in string
export function addHighlightTags(haystack, needle, highlightClass = 'em') {

	const haystackVal = haystack.trim();
	const needleVal = needle.trim();

	const foundIndex = haystackVal.toLowerCase().indexOf(needleVal.toLowerCase());

	if(foundIndex < 0) {
		return haystackVal;
	}

	const openTag = `<span class='${highlightClass}'>`;
	const closeTag = `</span>`;
	
	// return haystackVal.splice(foundIndex + closeTag.length, 0, closeTag).splice(foundIndex, 0, openTag);
	return stringSplice(stringSplice(haystackVal, foundIndex + needleVal.length, 0, closeTag), foundIndex, 0, openTag);
}

export function dbStringToArray(arrayString) {
	// Example from scryfall import: 
	// "['nonfoil', 'foil', 'etched']"

	try {
		const finalArray = [];

		if(!arrayString) {
			return finalArray;
		}

		if(isVarArray(arrayString)) {
			return arrayString;
		}

		const rawElements = arrayString.replace(/^[[\]]|[[\]]?$/g, '').split(',');
		
		for(const raw of rawElements)	{
			finalArray.push(raw.trim().replace(/^['"]|['"]?$/g, ''));
		}
		return finalArray;
	} catch(err) {
		// console.error(err);
		return arrayString;
	}
}

export function formatCCNumberInput(numString) {
	
	const digitString = stringToDigits(numString);

  const partitions = digitString.startsWith('34') || digitString.startsWith('37') ? [ 4, 6, 5 ] : [ 4, 4, 4, 4 ];
  const numbers = [];
  let position = 0;

  partitions.forEach(partition => {
    const part = digitString.substr(position, partition);
    if (part) {
    	numbers.push(part);
    }
    position += partition;
  });
	return numbers.join(' ');
}

export function stringCapWords(wordString) {

	try {
		const words = wordString.split(' ');
		for(let i = 0; i < words.length; i++) {
		    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
		}
		return words.join(' ');
	} catch(err) {
		return wordString;
	}
}

export function formatExpDate(expMonth, expYear, language = LANG_EN) {
	if(!expMonth || !expYear) { return ''; }

	try {
		
		const formatOptions = {
			month: '2-digit',
			year: '2-digit', 
		};
		const exp = new Date(expYear, safeParseInt(expMonth) - 1);

		return exp.toLocaleDateString(language, formatOptions);

	} catch(err) {
		return '';
	}
}

export function formatFilesize(bytes) {

	const decimalPlaces = 1;
	const inc = 1000;
	const r = 10**decimalPlaces;
	const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	if(Math.abs(bytes) < inc) {
		return bytes + ' B';
	}

	let u = -1;
	do {
		bytes /= inc;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= inc && u < units.length - 1);

	return bytes.toFixed(decimalPlaces) + ' ' + units[u];
}

export function normalizePrice(valuePrice) {
	if(valuePrice === '') { return ''; }
	try {
		return safeParseFloat(valuePrice).toFixed(getCurrencyPrecision());
	} catch(err) {
		return valuePrice;
	}
}

export function normalizeQuantity(valueQty) {
	if(valueQty === '') { return ''; }
	try {
		return safeParseInt(valueQty);
	} catch(err) {
		return valueQty;
	}
}

export function normalizeDistance(valueDistance, distanceUnit) {
	if(valueDistance === '') { return ''; }
	try {
		return safeParseFloat(valueDistance).toFixed(distanceUnit.decimalPlaces || 0);
	} catch(err) {
		return valueDistance;
	}
}

export function normalizeWeight(valueWeight, weightUnit) {
	if(valueWeight === '') { return ''; }
	try {
		return safeParseFloat(valueWeight).toFixed(weightUnit.decimalPlaces || 0);
	} catch(err) {
		return valueWeight;
	}
}

export function formatRut(rutValue) {
	// Format: 11.111.111-1
	try {

		if(!rutValue) { return ''; }

		const digitString = getDigitString(rutValue);

		if(digitString.length <= 2) {
			return digitString;
		} else if(digitString.length <= 5) {
			return `${digitString.substring(0,2)}.${digitString.substring(2)}`;
		} else if(digitString.length <= 8) {
			return `${digitString.substring(0,2)}.${digitString.substring(2,5)}.${digitString.substring(5)}`;
		} else {
			return `${digitString.substring(0,2)}.${digitString.substring(2,5)}.${digitString.substring(5,8)}-${digitString.substring(8,9)}`;
		}
	} catch(err) {
		console.error(err);
		return rutValue;
	}
}








