import { useDispatch, useSelector } from 'react-redux';

import { MENUS_ALL } from '../constants/menu';

import { headerSetMenu } from '../actions/header';

const useHeaderMenu = () => {

  // Dispatch
  const dispatch = useDispatch();

  // Redux state
  const activeMenuKey = useSelector((state) => state.header.menuActive);
  
  const openMenu = (menuType, config = {}) => {
    if(menuType) {
      dispatch(headerSetMenu(menuType, config));
    }
  };

  const closeMenu = (config = {}) => {
    dispatch(headerSetMenu(null, config));
  };

  const toggleMenu = (menuType, config = {}) => {
    dispatch(headerSetMenu(activeMenuKey && activeMenuKey === menuType ? null : menuType, config));
  }

  const menu = activeMenuKey
    ? MENUS_ALL.find((mn) => mn.key === activeMenuKey) || null
    : null;

  const menuComponent = (props = {}) => {
    if(!menu || !menu.component) { return null; }

    const MenuComponent = menu.component;
    return <MenuComponent {...props} />
  }

  return { 
    menu, 
    menuComponent,
    openMenu, 
    closeMenu,
    toggleMenu,
  };
};
export default useHeaderMenu;


