
import * as _ from 'underscore';

import { ERROR_PAYMENT_METHOD_CONFIG } from '../constants/errors';
import { LANG_EN } from '../constants/languages';
import { 
	PAYMENT_METHOD_EXTERNAL_GENERAL,
	PAYMENT_METHOD_PAY_AT_PICKUP, 
	PAYMENT_METHOD_SQUARE_CC, 
	PAYMENT_METHOD_STORE_CREDIT, 
	PAYMENT_METHOD_TRANSBANK_WEBPAY,
	PAYMENT_METHODS_ALL, 
	PAYMENT_RECORD_CLASS_BRAND,
	PAYMENT_RECORD_CLASS_EXP, 
	PAYMENT_RECORD_CLASS_NUMBER, 
	PM_VALUE_CREATE_ACTION_OAUTH, 
	PM_VALUE_KEY_SQUARE_CC, 
	PM_VALUE_KEY_STORE_CREDIT,
} from '../constants/payment-methods';
import { STORAGE_SQUARE_STATE } from '../constants/storage';
import { TX_null } from '../constants/strings';

// import { CartItem } from './carts';

import { getCurrencyMinorCount } from '../utils/currency';
import {
	encryptString, 
	decryptString, 
} from '../utils/crypto';
import { getNotEmptyError } from '../utils/form-validation';
import { 
	formatExpDate,
	stringCapWords,
	stringFormat, 
} from '../utils/formatting';
import { isVarBool } from '../utils/general';

import * as storage from '../persistance/storage';


export class PaymentMethod {

	constructor(props) {
		
		if(!props) { props = {}; }

		this.key = props.key || '';
		this.publicUuid = props.publicUuid || props.public_uuid || null;

		this.nameValue = props.nameValue || props.name || '';
		this.nameBuylist = props.nameBuylist || props.name_buylist || '';
		this.nameCustom = props.nameCustom || props.name_custom || '';
		this.description = props.description ||  '';
		this.configData = props.configData || props.config_data || {};
		this.privateData = props.privateData || props.config_data_2 || [];
		this.isEnabled = props.isEnabled || props.is_enabled || false;
		this.isBuylist = props.isBuylist || props.is_buylist || false;
		this.conditionalDisplay = props.conditionalDisplay || props.conditional_display || false;
		this.allowPaymentAtPickup = props.allowPaymentAtPickup || props.allow_payment_at_pickup || false;

		const configObj = {};
		if(this.schema.config) {
			for(const conf of this.schema.config) {
				configObj[conf.key] = new PaymentMethodConfig({
					...conf,
					public: this.configData,
					private: this.privateData,
					parentData: {
						publicUuid: this.publicUuid,
					},
				});
			}
		}
		this.config = configObj;
	}
	
	static getByKey(key) {
		
		if(!key) { return null; }

		if(key === PM_VALUE_KEY_SQUARE_CC) {
			return new SquareCCPaymentMethod({ key: key });
		}
		return new PaymentMethod({ key: key });
	}

	get schema() {
		for(const schema of PAYMENT_METHODS_ALL) {
			if(schema.key === this.key) {
				return schema;
			}
		}
		return {};
	}

	get public_uuid() {
		return this.publicUuid;
	}

	get shouldTranslateName() {
		return this.nameCustom ? false : true;
	}

	get name() {
		if(this.nameCustom) {
			return this.nameCustom;
		} else if(this.nameValue) {
			return this.nameValue;
		} else if(this.schema.name) {
			return this.schema.name;
		}
		return TX_null;
	}

	set name(val) {
		this.nameValue = val;
	}

	getDisplayName(t = null) {
		if(t && this.shouldTranslateName) {
			return t(this.name);
		}
		return this.name;
	}

	get inputComponent() {
		return this.schema && this.schema.components && this.schema.components.input ? this.schema.components.input : null;
	}

	get isStoreCredit() {
		return this.key === PM_VALUE_KEY_STORE_CREDIT;
	}

	getConfigError() {
		return this.isValid() ? '' : ERROR_PAYMENT_METHOD_CONFIG;
	}

	isValid() {

		if(_.isEmpty(this.schema)) { return false; }

		for(const key in this.config) {
			const conf = this.config[key];
			if(conf.isRequired) {

				if(conf.isPrivate) {
					if(this.privateData.includes(key) === false) {
						return false;
					}
				} else {
					if(!this.configData[key]) {
						return false
					}
				}
			}
		}
		return true;
	}

	getCalcConfigs() {
		const configResp = [];
		for(const key in this.config) {
			if(this.config[key] && this.config[key].isCalculated) {
				configResp.push(this.config[key]);
			}
		}
		return configResp;
	}

	getVariableConfigs() {
		const configResp = [];
		for(const key in this.config) {
			if(this.config[key] && !this.config[key].isCalculated) {
				configResp.push(this.config[key]);
			}
		}
		return configResp;
	}

	getApiData() {
		const apiData = {
			key: this.key,
      name: this.name,
      config_data: this.configData,
      is_enabled: this.isEnabled,
		};

		if(this.publicUuid) {
			apiData['public_uuid'] = this.publicUuid;
		}

		if(this.nameCustom) {
			apiData['name_custom'] = this.nameCustom;
		}

		if(this.schema && this.schema.allowPaymentAtPickup) {
			apiData['allow_payment_at_pickup'] = true;
		}

		return apiData;
	}

	isOauth() {
		return this.schema && this.schema.createAction ? this.schema.createAction === PM_VALUE_CREATE_ACTION_OAUTH : false;
	}
}


export class SquareCCPaymentMethod extends PaymentMethod {

	constructor(props) {
		
		if(!props) { props = {}; }
		super(props);
	}

	get schema() {
		return PAYMENT_METHOD_SQUARE_CC;
	}

	get baseApiUrl() {
		return process.env.REACT_APP_BUILD_SQUARE_BASE_URL;
	}

	get applicationId() {
		return this.schema.applicationId;
	}

	get locationId() {
		return this.schema.locationId;
	}

	authorizeRedirect() {
		const reqState = this.saveRequestState();
		const reqScope = 'PAYMENTS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+ORDERS_WRITE+ORDERS_READ+LOYALTY_READ+LOYALTY_WRITE+GIFTCARDS_READ+GIFTCARDS_WRITE';
		const authorizeUrl = `${this.baseApiUrl}/oauth2/authorize?client_id=${this.schema.applicationId}&scope=${reqScope}&session=false&state=${reqState}`;
		
		window.location.href = authorizeUrl;
	}

	saveRequestState() {
		const stateVal = `${window.location.host}|${crypto.randomUUID()}`;
		storage.put(STORAGE_SQUARE_STATE, stateVal);
		return stateVal;
	}

	validateRequestState(stateVal) {
		if(storage.get(STORAGE_SQUARE_STATE) === stateVal) {
			return true;
		}
		return false;
	}
}

export class PaymentMethodConfig {

	constructor(props) {		
		if(!props) { props = {}; }

		this.key = props.key || '';
		this.isPrivate = props.isPrivate || false;
		this.isRequired = props.isRequired || false;
		this.isEncrypted = props.isEncrypted || false;
		this.isCalculated = props.isCalculated || false;
		this.label = props.label || TX_null;
		this.placeholder = props.placeholder || TX_null;
		this.validationMethod = props.validationMethod || getNotEmptyError;
		this.calcTemplate = props.calcTemplate || '';
		this.calcTemplateVars = props.calcTemplateVars || [];

		this.publicData = props.public || {};
		this.privateData = props.private || [];
		this.parentData = props.parentData || {};
	}

	hasValue() {
		if(this.isPrivate) {
			return this.privateData.includes(this.key);
		} else {
			return Object.keys(this.publicData).includes(this.key);
		}
	}

	get value() {
		if(this.isPrivate === true && this.isCalculated === false) {
			return '';
		} else if(this.isCalculated === true) {
			const values = {};
			for(const key of this.calcTemplateVars) {
				values[key] = this.parentData[key];
			}
			return stringFormat(this.calcTemplate, values);
		}
		if(this.hasValue()) {
			if(this.isEncrypted === true) {
				return decryptString(this.publicData[this.key]);
			} else {
				return this.publicData[this.key];
			}
		} else {
			return '';
		}
	}

	getApiData(value) {
    const apiData = {
    	[this.isPrivate ? 'config_data_2' : 'config_data']: {
    		[this.key]: this.isEncrypted ? encryptString(value.trim()) : value.trim(),
    	}
    }
    return apiData;
	}
}


export class PaymentProcessor {

	constructor(props) {
		if(!props) { props = {}; }
	}
}


export class PaymentRecord {

	constructor(props) {
		if(!props) { props = {}; }

		const detailsObj = props.cardDetails || props.details || null;
		const paymentMethodObj = props.paymentMethod || props.payment_method || null;

		this.publicUuid = props.publicUuid || props.public_uuid || null;
		this.cardDetails = detailsObj !== null ? new PaymentCardDetails(detailsObj) : null;
		this.additionalData = props.additionalData || props.additional_data || {};
		this.paymentMethod = paymentMethodObj ? new PaymentMethod(paymentMethodObj) : null;
		this.createDate = props.createDate || props.create_date || '';
		this.totalPaid = parseFloat(props.totalPaid) || parseFloat(props.total_paid / getCurrencyMinorCount()) || 0;
		this.totalStoreCredit = parseFloat(props.totalStoreCredit) || parseFloat(props.store_credit / getCurrencyMinorCount()) || 0;
		this.note = props.note || props.payment_note || '';

		this.date = new Date(this.createDate);

		this.refunds = [];
		const refundsArray = props.refunds || [];
		for(const ra of refundsArray) {
			this.refunds.push(new RefundRecord(ra));
		}
	}

	format(t = null, config = {}) {

		const addTags = config.addTags ? config.addTags : false;
		const isAdmin = config.admin ? config.admin : false;
		const language = config.language ? config.language : LANG_EN;

		if(!this.paymentMethod) { return null; }

		if(this.paymentMethod.key === PAYMENT_METHOD_SQUARE_CC.key) {
			if(this.cardDetails !== null) {
				const cardName = stringCapWords(this.cardDetails.brand);
				const lastFour = this.cardDetails.lastFour.toString();
				const cardExp = formatExpDate(this.cardDetails.expMonth, this.cardDetails.expYear, { language: language });

				if(addTags) {
					return `<span class='${PAYMENT_RECORD_CLASS_BRAND}'>${cardName}</span><span class='${PAYMENT_RECORD_CLASS_NUMBER}'>•••• ${lastFour}</span><span class='${PAYMENT_RECORD_CLASS_EXP}'>${cardExp}</span>`;
				} else {
					return `${cardName} •••• ${lastFour} ${cardExp}`;
				}
			}
		} else if(this.paymentMethod.key === PAYMENT_METHOD_TRANSBANK_WEBPAY.key) {
			if(addTags) {
				return t ? `<span>${t(this.paymentMethod.name)}</span>` : null;
			} else {
				return t ? `${t(this.paymentMethod.name)}` : null;
			}
		} else if(this.paymentMethod.key === PAYMENT_METHOD_PAY_AT_PICKUP.key) {
			
			if(isAdmin) {
				return addTags ? `<span>${this.note}</span>` : this.note;
			} else {
				if(addTags) {
					return t ? `<span>${t(this.paymentMethod.name)}</span>` : null;
				}
				return t ? `${t(this.paymentMethod.name)}` : null;
			}
		} else if(this.paymentMethod.key === PAYMENT_METHOD_STORE_CREDIT.key) {
			
			if(isAdmin) {
				return addTags ? `<span>${this.note}</span>` : this.note;
			} else {
				if(addTags) {
					return t ? `<span>${t(this.paymentMethod.name)}</span>` : null;
				}
				return t ? `${t(this.paymentMethod.name)}` : null;
			}
		} else if(this.paymentMethod.key === PAYMENT_METHOD_EXTERNAL_GENERAL.key) {

			if(isAdmin) {
				return addTags ? `<span>${this.note}</span>` : this.note;
			} else {
				if(addTags) {
					return t ? `<span>${t(this.paymentMethod.name)}</span>` : null;
				}
				return t ? `${t(this.paymentMethod.name)}` : null;
			}
		} else {
			return null;
		}
	}
}

export class ExternalPaymentRecord {

	constructor(props) {
		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || null;
		// this.cardDetails = detailsObj !== null ? new PaymentCardDetails(detailsObj) : null;
		// this.additionalData = props.additionalData || props.additional_data || {};
		// this.paymentMethod = paymentMethodObj ? new PaymentMethod(paymentMethodObj) : null;
	}
}

export class PaymentCardDetails {

	constructor(props) {
		if(!props) { props = {}; }

		this.bin = props.bin || '';
		this.brand = props.brand || props.card_brand || '';
		this.type = props.type || props.card_type || '';
		this.expMonth = props.expMonth || props.exp_month || '';
		this.expYear = props.expYear || props.exp_year || '';
		this.fingerprint = props.fingerprint || '';
		this.lastFour = props.lastFour || props.last_4 || '';
		this.prepaidType = props.prepaidType || props.prepaid_type || '';
	}
}

export class RefundRecord {

	constructor(props) {
		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || null;
		this.createDate = props.createDate || props.create_date || '';
		this.totalRefundCurrency = parseFloat(props.totalRefundCurrency) || parseFloat(props.total_refund_currency / getCurrencyMinorCount()) || 0;
		this.totalRefundStoreCredit = parseFloat(props.totalRefundStoreCredit) || parseFloat(props.total_refund_store_credit / getCurrencyMinorCount()) || 0;
		this.note = props.note || props.refund_note || '';
		this.entireOrder = props.entireOrder || props.entire_order || false;

		this.date = new Date(this.createDate);

		const refundItemsModels = [];
		const refundItemsArray = props.refundItems || props.refund_items || [];
		for(const ri of refundItemsArray) {
			refundItemsModels.push(new RefundRecordItem(ri));
		}
		this.refundItems = refundItemsModels;
	}

	get total() {
		return this.totalRefundCurrency + this.totalRefundStoreCredit;
	}

	containsCartItem(cartItem) {
		if(!cartItem || !cartItem.id) { return null; }
		for(const ri of this.refundItems) {
			if(ri.cartItem && ri.cartItem.id && ri.cartItem.id === cartItem.id) {
				return true;
			}
		}
		return false;
	}

	getRefundQuantityByCartItem(cartItem) {
		if(!cartItem || !cartItem.id) { return 0; }

		let qty = 0;
		for(const ri of this.refundItems) {
			if(ri.cartItem && ri.cartItem.id && ri.cartItem.id === cartItem.id) {
				qty += ri.quantity;
			}
		}
		return qty;
	}
}


export class RefundRecordItem {

	constructor(props) {

		const { CartItem } = require('./carts');

		if(!props) { props = {}; }

		const cartItemObj = props.cartItem || props.cart_item || null;
		
		this.quantity = parseInt(props.quantity) || 0;

		this.cartItem = cartItemObj ? new CartItem(cartItemObj) : null;

		// Attribute booleans
  	if(isVarBool(props.returnedToInventory)) {
  		this.returnedToInventory = props.returnedToInventory;
  	} else if(isVarBool(props.returned_to_inventory)) {
  		this.returnedToInventory = props.returned_to_inventory;
  	} else {
  		this.returnedToInventory = true;
  	}
	}
}













