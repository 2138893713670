import React from 'react';
import { useTranslation } from 'react-i18next';

import { stringFormat } from '../../../../utils/formatting';

import CmsElementRenderer from '../../../Cms/CmsElementRenderer';
import PrimaryNavLink from '../../../Links/PrimaryNavLink';

import styles from './_styles/BlockContentImageAndCopy.module.scss';

// Right now, this only works inside a full-width container since the min-height is set with 50vw;
// If we want to adapt to within a smaller container, either need to use fussy pseudo elements to set min-height
// or more likely use JS to detect and set a min-height based on width of component with a listener for resize

export const BlockContentImageAndCopy = (props) => {
  
  // Props
  const {
    block,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Content
  const reverse = block.getConfigAttr('reverse', false);
  const backgroundColor = block.getConfigAttr('backgroundColor', '');
  const image = block.getConfigAttr('image', {});
  const content = block.getConfigAttr('content', {});

  // Methods
  const actionUrl = () => {
    return stringFormat(content.actionUrl || '', content.actionUrlData || {});
  };

  const actionLabel = () => {
    return content.actionLabelTranslate ? t(content.actionLabel) : content.actionLabel || '';
  };

  // Render
  return (
    <div className={styles.BlockContentImageAndCopy}>
      <div 
        className={`${styles.blockLiner} ${reverse ? styles.reverse : ''}`} 
        style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
        <div className={styles.imageSection}>
          {image.src ?
            <img 
              className={styles.imageElement}
              src={image.src} 
              alt={image.altTranslate ? t(image.alt) : image.alt || ''} /> :
            null
          }
        </div>
        <div className={styles.contentSection}>
          <div className={styles.contentLiner}>
            {content.title ?
              <div className={`${styles.titleWrapper}`} style={content.titleStyle || {}}>
                {content.titleTranslate ? t(content.title) : content.title}
              </div> :
              null
            }
            {content.copy?.length ?
              <div className={`${styles.copyWrapper}`} style={content.copyStyle || {}}>
                {content.copy.map((contentElement, i) => {
                  return <CmsElementRenderer key={i} element={contentElement} styleModule={styles} />;
                })}
              </div> :
              null
            }
            {actionUrl() && actionLabel() ?
              <div className={`${styles.actionWrapper}`}>
                <PrimaryNavLink 
                  to={actionUrl()}
                  className={styles.actionButton}>
                  {actionLabel()}
                </PrimaryNavLink>
              </div> :
              null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockContentImageAndCopy;




