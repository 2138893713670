import React from 'react';
import { Link } from 'react-router-dom';

import { ContentBanner } from '../../../../../models/layouts/content/banners';

import ConditionalWrapper from '../../../../Utils/ConditionalWrapper';

import styles from './_styles/BannerSlide.module.scss';

export const BannerSlide = (props) => {
  
  // Props
  const {
    banner = new ContentBanner(),
    onLoad,
  } = props;

  // Methods
  const imgLoaded = (evt) => {

    const img = evt?.target;
    const displayValue = window.getComputedStyle(img).display;

    if(onLoad && displayValue !== 'none') {
      onLoad();
    }
  }

  // Render
  return (
    <div className={styles.BannerSlide}>
      <ConditionalWrapper
        condition={banner.linkSrc}
        wrapper={(children) => (
          <Link 
            className={styles.linkWrapper}
            to={{
              pathname: banner.linkSrc,
              state: banner.linkState,
            }}>
            {children}
          </Link>
        )}>
        <div className={styles.bannerWrapper}>
          <img 
            className={styles.desktop} 
            src={banner.backgroundDesktop}
            alt={banner.backgroundAlt}
            onLoad={imgLoaded} />
          <img 
            className={styles.tablet} 
            src={banner.backgroundTablet}
            alt={banner.backgroundAlt}
            onLoad={imgLoaded} />
          <img 
            className={styles.mobile} 
            src={banner.backgroundMobile}
            alt={banner.backgroundAlt}
            onLoad={imgLoaded} />
        </div>
      </ConditionalWrapper>
    </div>
  );
};

export default BannerSlide;




