import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import {
//   checkoutPrereqMet, 
// } from '../../../../../utils/checkout';

import CheckoutStepShippingMethodInput from './CheckoutStepShippingMethodInput';

let allActionCreators = Object.assign({});

export class CheckoutStepShippingMethod extends Component {

  getStepComponent() {

    const { checkoutPrereqMet } = require('../../../../../utils/checkout');

    // const { CheckoutStepShippingMethodInput } = require('./CheckoutStepShippingMethodInput').default;

    // If pre-requisites not met, do not display
    if(checkoutPrereqMet(this.props.config, this.props.checkout.stepData) === false) {
      return null;
    }

    return <CheckoutStepShippingMethodInput 
            config={this.props.config}
            setStepData={this.props.setStepData} />;
  }

  render() {
    return <div className={'CheckoutStepShippingMethod'}>{this.getStepComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepShippingMethod));