import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CSS_SLIDE_DURATION } from '../../../constants/css';
import { 
  ICON_CLOSE,
  ICON_SEARCH,
} from '../../../constants/icons';
import { 
  URL_BUY_GENERAL, 
  URL_BUY_PRODUCT_LINE
} from '../../../constants/urls';

import { isCurrentRoute } from '../../../utils/general';

import HeaderSearch from './HeaderSearch';
import Icon from '../../Icons/Icon';

import styles from './_styles/HeaderElementSearchDrawer.module.scss';

export const HeaderElementSearchDrawer = (props) => {
  
  // State
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchClosable, setSearchClosable] = useState(false);
  const [searchFocusSignal, setSearchFocusSignal] = useState(0);

  // Refs
  const searchDrawerRef = useRef();
  const searchTimeoutRef = useRef();

  // Methods
  const blockedRoute = () => {
    
    // Should return true only on pages with dedicated search component, which is currently just gallery
    const nonSearchRoutes = [
      URL_BUY_GENERAL,
      URL_BUY_PRODUCT_LINE,
    ];

    for(const rt of nonSearchRoutes) {
      if(isCurrentRoute(rt)) {
        return true;
      }
    }
    return false;
  }

  const openSearch = () => {

    setSearchOpen(true);
    setSearchClosable(false);

    searchTimeoutRef.current = setTimeout(() => {
      setSearchClosable(true);
      setSearchFocusSignal(Date.now());
    }, CSS_SLIDE_DURATION);
  }

  const closeSearch = () => {
    setSearchOpen(false);
    setSearchClosable(false);
  }

  const checkClick = useCallback((evt) => {
    if(searchOpen && searchClosable) {
      let targetElement = evt.target;
      do {
        if(searchDrawerRef && targetElement === searchDrawerRef.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      closeSearch();
    }
  }, [ searchOpen, searchClosable ]);

  // Effects
  useEffect(() => {

    document.addEventListener('click', checkClick, false);

    return () => {
      document.removeEventListener('click', checkClick, false);
      if(searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [ checkClick ]);

  // Render
  return (
    <div className={`${styles.HeaderElementSearchDrawer} ${blockedRoute() ? styles.blockedRoute : ''}`}>
      {searchOpen ?
        <div className={styles.searchIconWrapper} onClick={closeSearch}>
          <Icon 
            value={ICON_CLOSE} 
            iconClass={styles.searchIcon} />
        </div> :
        <div className={styles.searchIconWrapper} onClick={openSearch}>
          <Icon 
            value={ICON_SEARCH} 
            iconClass={styles.searchIcon} />
        </div>
      }
      <div ref={searchDrawerRef} className={`${styles.searchDrawer} ${searchOpen ? styles.open : ''} ${searchClosable ? styles.closable : ''}`}>
        <div className={styles.searchWrapper}>
          <HeaderSearch focusSignal={searchFocusSignal} />
        </div>
        <div className={styles.searchCloseWrapper} onClick={closeSearch}>
          <Icon 
            value={ICON_CLOSE} 
            iconClass={styles.closeIcon} />
        </div>
      </div>
    </div>
  );
};

export default HeaderElementSearchDrawer;




