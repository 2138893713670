import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../../constants/strings';

// import { ShippingParcel } from '../../../../models/shipping-methods';

import {
  getDistanceError, 
  getWeightError,
  isFormValid,
} from '../../../../utils/form-validation';
import {
  normalizeDistance,
  normalizeWeight,
} from '../../../../utils/formatting';
import { 
  getStoreDefaultDistanceUnit,
  getStoreDefaultWeightUnit,
} from '../../../../utils/general';

import Confirm from '../../../Popups/Confirm';

const allActionCreators = Object.assign({});

export class IntegrationConfigParcelSizeRow extends Component {

  constructor(props) {
    super(props);

    this.isDefaultRow = this.props.createDefault || this.props.idx === 0 || false;

    this.distanceUnit = getStoreDefaultDistanceUnit();
    this.weightUnit = getStoreDefaultWeightUnit();

    this.state = this.getInitState();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevProps.row, this.props.row)) {
      this.setState(this.getInitState());
    }
  }

  getInitState() {
    return {
      rowOpen: this.props.idx === null || false,

      deletePromptOpen: false,

      inputMinWeight: this.props.row.minWeight || '',
      inputLength: this.props.row.length || '',
      inputWidth: this.props.row.width || '',
      inputHeight: this.props.row.height || '',

      errorMinWeight: '',
      errorLength: '',
      errorWidth: '',
      errorHeight: '',
    };
  }

  changeWeight(evt) {
    this.setState({
      inputMinWeight: evt.target.value,
    }, () => {
      if(this.state.errorMinWeight) {
        this.validateWeight(false);
      }
    });
  }

  validateWeight(shouldNormalize = true) {
    this.setState({ 
      inputMinWeight: shouldNormalize && !this.isDefaultRow ? normalizeWeight(this.state.inputMinWeight, this.weightUnit) : this.state.inputMinWeight, 
      errorMinWeight: getWeightError(this.state.inputMinWeight, this.isDefaultRow),
    });
  }

  changeLength(evt) {
    this.setState({
      inputLength: evt.target.value,
    }, () => {
      if(this.state.errorLength) {
        this.validateLength(false);
      }
    });
  }

  validateLength(shouldNormalize = true) {
    this.setState({ 
      inputLength: shouldNormalize ? normalizeDistance(this.state.inputLength, this.distanceUnit) : this.state.inputLength, 
      errorLength: getDistanceError(this.state.inputLength),
    });
  }

  changeWidth(evt) {
    this.setState({
      inputWidth: evt.target.value,
    }, () => {
      if(this.state.errorWidth) {
        this.validateWidth(false);
      }
    });
  }

  validateWidth(shouldNormalize = true) {
    this.setState({ 
      inputWidth: shouldNormalize ? normalizeDistance(this.state.inputWidth, this.distanceUnit) : this.state.inputWidth, 
      errorWidth: getDistanceError(this.state.inputWidth),
    });
  }

  changeHeight(evt) {
    this.setState({
      inputHeight: evt.target.value,
    }, () => {
      if(this.state.errorHeight) {
        this.validateHeight(false);
      }
    });
  }

  validateHeight(shouldNormalize = true) {
    this.setState({ 
      inputHeight: shouldNormalize ? normalizeDistance(this.state.inputHeight, this.distanceUnit) : this.state.inputHeight, 
      errorHeight: getDistanceError(this.state.inputHeight),
    });
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorMinWeight: getWeightError(this.state.inputMinWeight, this.isDefaultRow),
      errorLength: getDistanceError(this.state.inputLength),
      errorWidth: getDistanceError(this.state.inputWidth),
      errorHeight: getDistanceError(this.state.inputHeight),
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  async saveRow(evt) {
    if(evt) { evt.preventDefault(); }

    const { ShippingParcel } = require('../../../../models/shipping-methods');

    if(this.validateAll(true)) {

      const parcel = new ShippingParcel({
        isDefault: this.isDefaultRow,
        distanceUnit: this.distanceUnit,
        weightUnit: this.weightUnit,
        minWeight: this.isDefaultRow ? 0 : this.state.inputMinWeight,
        length: this.state.inputLength,
        width: this.state.inputWidth,
        height: this.state.inputHeight,
      });

      this.props.saveParcel(parcel, this.props.idx, () => {
        this.closeRow();
      });
    }
  }

  singleError() {
    return this.state.errorMinWeight || this.state.errorLength || this.state.errorWidth || this.state.errorHeight || '';
  }

  openRow() {
    this.setState(this.getInitState(), () => {
      this.setState({ rowOpen: true });
    });
  }

  closeRow() {
    if(this.props.parentCloseMethod) {
      this.props.parentCloseMethod();
    } else {
      this.setState(this.getInitState());
    }
  }

  toggleDeletePrompt() {
    this.setState({ deletePromptOpen: !this.state.deletePromptOpen });
  }

  deleteAction() {
    this.props.saveParcel(null, this.props.idx, () => {
      this.closeRow();
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationConfigParcelSizeRow'}>
      <div className='parcelRowWrapper'>
        {this.state.rowOpen ?
          <div className='rowOpenWrapper'>
            <form className='rowWrapper' onSubmit={this.saveRow.bind(this)}>
              <div className='rowElements'>
                <div className='rowElementWrapper'>
                  <div className='rowElement'>
                    <div className='elementLabel'>{t(tx.TX_WEIGHT_UNIT, { unit: this.weightUnit.display })}</div>
                    {this.isDefaultRow ?
                      <div className='elementValue'>{t(tx.TX_DEFAULT)}</div> :
                      <div className='elementInput'>
                        <input
                          type='number'
                          min={0}
                          step={1 / Math.pow(10, this.weightUnit.decimalPlaces)}
                          name={t(tx.TX_WEIGHT)}
                          className={this.state.errorMinWeight ? 'InputError' : ''}
                          value={this.state.inputMinWeight}
                          onChange={this.changeWeight.bind(this)}
                          onBlur={this.validateWeight.bind(this)}
                          placeholder={t(tx.TX_PLACEHOLDER_WEIGHT)} />
                      </div>
                    }
                  </div>
                </div>
                <div className='rowElementWrapper'>
                  <div className='rowElement'>
                    <div className='elementLabel'>{t(tx.TX_LENGTH_UNIT, { unit: this.distanceUnit.display })}</div>
                    <div className='elementInput'>
                      <input
                        type='number'
                        min={0}
                        step={1 / Math.pow(10, this.distanceUnit.decimalPlaces)}
                        name={t(tx.TX_LENGTH)}
                        className={this.state.errorLength ? 'InputError' : ''}
                        value={this.state.inputLength}
                        onChange={this.changeLength.bind(this)}
                        onBlur={this.validateLength.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_LENGTH)} />
                    </div>
                  </div>
                </div>
                <div className='rowElementWrapper'>
                  <div className='rowElement'>
                    <div className='elementLabel'>{t(tx.TX_WIDTH_UNIT, { unit: this.distanceUnit.display })}</div>
                    <div className='elementInput'>
                      <input
                        type='number'
                        min={0}
                        step={1 / Math.pow(10, this.distanceUnit.decimalPlaces)}
                        name={t(tx.TX_WIDTH)}
                        className={this.state.errorWidth ? 'InputError' : ''}
                        value={this.state.inputWidth}
                        onChange={this.changeWidth.bind(this)}
                        onBlur={this.validateWidth.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_WIDTH)} />
                    </div>
                  </div>
                </div>
                <div className='rowElementWrapper'>
                  <div className='rowElement'>
                    <div className='elementLabel'>{t(tx.TX_HEIGHT_UNIT, { unit: this.distanceUnit.display })}</div>
                    <div className='elementInput'>
                      <input
                        type='number'
                        min={0}
                        step={1 / Math.pow(10, this.distanceUnit.decimalPlaces)}
                        name={t(tx.TX_HEIGHT)}
                        className={this.state.errorHeight ? 'InputError' : ''}
                        value={this.state.inputHeight}
                        onChange={this.changeHeight.bind(this)}
                        onBlur={this.validateHeight.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_HEIGHT)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='rowActions'>
                <div className='rowActionsLiner'>
                  <button className='rowSaveAction' type='submit'>{t(tx.TX_SAVE)}</button>
                  <button className='rowSaveCancel' type='button' onClick={this.closeRow.bind(this)}>&times;</button>
                </div>
              </div>
              {this.singleError() ?
                <div className='rowError'>{t(this.singleError())}</div> :
                null
              }
            </form>
          </div> :
          <div className='rowClosedWrapper'>
            <div className='weightWrapper'>
              {this.props.row && this.props.row.isDefault ?
                <div className='weightDefault'>{t(tx.TX_DEFAULT)}</div> :
                <>
                  <div className='weightValue'>{this.props.row && this.props.row.minWeight ? normalizeWeight(this.props.row.minWeight, this.weightUnit) : ''}</div>
                  <div className='weightUnit'>{this.weightUnit.display}</div>
                </>
              }
            </div>
            <div className='dimensionsWrapper'>
              {this.props.row ?
                <div className='dimensionsValue'>
                  <div className='dimension'>{normalizeDistance(this.props.row.length, this.distanceUnit)}</div>
                  <div className='dimensionX'>&times;</div>
                  <div className='dimension'>{normalizeDistance(this.props.row.width, this.distanceUnit)}</div>
                  <div className='dimensionX'>&times;</div>
                  <div className='dimension'>{normalizeDistance(this.props.row.height, this.distanceUnit)}</div>
                </div> :
                null
              }
            </div>
            <div className='actionsWrapper'>
              <div className='actionsLiner'>
                <button className='editButton' type='button' onClick={this.openRow.bind(this)}>{t(tx.TX_EDIT)}</button>
                {this.props.idx !== 0 ?
                  <button className='deleteButton' type='button' onClick={this.toggleDeletePrompt.bind(this)}>&times;</button> :
                  null
                }
              </div>
            </div>
          </div>
        }
      </div>
      <Confirm
        title={tx.TX_DELETE_PROMPT_TITLE}
        copy={tx.TX_SETTINGS_INTEGRATIONS_PARCEL_DELETE_COPY}
        open={this.state.deletePromptOpen}
        confirmValue={tx.TX_DELETE}
        closeMethod={this.toggleDeletePrompt.bind(this)}
        onConfirm={this.deleteAction.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationConfigParcelSizeRow));