



// Auth actions

// export const ACTION_AUTH_CLEAR = 'ACTION_AUTH_CLEAR';
export const ACTION_AUTH_SET_PERMISSIONS = 'ACTION_AUTH_SET_PERMISSIONS';
export const ACTION_AUTH_RESET_PENDING = 'ACTION_AUTH_RESET_PENDING';
export const ACTION_AUTH_SET_AUTH = 'ACTION_AUTH_SET_AUTH';


// Cart actions

export const ACTION_CART_BUYLIST_CREATE_PENDING = 'ACTION_CART_BUYLIST_CREATE_PENDING';
export const ACTION_CART_BUYLIST_SET_CART = 'ACTION_CART_BUYLIST_SET_CART';
export const ACTION_CART_CREATE_PENDING = 'ACTION_CART_CREATE_PENDING';
export const ACTION_CART_FETCH_PENDING = 'ACTION_CART_FETCH_PENDING';
export const ACTION_CART_SET_BUYLIST_MODE = 'ACTION_CART_SET_BUYLIST_MODE';
export const ACTION_CART_SET_CART = 'ACTION_CART_SET_CART';
export const ACTION_CART_TOGGLE_MINICART_SLIDER = 'ACTION_CART_TOGGLE_MINICART_SLIDER';


// Checkout actions

export const ACTION_CHECKOUT_CLEAR_DATA = 'ACTION_CHECKOUT_CLEAR_DATA';
export const ACTION_CHECKOUT_SET_STEP_DATA = 'ACTION_CHECKOUT_SET_STEP_DATA';


// Common actions

export const ACTION_COMMON_ALERT_TOGGLE = 'ACTION_COMMON_ALERT_TOGGLE';


// Event actions

export const ACTION_EVENTS_CLEAR = 'ACTION_EVENTS_CLEAR';
export const ACTION_EVENTS_SET_MONTH = 'ACTION_EVENTS_SET_MONTH';


// Header actions

export const ACTION_HEADER_SET_MENU = 'ACTION_HEADER_SET_MENU';


// Product actions

export const ACTION_PRODUCT_CLEAR_FILTERS = 'ACTION_PRODUCT_CLEAR_FILTERS';
export const ACTION_PRODUCT_SET_FILTER_CUSTOM_OPTIONS = 'ACTION_PRODUCT_SET_FILTER_CUSTOM_OPTIONS';
export const ACTION_PRODUCT_SET_FILTERS = 'ACTION_PRODUCT_SET_FILTERS';
export const ACTION_PRODUCT_SET_NAME_VALUE = 'ACTION_PRODUCT_SET_NAME_VALUE';


// Product line actions

export const ACTION_PRODUCT_LINE_ALL_SET_PENDING = 'ACTION_PRODUCT_LINE_ALL_SET_PENDING';
export const ACTION_PRODUCT_LINE_ENABLED_SET_PENDING = 'ACTION_PRODUCT_LINE_ENABLED_SET_PENDING';
export const ACTION_PRODUCT_LINE_MANAGED_SET_PENDING = 'ACTION_PRODUCT_LINE_MANAGED_SET_PENDING';
export const ACTION_PRODUCT_LINE_MENUS_SET_PENDING = 'ACTION_PRODUCT_LINE_MENUS_SET_PENDING';
export const ACTION_PRODUCT_LINE_SET_ALL = 'ACTION_PRODUCT_LINE_SET_ALL';
export const ACTION_PRODUCT_LINE_SET_DATA = 'ACTION_PRODUCT_LINE_SET_DATA';
export const ACTION_PRODUCT_LINE_SET_ENABLED = 'ACTION_PRODUCT_LINE_SET_ENABLED';
export const ACTION_PRODUCT_LINE_SET_MANAGED = 'ACTION_PRODUCT_LINE_SET_MANAGED';
export const ACTION_PRODUCT_LINE_SET_MENUS = 'ACTION_PRODUCT_LINE_SET_MENUS';


// Store actions

export const ACTION_STORE_NOTICE_DISMISS = 'ACTION_STORE_NOTICE_DISMISS';
export const ACTION_STORE_NOTICE_RESET_DISMISSALS = 'ACTION_STORE_NOTICE_RESET_DISMISSALS';
export const ACTION_STORE_NOTICES_PENDING = 'ACTION_STORE_NOTICES_PENDING';
export const ACTION_STORE_SET_NOTICES = 'ACTION_STORE_SET_NOTICES';


// User actions

export const ACTION_USER_SET = 'ACTION_USER_SET';
export const ACTION_USER_SET_PENDING = 'ACTION_USER_SET_PENDING';
export const ACTION_USER_SET_STORE = 'ACTION_USER_SET_STORE';
export const ACTION_USER_STORE_FETCH_PENDING = 'ACTION_USER_STORE_FETCH_PENDING';
