import { useSelector, useDispatch } from 'react-redux';
import { storeNoticesDismiss } from '../actions/store';

export const useNotice = (position) => {
  
  const dispatch = useDispatch();

  // Redux state
  const notices = useSelector((state) => state.store.notices);
  const dismissedNotices = useSelector((state) => state.store.noticesDismissed);
  
  const notice = position && !dismissedNotices.includes(position)
    ? notices.find((nt) => nt.position === position) || null
    : null;

  // Function to dismiss a notice
  const dismissNotice = () => {
    dispatch(storeNoticesDismiss(position));
  };

  return {
    notice,
    dismissNotice,
  };
};
