import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CSS_TABLET_BREAK } from '../../../constants/css';
import { MKT_NOTICE_KEY_HEADER } from '../../../constants/marketing';
import {
  MENU_BUY,
  MENU_PLAY,
  MENU_SELL,
  MENU_USER,
} from '../../../constants/menu';

import useHeaderMenu from '../../../hooks/useHeaderMenu';
import { useNotice } from '../../../hooks/useNotice';

import { isLoggedIn } from '../../../utils/auth';

import { Icon } from '../../Icons/Icon';

import HeaderFullMenuFooter from './HeaderFullMenuFooter';

import styles from './_styles/HeaderFullMenu.module.scss';

const HeaderFullMenu = () => {

  // State
  const [currentWindowHeight, setCurrentWindowHeight] = useState(window.innerHeight);
  
  // Refs
  const menuBodyRef = useRef(null);
  
  // i18n
  const { t } = useTranslation();
  
  // Notice
  const { notice } = useNotice(MKT_NOTICE_KEY_HEADER);

  // Menu
  const {
    menu, 
    menuComponent,
    openMenu, 
    closeMenu,
  } = useHeaderMenu();

  const menuOpen = useCallback(() => {
    const availableMenus = [
      MENU_BUY,
      MENU_PLAY,
      MENU_SELL,
      MENU_USER,
    ];
    return menu && availableMenus.map(mn => mn.key).includes(menu.key);
  }, [ menu ]);

  const getMenuHeight = () => {

    let noticeHeight = 0;

    if(notice) {

      const noticeBreak = 900;
      const noticeHeightDesktop = 30;
      const noticeHeightMobile = 45;

      noticeHeight = window.innerWidth > noticeBreak ? noticeHeightDesktop : noticeHeightMobile;
    }

    return menuOpen() ? `${currentWindowHeight - noticeHeight}px` : '0';
  };

  // Effects
  useEffect(() => {
    const checkClick = (evt) => {
      if (window.innerWidth <= CSS_TABLET_BREAK && menuOpen()) {
        let targetElement = evt.target;
        do {
          if (targetElement === menuBodyRef.current) {
            return;
          }
          targetElement = targetElement.parentNode;
        } while (targetElement);
        closeMenu();
      }
    };

    const checkSize = () => {
      setCurrentWindowHeight(window.innerHeight);
    };

    document.addEventListener('click', checkClick);
    window.addEventListener('resize', checkSize);

    return () => {
      document.removeEventListener('click', checkClick);
      window.removeEventListener('resize', checkSize);
    };
  }, [ closeMenu, menuOpen ]);

  return (
    <div className={`${styles.HeaderFullMenu} ${!!notice ? styles.notified : ''}`}>
      <div
        className={styles.hfmOuterWrapper}
        ref={menuBodyRef}
        style={{ height: getMenuHeight() }}>
        
        <div className={styles.hfmInnerWrapper}>
          <div className={styles.hfmHeader}>
            <div className={styles.iconsWrapper}>
              <Icon
                value={MENU_BUY.icon}
                iconClass={menu && menu.key === MENU_BUY.key ? `${styles.iconElement} ${styles.current}` : styles.iconElement}
                iconWrapperClass={styles.iconSvg}
                bottomClass={styles.iconUnderline}
                iconLabel={t(MENU_BUY.title)}
                ellipsisLabel={true}
                onClick={() => openMenu(MENU_BUY.key)}
              />
              <Icon
                value={MENU_SELL.icon}
                iconClass={menu && menu.key === MENU_SELL.key ? `${styles.iconElement} ${styles.current}` : styles.iconElement}
                iconWrapperClass={styles.iconSvg}
                bottomClass={styles.iconUnderline}
                iconLabel={t(MENU_SELL.title)}
                ellipsisLabel={true}
                onClick={() => openMenu(MENU_SELL.key)}
              />
              <Icon
                value={MENU_PLAY.icon}
                iconClass={menu && menu.key === MENU_PLAY.key ? `${styles.iconElement} ${styles.current}` : styles.iconElement}
                iconWrapperClass={styles.iconSvg}
                bottomClass={styles.iconUnderline}
                iconLabel={t(MENU_PLAY.title)}
                ellipsisLabel={true}
                onClick={() => openMenu(MENU_PLAY.key)}
              />
              {isLoggedIn() && (
                <Icon
                  value={MENU_USER.icon}
                  iconClass={menu && menu.key === MENU_USER.key ? `${styles.iconElement} ${styles.current}` : styles.iconElement}
                  iconWrapperClass={styles.iconSvg}
                  bottomClass={styles.iconUnderline}
                  iconLabel={t(MENU_USER.title)}
                  ellipsisLabel={true}
                  onClick={() => openMenu(MENU_USER.key)}
                />
              )}
            </div>
          </div>
          <div className={styles.hfmVariable}>{menuComponent()}</div>
          <div className={styles.hfmFooter}>
            <HeaderFullMenuFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderFullMenu;
