import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../store';

import { API_KEY_LIMIT } from '../../../constants/api';
import { FV_KEY_NAME } from '../../../constants/filters';
import { ICON_SEARCH } from '../../../constants/icons';
import * as tx from '../../../constants/strings';
import {
  URL_BUY_GENERAL, 
  URL_BUY_PRODUCT_LINE, 
  URL_SELL_GENERAL,
  URL_SELL_PRODUCT_LINE,
  URL_NS_SELL,
} from '../../../constants/urls';

import { isCurrentRoute } from '../../../utils/general';

import { Icon } from '../../Icons/Icon';

import '../_styles/_headersearch.scss';

import * as productActionCreators from '../../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class HeaderSearch extends Component {

  constructor(props) {
    super(props);

    this.MIN_INPUT_LENGTH = 3;

    this.OPEN_DELAY = 200;

    this.state = {
      autocompleteActive: false,
      autocompleteClosable: false,

      navIndex: -1,

      options: null,

      lastQuery: null,
    };

    this.closeTimeout = null;

    this.autocompleteWrapperRef = React.createRef();

    this.controllerAutocomplete = null;

    this.checkClick = this.checkClick.bind(this);
    this.checkKeypress = this.checkKeypress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
    window.addEventListener('keydown', this.checkKeypress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
    window.removeEventListener('keydown', this.checkKeypress, false);

    if(this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
  }

  checkClick(evt) {
    if(this.state.autocompleteActive && this.state.autocompleteClosable) {
      let targetElement = evt.target;
      do {
        if(this.autocompleteWrapperRef && targetElement === this.autocompleteWrapperRef.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeAutocomplete();
    }
  }

  checkKeypress(evt) {
    if(evt) {
      switch(evt.key) {
        case 'ArrowUp':
          // evt.preventDefault();
          this.setState({ navIndex: Math.max(this.state.navIndex - 1, 0) });
          break;
        case 'ArrowDown':
          // evt.preventDefault();
          this.setState({ navIndex: Math.min(this.state.navIndex + 1, this.state.options ? this.state.options.length - 1 : 0) });
          break;
        case 'Enter':
          // evt.preventDefault();
          this.handleSelect(this.state.navIndex);
          break;
        case 'Escape':
          // evt.preventDefault();
          this.closeAutocomplete();
          break;
        default:
          break;
      }
    }
  }

  handleSelect(idx) {

    let searchValue = this.props.product.nameSearchValue;
    if(idx > -1 && this.state.options) {
      searchValue = this.state.options.length > idx ? this.state.options[idx].name : '';
    }

    if(searchValue) {
      this.props.productSetNameValue(searchValue);
      this.searchAction(searchValue);
    }
  }

  mouseEnterAction(idx) {
    if(idx !== this.state.navIndex) {
      this.setState({ navIndex: idx });
    }
  }
  
  mouseLeaveAction(idx) {
    if(idx === this.state.navIndex) {
      this.setState({ navIndex: -1 });
    }
  }

  openAutocomplete() {
    this.setState({
      autocompleteActive: true,
      autocompleteClosable: false,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({ autocompleteClosable: true });
      }, this.OPEN_DELAY);
    });
  }

  closeAutocomplete(cb = null) {
    this.setState({ 
      autocompleteActive: false,
      autocompleteClosable: false,
      navIndex: -1,
    }, () => {
      if(cb) { cb(); }
    });
  }

  async fetchAutocomplete(searchString = '') {

    // Async call to autocomplete products
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerAutocomplete = new AbortController();
    this.controllerAutocomplete = controllerAutocomplete;

    const searchVal = searchString.trim() || this.props.product.nameSearchValue.trim();
    const searchParams = {
      [API_KEY_LIMIT]: 10,
    }
    
    const searchResp = await this.props.productAutocomplete(searchVal, searchParams, controllerAutocomplete.signal)
      .catch((errResp) => {
        // Do nothing
      });

    if(!searchResp) { return null; }

    const optionsValue = searchResp.unique && searchResp.unique.length ? searchResp.unique : null;

    this.setState({ 
      options: optionsValue, 
    });

    if(optionsValue !== null && !this.state.autocompleteActive) {
      this.openAutocomplete();
    }
  }

  searchAction(searchString = '') {

    const searchValue = searchString || this.props.product.nameSearchValue || '';
    const passedFilters = {
      [FV_KEY_NAME]: searchValue,
    }

    const isBuylist = window.location.pathname.startsWith(`/${URL_NS_SELL}`);

    const generalUrl = isBuylist ? URL_SELL_GENERAL : URL_BUY_GENERAL;
    const productLineUrl = isBuylist ? URL_SELL_PRODUCT_LINE : URL_BUY_PRODUCT_LINE;

    const skipNav = isCurrentRoute(generalUrl) || isCurrentRoute(productLineUrl);
    this.closeAutocomplete(() => {
      if(skipNav) {
        history.replace(window.location.pathname, {filters: passedFilters});
      } else {
        history.push(generalUrl, {filters: passedFilters});
      }
    });
  }

  changeName(evt) {

    const inputValue = evt.target.value;

    this.props.productSetNameValue(inputValue);

    if(inputValue.trim().length >= this.MIN_INPUT_LENGTH) {
      this.fetchAutocomplete(inputValue);
    } else {
      this.setState({ options: null });
    }

    if(this.state.options !== null && !this.state.autocompleteActive) {
      this.openAutocomplete();
    }
  }

  render() {

    const {t} = this.props;

    return <div className='HeaderSearch' ref={this.autocompleteWrapperRef}>
      
      <div className='searchInnerWrapper'>
        <div className='searchInputWrapper'>
          <input 
            value={this.props.product.nameSearchValue}
            placeholder={t(tx.TX_PLACEHOLDER_SEARCH)}
            onChange={this.changeName.bind(this)} />
        </div>
        <div className='searchButtonWrapper' onClick={() => this.searchAction()}>
          <div className='searchButtonInnerWrapper'>{t(tx.TX_SEARCH)}</div>
          <div className='searchButtonInnerWrapperTablet'>
            <Icon 
              value={ICON_SEARCH} 
              iconClass='searchTabletIcon' />
          </div>
        </div>
        <div className={`searchAutocompleteWrapper ${this.state.autocompleteActive ? 'open' : ''}`}>
          {this.state.options !== null ?
            <div className='optionsLiner'>
              {this.state.options.length ?
                <div className='optionsList'>
                  {this.state.options.map((product, i) => {
                    return <div 
                              key={i} 
                              className={`optionElementWrapper ${this.state.navIndex === i ? 'hovered' : ''}`}
                              onMouseEnter={() => this.mouseEnterAction(i)}
                              onMouseLeave={() => this.mouseLeaveAction(i)}
                              onClick={() => this.handleSelect(i)}>{product.name}</div>
                  })}
                </div> :
                <div className='optionsNull'>
                  <div className='nullWrapper'>
                    <div className={'noResults'}>
                      <div className='nullLiner'>
                        {this.state.lastQuery ?
                          <>
                            <div className='noResultsCopy'>{t(tx.TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING)}</div>
                            <div className='noResultsNeedle'>{this.props.lastQuery}</div>
                          </> :
                          <div className='noResultsCopy'>{t(tx.TX_NO_RESULTS)}</div>
                        }
                      </div>
                    </div>
                  </div>
                  
                </div>
              }
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    product: state.product
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderSearch));


