import React, { useCallback, useEffect, useRef } from 'react';

import { MENU_USER } from '../../../constants/menu';

import useHeaderMenu from '../../../hooks/useHeaderMenu';

import styles from './_styles/HeaderUserMenu.module.scss';


const HeaderUserMenu = (props) => {

  // Props
  const {
    cssBreak = 0,
  } = props;
  
  // Refs
  const menuBodyRef = useRef(null);

  // Menu
  const {
    menu, 
    menuComponent,
    closeMenu,
  } = useHeaderMenu();

  // Methods
  const menuOpen = useCallback(() => {
    const availableMenus = [
      MENU_USER,
    ];
    return menu && availableMenus.map(mn => mn.key).includes(menu.key);
  }, [ menu ]);

  // Effects
  useEffect(() => {
    const checkClick = (evt) => {
      if(window.innerWidth > cssBreak && menuOpen()) {
        let targetElement = evt.target;
        do {
          if (targetElement === menuBodyRef.current) {
            return;
          }
          targetElement = targetElement.parentNode;
        } while (targetElement);
        closeMenu(null);
      }
    };

    document.addEventListener('click', checkClick, false);
    return () => {
      document.removeEventListener('click', checkClick, false);
    };
  }, [ closeMenu, cssBreak, menuOpen ]);

  return (
    <div className={styles.HeaderUserMenu}>
      <div 
        className={`${styles.humOuterWrapper} ${menuOpen() ? styles.open : ''}`}
        ref={menuBodyRef}>
        <div className={styles.humInnerWrapper}>
          {menuOpen() ? menuComponent() : null}
        </div>
      </div>
    </div>
  );
};

export default HeaderUserMenu;



