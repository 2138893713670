import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { STORE_CODE } from '../../../../constants/store';

import { stringFormat } from '../../../../utils/formatting';

import styles from './_styles/BlockContentImageInline.module.scss';

// Block configs; these should be defined in TS once Next migration complete
// theme: BLOCK_CONFIG_THEME_DARK,
// title: 'Seeking the Sun',
// body: '',
// actionLabel: TX_SHOP,
// actionUrl: URL_BUY_PRODUCT_LINE,
// actionUrlData: {
//   productLine: 'planetary-pins',
// },
// backgroundUrl: 'home/blocks/sunflowers.jpg',

export const BlockContentImageInline = (props) => {
  
  // Props
  const {
    block,
  } = props;

  // State  
  // None yet

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const getInnerStyle = () => {

    const innerStyle = {};

    // Right now, this will always be square, but in the future, we can add a config term for different height behaviors
    innerStyle['paddingBottom'] = '100%';

    innerStyle['backgroundImage'] = `url(/store/${STORE_CODE}/${block.getConfigAttr('backgroundUrl')})`;

    return innerStyle;
  }

  // Effects
  // None yet

  // Render
  return (
    <div className={`${styles.BlockContentImageInline}`}>
      <div className={styles.innerWrapper} style={getInnerStyle()}>
        {block.getConfigAttr('title') ?
          <div className={`${styles.titleWrapper} ${block.getConfigAttr('theme') ? styles[block.getConfigAttr('theme')] : ''}`}>{block.getConfigAttr('title')}</div> :
          null
        }
        {block.getConfigAttr('body') ?
          <div className={`${styles.bodyWrapper} ${block.getConfigAttr('theme') ? styles[block.getConfigAttr('theme')] : ''}`}>{block.getConfigAttr('body')}</div> :
          null
        }
        {block.getConfigAttr('actionLabel') && block.getConfigAttr('actionUrl') ?
          <div className={`${styles.actionWrapper}`}>
            <Link to={stringFormat(block.getConfigAttr('actionUrl'), block.getConfigAttr('actionUrlData'))}>
              <div className={styles.actionButton}>{t(block.getConfigAttr('actionLabel'))}</div>
            </Link>
          </div> :
          null
        }
      </div>
    </div>
  );
};

export default BlockContentImageInline;




