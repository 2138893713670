import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { 
  ID_TITAN_HEADER,
  ID_TITAN_NOTICE, 
} from '../../constants/dom';
import { MKT_NOTICE_KEY_HEADER } from '../../constants/marketing';

import { storeHeaderComponent } from '../../utils/website-config';

import HeaderBuySellPlay from './HeaderBuySellPlay';
import MiniCartSlider from '../Cart/blocks/MiniCartSlider';

import './_styles/_header.scss';

import * as cartActionCreators from '../../actions/cart';
import * as headerActionCreators from '../../actions/header';
import * as productLineActionCreators from '../../actions/product-line';
import * as storeActionCreators from '../../actions/store';
let allActionCreators = Object.assign({}, cartActionCreators, headerActionCreators, productLineActionCreators, storeActionCreators);

export class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      noticeBackgroundColor: null,
      noticeTextColor: null,
    };
  }

  componentDidMount() {
    
    // Intentionally doesn't check if data already present; should try to pick up any changes
    this.props.productLineFetchMenus()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    this.props.storeFetchNotices()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    this.setHeaderNoticeColors();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevProps.store.notices, this.props.store.notices)) {
      this.setHeaderNoticeColors();
    }
  }

  getHeaderNotice() {
    const allNotices = this.props.store.notices;
    for(const nt of allNotices) {
      if(nt.position === MKT_NOTICE_KEY_HEADER) {
        return nt;
      }
    }
    return null;
  }

  getActiveNotice() {
    if(this.props.store.noticesDismissed.includes(MKT_NOTICE_KEY_HEADER)) {
      return null;
    }
    return this.getHeaderNotice();
  }

  getNoticeStyle() {
    
    const notice = this.getActiveNotice();
    const noticeStyle = {};

    if(this.state.noticeTextColor) {
      noticeStyle['color'] = `#${this.state.noticeTextColor}`;
    } else if(notice && notice.textColor) {
      noticeStyle['color'] = `#${notice.textColor}`;
    }

    if(this.state.noticeBackgroundColor) {
      noticeStyle['backgroundColor'] = `#${this.state.noticeBackgroundColor}`;
    } else if(notice && notice.backgroundColor) {
      noticeStyle['backgroundColor'] = `#${notice.backgroundColor}`;
    }

    return noticeStyle;
  }

  dismissNotice() {
    const notice = this.getActiveNotice();

    if(notice) {
      this.props.storeNoticesDismiss(notice.position);
    }
  }

  setHeaderNoticeColors() {

    const allNotices = this.props.store.notices;
    for(const nt of allNotices) {
      if(nt.position === MKT_NOTICE_KEY_HEADER) {

        if(this.state.noticeTextColor !== nt.textColor) {
          this.setState({ noticeTextColor: nt.textColor });
        }

        if(this.state.noticeBackgroundColor !== nt.backgroundColor) {
          this.setState({ noticeBackgroundColor: nt.backgroundColor });
        }

        break;
      }
    }
  }

  getHeaderComponent() {
    const CustomHeader = storeHeaderComponent() || HeaderBuySellPlay;
    return <CustomHeader />;
  }

  render() {

    const notice = this.getActiveNotice();

    return <div className={`Header ${notice ? 'notified' : ''}`} id={ID_TITAN_HEADER}>
      <div className='headerSpacer'></div>
      <div className='headerFloatWrapper'>
        <div className='headerNotice' style={this.getNoticeStyle()} id={ID_TITAN_NOTICE}>
          {notice && notice.href ?
            <Link to={notice.linkTo} className='headerNoticeLink' style={this.getNoticeStyle()}>
              <div className='headerNoticeLiner'>
                <div className={'headerNoticeValue FlexCenter'}>{notice ? notice.copy : ''}</div>
              </div>
            </Link> :
            <div className='headerNoticeLiner'>
              <div className={'headerNoticeValue FlexCenter'}>{notice ? notice.copy : ''}</div>
            </div>
          }
          <div className='headerCloseWrapper' onClick={this.dismissNotice.bind(this)}>
            <div className='headerClose FlexCenter'>&times;</div>
          </div>
        </div>
        <div className='headerWrapper'>{this.getHeaderComponent()}</div>
      </div>
      <MiniCartSlider />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    cart: state.cart, 
    header: state.header,
    store: state.store,
  };
}

export default withRouter(connect(mapStateToProps, allActionCreators)(withTranslation()(Header)));


