import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERROR_RETURNS_REFUND_MAX } from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../../utils/currency';
import { getGenericNumberError } from '../../../../utils/form-validation';
import { 
  formatPrice,
  normalizePrice, 
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import '../../style/_vieworder.scss';

import Checkbox from '../../../Input/Checkbox';
import OrderLineItemRefundReturn from '../line-items/OrderLineItemRefundReturn';
import OrderLineItemShippingRefund from '../line-items/OrderLineItemShippingRefund';

import * as paymentsActionCreators from '../../../../actions/payments';
const allActionCreators = Object.assign({}, paymentsActionCreators);

export class OrderModalRefundStepInventory extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputCustomTotal: '',

      errorCustomTotal: '',

      customMode: false,

      selectedRows: this.props.selectedRows,
      quantityModifiers: this.props.quantityModifiers,
      returnValues: this.props.returnValues,

      shippingSelected: false,
    };

    this.scrollWrapper = React.createRef();

    this.nextTimout = null;
  }

  componentWillUnmount() {
    if(this.nextTimout) {
      clearTimeout(this.nextTimout);
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getScrollbarWidth() {
    if(!this.scrollWrapper || !this.scrollWrapper.current) { return 0; }

    return this.scrollWrapper.current.innerWidth - this.scrollWrapper.current.clientWidth;
  }

  getLineItems() {
    if(!this.props.order || this.props.order.isBuylist) { return []; }
    return this.props.order.orderCart.items;
  }

  checkToAllowNext() {
    this.nextTimout = setTimeout(() => {

      if(this.state.errorCustomTotal) {
        this.props.allowConfirm(false);
      } else if(this.state.customMode && parseFloat(this.state.inputCustomTotal) && parseFloat(this.state.inputCustomTotal) <= this.maxRefund()) {
        this.props.allowConfirm(true);
      } else {
        this.props.allowConfirm(this.getTotalRefundPrice() > 0);
      }

      this.props.syncData({
        selectedRows: this.state.selectedRows,
        quantityModifiers: this.state.quantityModifiers,
        returnValues: this.state.returnValues,
        refundTotal: this.state.customMode && !this.state.errorCustomTotal ? normalizePrice(this.state.inputCustomTotal) : this.getTotalRefundPrice(),
      });
    }, 50);
  }

  hasShippingRow() {
    return !!(this.props.order && this.props.order.totalShipping);
  }

  toggleSelectAll() {

    const allRows = this.getLineItems();

    if(this.state.selectedRows.length === allRows.length && this.hasShippingRow() === this.state.shippingSelected) {
      this.setState({ 
        selectedRows: [], 
        shippingSelected: false,
      }, () => {
        this.setState({ quantityModifiers: {} });
      });
    } else {
      this.setState({ 
        selectedRows: allRows,
        shippingSelected: !!this.hasShippingRow(), 
      });
    }

    if(this.state.requestError) {
      this.setState({ requestError: null });
    }

    this.checkToAllowNext();
  }

  toggleSelectAllInventoryReturn() {
    
    if(this.returnItemsAllSelected()) {
      this.setState({ returnValues: {} });
    } else {
      const allSelectedResp = {};
      const allItems = this.getLineItems();

      for(const item of allItems) {
        allSelectedResp[item.id.toString()] = true;
      }
      this.setState({ returnValues: allSelectedResp });
    }

    this.checkToAllowNext();
  }

  returnItemsAllSelected() {    
    
    const allItems = this.getLineItems();
    const selectedCount = allItems.reduce((acc, item) => acc + (this.getLineReturnValue(item) ? 1 : 0), 0);

    return allItems.length === selectedCount;
  }

  isAmbiguousReturnCheck() {
    
    const allItems = this.getLineItems();
    const selectedCount = allItems.reduce((acc, item) => acc + (this.getLineReturnValue(item) ? 1 : 0), 0);

    return selectedCount > 0 && allItems.length > selectedCount;
  }

  toggleRow(cartItem) {

    const selectedClone = [ ...this.state.selectedRows ];
    
    if(this.isRowSelected(cartItem)) {
      for(let i = 0; i < selectedClone.length; i++) {
        if(cartItem.id === selectedClone[i].id) {
          selectedClone.splice(i, 1);
          break;
        }
      }
      this.setState({ selectedRows: selectedClone }, () => {

        // Return values can stay the same since might as well keep the older value

        // Remove any quantity modifications
        const quantityModClone = { ...this.state.quantityModifiers };
        if(Object.keys(quantityModClone).includes(cartItem.id.toString())) {
          delete quantityModClone[cartItem.id.toString()];
          this.setState({ quantityModifiers: quantityModClone });
        }
      });
    } else {
      selectedClone.push(cartItem);
      this.setState({ selectedRows: selectedClone });
    }

    if(this.state.requestError) {
      this.setState({ requestError: null });
    }

    this.checkToAllowNext();
  }

  isRowSelected(cartItem) {

    if(!cartItem) { return false; }

    for(const item of this.state.selectedRows) {
      if(item.id === cartItem.id) {
        return true;
      }
    }
    return false;
  }

  getLineQuantity(cartItem) {
    if(!cartItem) { return 0; }

    if(this.isRowSelected(cartItem) && Object.keys(this.state.quantityModifiers).includes(cartItem.id.toString())) {
      return this.state.quantityModifiers[cartItem.id];
    }
    return cartItem.quantity;
  }

  setLineQuantity(cartItem, quantity) {

    if(!cartItem) { return false; }
    
    if(parseInt(quantity) === 0) {
      this.toggleRow(cartItem);
      return null;
    }

    const qtyValue = parseInt(quantity) || cartItem.quantity;
    this.setState({ quantityModifiers: Object.assign({}, this.state.quantityModifiers, { [cartItem.id]: qtyValue }) }, () => {
      this.checkToAllowNext();
    });
  }

  getLineReturnValue(cartItem) {
    if(!cartItem) { return false; }

    if(this.isRowSelected(cartItem) && Object.keys(this.state.returnValues).includes(cartItem.id.toString())) {
      return this.state.returnValues[cartItem.id.toString()];
    }
    return false;
  }

  setLineReturnValue(cartItem) {

    if(!cartItem) { return false; }

    const returnValClone = { ...this.state.returnValues };

    if(Object.keys(returnValClone).includes(cartItem.id.toString())) {
      this.setState({ returnValues: Object.assign({}, returnValClone, { [cartItem.id.toString()]: !returnValClone[cartItem.id] }) }, () => {
        this.checkToAllowNext();
      });
    } else {
      // First toggle state should be on
      this.setState({ returnValues: Object.assign({}, returnValClone, { [cartItem.id.toString()]: true }) }, () => {
        this.checkToAllowNext();
      });
    }
  }

  maxRefund() {
    if(!this.props.order) { return 0; }

    return Math.max(this.props.order.totalGrand - this.props.order.totalRefund, 0);
  }

  getShippingRefundAmount() {
    if(this.props.order && this.state.shippingSelected) {
      return this.props.order.totalShipping || 0;
    }
    return 0;
  }

  getTotalRefundPrice() {
    try {
      let totalRefund = 0;

      for(const item of this.state.selectedRows) {
        const itemQty = parseInt(this.getLineQuantity(item)) || 0;        
        totalRefund += itemQty*item.price;
      }

      return Math.min(totalRefund + this.getShippingRefundAmount() + this.getTaxRefundAmount() - this.getCouponRefundAmount(), this.maxRefund());
    } catch(err) {
      return 0;
    }
  }

  getSubtotalRefundPrice() {
    try {
      let totalRefund = 0;

      for(const item of this.state.selectedRows) {
        const itemQty = parseInt(this.getLineQuantity(item)) || 0;        
        totalRefund += itemQty*item.price;
      }

      return totalRefund + this.getShippingRefundAmount();
    } catch(err) {
      return 0;
    }
  }

  getDiscountedRefundTotal() {
    try {
      let totalRefund = 0;

      for(const item of this.state.selectedRows) {
        const itemQty = parseInt(this.getLineQuantity(item)) || 0;        
        totalRefund += itemQty*item.price;
      }

      return totalRefund + this.getShippingRefundAmount() - this.getCouponRefundAmount();
    } catch(err) {
      return 0;
    }
  }

  getOriginalTotal() {
    return this.props.order && this.props.order.totalGrand ? this.props.order.totalGrand : 0;
  }

  getAlreadyRefunded() {
    return this.props.order && this.props.order.totalRefund ? this.props.order.totalRefund : 0;
  }

  toggleShipping() {
    this.setState({ shippingSelected: !this.state.shippingSelected }, () => {
      this.checkToAllowNext();
    });
  }

  isAmbiguousCheck() {
    if(this.state.selectedRows.length > 0 && this.state.selectedRows.length < this.getLineItems().length) {
      return true;
    }
    if(this.hasShippingRow()) {
      if(this.state.selectedRows.length === this.getLineItems().length && this.getLineItems().length > 0 && !this.state.shippingSelected) {
        return true;
      } else if(this.state.selectedRows.length === 0 && this.state.shippingSelected) {
        return true;
      }
    }
    return false;
  }

  getTaxRefundAmount() {
    if(!this.props.order || !this.props.order.taxRateSales) { return 0; }
    return this.getDiscountedRefundTotal()*(this.props.order.taxRateSales/100);
  }

  hasPercentageCoupon() {
    if(!this.props.order || !this.props.order.coupon) { return false; }
    return this.props.order.coupon.isPercentage;
  }

  getCouponRefundAmount() {
    if(!this.hasPercentageCoupon()) { return 0; }
    return this.getSubtotalRefundPrice() * (this.props.order.coupon.discountAmount/100);
  }

  toggleCustomMode() {
    this.setState({
      customMode: !this.state.customMode,
      inputCustomTotal: !this.state.customMode ? normalizePrice(this.getTotalRefundPrice()) : '',
    })
  }

  changeCustomTotal(evt) {
    this.setState({
      inputCustomTotal: evt.target.value,
    }, () => {
      if(this.state.errorCustomTotal) {
        this.validateCustomTotal(false);
      }
      this.checkToAllowNext();
    });
  }

  validateCustomTotal(normalize = true) {
    this.setState({ 
      inputCustomTotal: normalize ? normalizePrice(this.state.inputCustomTotal) : this.state.inputCustomTotal, 
      errorCustomTotal: getGenericNumberError(this.state.inputCustomTotal, { max: this.maxRefund(), maxError: ERROR_RETURNS_REFUND_MAX }), 
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalRefundStepInventory OrderModalStep'}>
      <div className='omsLiner'>

        <div className={this.state.errorCustomTotal ? 'omFormError present' : 'omFormError'}>{t(this.state.errorCustomTotal || tx.TX_null)}</div>

        <div className='refundTotalWrapper'>
          <div className={'orderTotalWrapper totalWrapper'}>
            <div className='totalLabel'>{t(tx.TX_ORDER_ORIGINAL_TOTAL)}</div>
            <div className='totalValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getOriginalTotal(), { addTags: true, language: this.getLanguage() }) }} />
          </div>
          <div className={'alreadyRefundedWrapper totalWrapper'}>
            <div className='totalLabel'>{t(tx.TX_ORDER_ALREADY_REFUNDED)}</div>
            <div className='totalValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getAlreadyRefunded(), { addTags: true, language: this.getLanguage() }) }} />
          </div>
        </div>

        <div className='refundHeaderWrapper'>
          <div className='refundHeader'>
            <div className='headerCheckbox'>
              <div className='headerCheckboxInputWrapper'>
                <Checkbox 
                  adminTheme={true}
                  name={'return-refund-select-all'}
                  id={`select-all-order-modal-refund-create`}
                  value={'return-refund-select-all'}
                  disabled={this.getLineItems().length === 0}
                  checked={this.state.selectedRows.length === this.getLineItems().length && this.getLineItems().length > 0 && this.hasShippingRow() === this.state.shippingSelected}
                  ambiguousCheck={this.isAmbiguousCheck()}
                  onChange={this.toggleSelectAll.bind(this)} />
              </div>
            </div>
            <div className={'headerItem headerLabel'}>
              <div className={'headerLabelValue FlexCenterLeft'}>{t(tx.TX_ORDER_ORDER_ITEMS)}</div>
            </div>
            <div className={'headerQuantity headerLabel'}>
              <div className={'headerLabelValue FlexCenter'}>{t(tx.TX_QUANTITY)}</div>
            </div>
            <div className={'headerPrice headerLabel'}>
              <div className={'headerLabelValue FlexCenter'}>{t(tx.TX_PRICE)}</div>
            </div>
            <div className={'headerInventoryReturn headerLabel'}>
              <div className={'headerLabelToggle'}>
                <Checkbox 
                  adminTheme={true}
                  name={'return-refund-inv-select-all'}
                  id={`select-all-order-modal-refund-inv`}
                  value={'return-refund-inv-select-all'}
                  disabled={this.state.selectedRows.length === 0}
                  checked={this.returnItemsAllSelected()}
                  ambiguousCheck={this.isAmbiguousReturnCheck()}
                  onChange={this.toggleSelectAllInventoryReturn.bind(this)} />
              </div>
              <div className={'headerLabelValue'}>{t(tx.TX_ORDER_RETURN_TO_INVENTORY_QUESTION)}</div>
            </div>
          </div>
        </div>

        <div className='refundScrollableWrapper' ref={this.scrollWrapper}>
          <div className='refundScrollableElement'>
            <div className='refundScrollableLiner'>
              {this.getLineItems().length === 0 ?
                <div className='noItems'>
                  <div className='noItemsNotice'>{t(tx.TX_ORDER_REFUND_NO_ITEMS)}</div>
                </div> :
                <div className='itemsListWrapper'>
                  <div className='itemsList'>
                    {this.getLineItems().map((cartItem, i) => {
                      return <div key={i} className={`lineItemWrapper ${this.isRowSelected(cartItem) ? 'selected' : ''}`}>
                        <OrderLineItemRefundReturn
                          cartItem={cartItem}
                          isSelected={this.isRowSelected(cartItem)}
                          setQuantity={this.setLineQuantity.bind(this)}
                          valueQuantity={this.getLineQuantity(cartItem)}
                          setReturn={this.setLineReturnValue.bind(this)}
                          valueReturn={this.getLineReturnValue(cartItem)}
                          toggleRow={this.toggleRow.bind(this)}
                          selectedRows={this.state.selectedRows}
                          scrollbarWidth={this.getScrollbarWidth()} />
                      </div>;
                    })}
                    {this.hasShippingRow() ?
                      <div className={`lineItemWrapper ${this.state.shippingSelected ? 'selected' : ''}`}>
                        <OrderLineItemShippingRefund
                          order={this.props.order}
                          isSelected={this.state.shippingSelected}
                          toggleRow={this.toggleShipping.bind(this)}
                          scrollbarWidth={this.getScrollbarWidth()} />
                      </div> :
                      null
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div> 

        <div className='refundSummaryWrapper'>
          <div className='refundSummary'>
            <div className='summaryLineWrapper'>
              <div className='summaryLabel'>
                <div className='summaryLabelValue'>{t(tx.TX_ORDER_REFUND_SUBTOTAL)}</div>
              </div>
              <div className='summaryValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getSubtotalRefundPrice(), { addTags: true, language: this.getLanguage() }) }} />
            </div>
            {this.props.order && this.props.order.taxRateSales ?
              <div className='summaryLineWrapper'>
                <div className='summaryLabel'>
                  <div className='summaryLabelValue'>{t(tx.TX_TAX)}</div>
                </div>
                <div className='summaryValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getTaxRefundAmount(), { addTags: true, language: this.getLanguage() }) }} />
              </div> :
              null
            }
            {this.props.order && this.props.order.totalCoupon && this.hasPercentageCoupon() ?
              <div className='summaryLineWrapper'>
                <div className='summaryLabel'>
                  <div className='summaryLabelValue'>{t(tx.TX_COUPON)}</div>
                </div>
                <div className='summaryValue' dangerouslySetInnerHTML={{ __html: formatPrice(-1*this.getCouponRefundAmount(), { addTags: true, language: this.getLanguage() }) }} />
              </div> :
              null
            }
            {this.state.customMode ?
              <div className={'summaryLineWrapper totalSummary'}>
                <div className='summaryLabel'>
                  <div className='summaryLabelValue'>{t(tx.TX_ORDER_REFUND_CUSTOM_AMOUNT)}</div>
                  <div className='summaryLabelAction' onClick={this.toggleCustomMode.bind(this)}>{t(tx.TX_RESET)}</div>
                </div>
                <div className='summaryValue'>
                  <div className='summaryInputWrapper'>
                    <input
                      type='number'
                      className={`summaryInput ${this.state.errorCustomTotal ? 'InputError' : ''}`}
                      min={0}
                      max={this.maxRefund()}
                      step={getCurrencyIncrement()}
                      value={this.state.inputCustomTotal}
                      onChange={this.changeCustomTotal.bind(this)}
                      onBlur={this.validateCustomTotal.bind(this)} />
                    <div className={'summaryCurrencyOverlay FlexCenter'}>{getCurrencySymbol()}</div>
                  </div>
                </div>
              </div> :
              <div className={'summaryLineWrapper totalSummary'}>
                <div className='summaryLabel'>
                  <div className='summaryLabelValue'>{t(tx.TX_ORDER_TOTAL_REFUND)}</div>
                  <div className='summaryLabelAction' onClick={this.toggleCustomMode.bind(this)}>{t(tx.TX_EDIT)}</div>
                </div>
                <div className='summaryValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getTotalRefundPrice(), { addTags: true, language: this.getLanguage() }) }} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalRefundStepInventory));