import 'whatwg-fetch';

import * as n from '../constants/endpoints';
import * as a from '../constants/api';
import * as x from '../constants/actions';

import { authTokenRefresh } from './auth';

import { 
  StampsLabel,
  StampsRate,
} from '../models/integration-providers/stamps';
// import { IntegrationProvider } from '../models/integrations';
import { WebsiteNotice } from '../models/notices';
import { Layout } from '../models/pages';

import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/



export function storeSetNotices(payload) {
  return {
    type: x.ACTION_STORE_SET_NOTICES,
    payload
  }; 
}

export function storeNoticesPending(payload) {
  return {
    type: x.ACTION_STORE_NOTICES_PENDING,
    payload
  }; 
}

export function storeNoticesDismiss(payload) {
  return {
    type: x.ACTION_STORE_NOTICE_DISMISS,
    payload
  }; 
}

export function storeNoticesResetDismissals(payload) {
  return {
    type: x.ACTION_STORE_NOTICE_RESET_DISMISSALS,
    payload
  }; 
}


/******************************
*******  Asynchronous  ********
******************************/




export function storeFetchIntegrationProvidersPage(getParams, signal = null) {
  return async (dispatch, getState) => {

    const { IntegrationProvider } = require('../models/integrations');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_STORE_ADMIN_FETCH_INTEGRATION_PROVIDERS + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const respModels = [];
        for(const r of resp.data.data) {
          respModels.push(new IntegrationProvider(r));
        }
        return Promise.resolve(Object.assign({}, resp.data, { data: respModels }));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeAddIntegration(addData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_STORE_ADMIN_ADD_INTEGRATION;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(addData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeUpdateIntegration(updateData, integrationUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STORE_ADMIN_UPDATE_INTEGRATION, { publicUuid: integrationUuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(updateData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeDeleteIntegration(integrationUuid, signal = null) {
  return async (dispatch, getState) => {

    // const refreshMethod = await authTokenRefresh(dispatch, getState);
    // await refreshMethod();

    // const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PROMOTIONS_ADMIN_DELETE_COUPON, { code: couponCode });
    // return fetch(url, {
    //   method: 'delete',
    //   headers: authReq(a.API_HEADERS),
    //   signal, 
    // })
    // .then(parseJSON)
    // .then((resp) => {
    //   if(isOk(resp.status)) {
    //     return Promise.resolve(resp.data);
    //   } else {
    //     return Promise.reject(resp.data);
    //   }
    // });
  }
}


export function storeFetchProvider(providerKey, signal = null) {
  return async (dispatch, getState) => {

    const { IntegrationProvider } = require('../models/integrations');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STORE_ADMIN_FETCH_INTEGRATION_PROVIDER_SINGLE, { key: providerKey });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new IntegrationProvider(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeFetchNotices(signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    if(getState().store.noticesPending) {
      return Promise.reject(null);
    }
    dispatch(storeNoticesPending(true));

    const url = a.API_TITAN_API + n.ENDPOINT_WEBSITE_GET_NOTICES;
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      dispatch(storeNoticesPending(false));
      if(isOk(resp.status)) {
        const respModels = [];
        for(const r of resp.data) {
          respModels.push(new WebsiteNotice(r));
        }
        dispatch(storeSetNotices(respModels));
        return Promise.resolve(respModels);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeFetchAdminNotices(signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_WEBSITE_ADMIN_GET_NOTICES;
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const respModels = [];
        for(const r of resp.data) {
          respModels.push(new WebsiteNotice(r));
        }
        return Promise.resolve(respModels);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeCreateNotice(createData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_WEBSITE_ADMIN_CREATE_NOTICE;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(createData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeUpdateNotice(updateData, noticeUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_WEBSITE_ADMIN_UPDATE_NOTICE, { publicUuid: noticeUuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(updateData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}



export function storeIntegrationOauthAuthorize(authorizeData, integrationUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STORE_ADMIN_INTEGRATION_OAUTH_AUTHORIZE, { publicUuid: integrationUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(authorizeData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}



export function storeIntegrationOauthRevoke(authorizeData, integrationUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STORE_ADMIN_INTEGRATION_OAUTH_REVOKE, { publicUuid: integrationUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(authorizeData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsRates(ratesData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_FETCH_RATES, { publicUuid: orderUuid }) + getUrlParams(ratesData);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {        
        const respModels = [];
        for(const r of resp.data) {
          respModels.push(new StampsRate(r));
        }
        return Promise.resolve(respModels);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsCreateLabel(labelData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_CREATE_LABEL, { publicUuid: orderUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(labelData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {        
        return Promise.resolve(new StampsLabel(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsGetLabel(orderUuid, labelUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_FETCH_LABEL, { publicUuid: orderUuid, labelUuid: labelUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new StampsLabel(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsTestLabel(integrationUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_FETCH_TEST_LABEL, { integrationUuid: integrationUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new StampsLabel(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsParcelguardStatus(integrationUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_FETCH_PARCELGUARD_STATUS, { integrationUuid: integrationUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsParcelguardMakeClaim(orderUuid, labelUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_FETCH_PARCELGUARD_MAKE_CLAIM, { orderUuid: orderUuid, labelUuid: labelUuid });
    return fetch(url, {
      method: 'post',
      body: JSON.stringify({}),
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsParcelguardViewClaim(orderUuid, labelUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_FETCH_PARCELGUARD_VIEW_CLAIM, { orderUuid: orderUuid, labelUuid: labelUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeIntegrationStampsAddressVerification(shippingMethodUuid, addressUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_STAMPS_FETCH_ADDRESS_VARIANTS, { shippingMethodUuid: shippingMethodUuid, addressUuid: addressUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function storeFetchLayout(layoutKey, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_WEBSITE_FETCH_LAYOUT, { layoutKey: layoutKey });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Layout(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}







