import 'whatwg-fetch';

import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

// import { AnalyticsDataSalesByDate } from '../models/analytics/sales-by-date';
// import { AnalyticsDataOrdersByStatus } from '../models/analytics/orders-by-status';
// import { AnalyticsDataOrdersTotal } from '../models/analytics/orders-total';
// import { AnalyticsDataOrdersTopList } from '../models/analytics/top-list';
// import { Order, OrderGrading } from '../models/orders';

import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/



// None yet





/******************************
*******  Asynchronous  ********
******************************/



export function ordersFetchOrder(publicUuid, signal = null) {
  return async (dispatch, getState) => {

    const { Order } = require('../models/orders');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_FETCH_ORDER, { publicUuid: publicUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Order(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAdminFetchPage(getParams, signal = null) {
  return async (dispatch, getState) => {

    const { Order } = require('../models/orders');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_ADMIN_FETCH_LIST + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const respModels = [];
        for(const r of resp.data.data) {
          respModels.push(new Order(r));
        }
        return Promise.resolve(Object.assign({}, resp.data, { data: respModels }));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersFetchUserOrders(getParams, signal = null) {
  return async (dispatch, getState) => {

    const { Order } = require('../models/orders');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_FETCH_LIST + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const respModels = [];
        for(const r of resp.data.data) {
          respModels.push(new Order(r));
        }
        return Promise.resolve(Object.assign({}, resp.data, { data: respModels }));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAdminFetchOrder(publicUuid, signal = null) {
  return async (dispatch, getState) => {

    const { Order } = require('../models/orders');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_FETCH_ORDER, { publicUuid: publicUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Order(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersFetchUserOrder(publicUuid, signal = null) {
  return async (dispatch, getState) => {

    const { Order } = require('../models/orders');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_FETCH_ORDER, { publicUuid: publicUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Order(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAddShipment(addData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_ADD_SHIPMENT, { publicUuid: orderUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(addData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersCancelShipment(cancelData, orderUuid, shipmentUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_CANCEL_SHIPMENT, { 
      publicUuid: orderUuid, 
      shipmentUuid: shipmentUuid, 
    });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(cancelData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAddPickup(addData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_ADD_PICKUP, { publicUuid: orderUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(addData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersClosePickup(closeData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_CLOSE_PICKUP, { publicUuid: orderUuid, pickupUuid: closeData.public_uuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(closeData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersCancelOrder(cancelData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_CANCEL_ORDER, { orderUuid: orderUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(cancelData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersRecordGoodsReceipt(receiptData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_RECORD_GOODS_RECEIPT, { publicUuid: orderUuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(receiptData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersUpdateStatus(statusData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_UPDATE_ORDER, { publicUuid: orderUuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(statusData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersSendAdminMessage(sendData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_SEND_MESSAGE, { publicUuid: orderUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(sendData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAdminFetchGrading(orderUuid, gradingUuid, signal = null) {
  return async (dispatch, getState) => {

    const { OrderGrading } = require('../models/orders');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_FETCH_GRADING, { orderUuid: orderUuid, gradingUuid: gradingUuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new OrderGrading(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAdminCreateGrading(createData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_CREATE_GRADING, { orderUuid: orderUuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(createData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAdminCompleteGrading(completeData, orderUuid, gradingUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_COMPLETE_GRADING, { 
      orderUuid: orderUuid, 
      gradingUuid: gradingUuid, 
    });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(completeData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAdminCreateItemGrade(createData, orderUuid, gradingUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_CREATE_ITEM_GRADE, { 
      orderUuid: orderUuid, 
      gradingUuid: gradingUuid,
    });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(createData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAdminUpdateItemGrade(updateData, orderUuid, gradingUuid, itemGradeUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ADMIN_UPDATE_ITEM_GRADE, { 
      orderUuid: orderUuid, 
      gradingUuid: gradingUuid,
      itemGradeUuid: itemGradeUuid,
    });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(updateData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAnalyticsSalesByDate(getParams = {}, initConfig = {}, signal = null) {
  return async (dispatch, getState) => {

    const { AnalyticsDataSalesByDate } = require('../models/analytics/sales-by-date');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_ANALYTICS_SALES_BY_DATE + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new AnalyticsDataSalesByDate(Object.assign({}, resp.data, { config: initConfig })));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAnalyticsOrdersTotal(getParams = {}, signal = null) {
  return async (dispatch, getState) => {

    const { AnalyticsDataOrdersTotal } = require('../models/analytics/orders-total');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_ANALYTICS_ORDERS_TOTAL + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new AnalyticsDataOrdersTotal(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAnalyticsOrdersByStatus(getParams = {}, initConfig = {}, signal = null) {
  return async (dispatch, getState) => {

    const { AnalyticsDataOrdersByStatus } = require('../models/analytics/orders-by-status');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_ANALYTICS_ORDERS_BY_STATUS + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new AnalyticsDataOrdersByStatus(Object.assign({}, resp.data, { config: initConfig })));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function ordersAnalyticsTopList(aggregationType, getParams = {}, initConfig = {}, signal = null) {
  return async (dispatch, getState) => {

    const { AnalyticsDataOrdersTopList } = require('../models/analytics/top-list');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_ORDERS_ANALYTICS_TOP_LIST, { aggregationType: aggregationType }) + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new AnalyticsDataOrdersTopList(Object.assign({}, resp.data, { config: initConfig })));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}























