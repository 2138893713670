import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CSS_TABLET_BREAK } from '../../constants/css';
import {
  MENU_TYPE_BUY,
  MENU_TYPE_SELL,
  MENU_TYPE_PLAY,
  MENU_TYPE_USER,
} from '../../constants/menu';
import { 
  URL_BUY_GENERAL,
  URL_SELL_GENERAL, 
  URL_PLAY_GENERAL, 
} from '../../constants/urls';
import {
  TX_BUY,
  TX_SELL,
  TX_PLAY,
  TX_null,
} from '../../constants/strings';

import useHeaderMenu from '../../hooks/useHeaderMenu';

import { isLoggedIn } from '../../utils/auth';

import HeaderElementAccount from './elements/HeaderElementAccount';
import HeaderElementCart from './elements/HeaderElementCart';
import HeaderElementHamburger from './elements/HeaderElementHamburger';
import HeaderElementLogo from './elements/HeaderElementLogo';
import HeaderElementSearchDrawer from './elements/HeaderElementSearchDrawer';
import HeaderFullMenu from './menus/HeaderFullMenu';
import HeaderSearch from './elements/HeaderSearch';
import HeaderUserMenu from './menus/HeaderUserMenu';

import styles from './_styles/HeaderBuySellPlay.module.scss';

export const HeaderBuySellPlay = (props) => {
  
  // Constants
  const menuLinks = [
    {
      key: MENU_TYPE_BUY,
      url: URL_BUY_GENERAL,
      label: TX_BUY,
    },
    {
      key: MENU_TYPE_SELL,
      url: URL_SELL_GENERAL,
      label: TX_SELL,
    },
    {
      key: MENU_TYPE_PLAY,
      url: URL_PLAY_GENERAL,
      label: TX_PLAY,
    },
  ];

  // Hooks
  const {
    menu, 
    menuComponent,
    openMenu, 
    closeMenu,
  } = useHeaderMenu();

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const menuInHeader = () => {
    return menu && menuLinks.map(mn => mn.key).includes(menu.key);
  };

  // Render
  return (
    <div className={styles.HeaderBuySellPlay}>
      <div className={styles.displayWrapper}>
        <div className={styles.logoWrapper}>
          <HeaderElementLogo />
        </div>
        <div 
          className={`${styles.menuWrapper} ${menuInHeader() ? `${styles.open} ${styles[`${menu.key}Menu`]}` : ''}`}
          onMouseLeave={() => closeMenu({ ignoreTimeout: true })}>
          {menuLinks.map((link) => {
            return <NavLink 
              key={link.key}
              to={{
                pathname: link.url,
                state: {
                  clearFilters: true,
                }
              }} 
              className={styles.primaryNavLink} 
              activeClassName={menu ? '' : styles.current}>
              <div 
                className={`${styles.menuElementWrapper} ${menu && menu.key === link.key ? styles.active : ''}`}
                onMouseEnter={() => openMenu(link.key, { ignoreTimeout: true })}>
                <div
                  className={styles.menuTli}>
                  {t(link.label)}
                </div>
              </div>
            </NavLink>
          })}
          <div className={styles.menuDropdownWrapper} style={{ display: menu ? 'block' : 'none' }}>
            <div className={styles.menuDropdownTitle}>
              {t(menuInHeader() ? menu.title || TX_null : TX_null)}
            </div>
            <div className={styles.menuDropdownBody}>
              {menuInHeader() ? menuComponent() : null}
            </div>
            <div className={styles.menuDropdownFooter}></div>
          </div>
        </div>
        <div className={styles.searchWrapper}>
          <HeaderSearch />
        </div>
        <div className={styles.accountWrapper}>
          <HeaderElementAccount />
        </div>
        <div className={styles.cartWrapper}>
          <HeaderElementCart />
        </div>
        <div className={styles.hamburgerWrapper}>
          <HeaderElementHamburger
            menuType={MENU_TYPE_USER} />
        </div>
        <div className={styles.searchDrawerWrapper}>
          <HeaderElementSearchDrawer />
        </div>
      </div>
      {isLoggedIn() ?
        <div className={styles.userMenuWrapper}>
          <HeaderUserMenu cssBreak={CSS_TABLET_BREAK} />
        </div> :
        null
      } 
      <HeaderFullMenu />
    </div>
  );
};

export default HeaderBuySellPlay;




