import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadGoogleTagManager } from '../../lib/google-tag-manager';
import { loadGoogleAnalytics } from '../../lib/google-analytics';

import { APP_IS_PROD } from '../../constants/app';
import { 
  STORE_GA_TRACKING_IDS,
  STORE_GTM_IDS,
  STORE_MAINTENANCE_MODE, 
} from '../../constants/store';
import { URL_NS_ADMIN } from '../../constants/urls';

import Alert from '../Popups/Alert';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import MenuScreen from '../Popups/MenuScreen';

import '../../../style/_base.scss'; // Global styles
import './styles/_app.scss';

import * as cartActionCreators from '../../actions/cart';
import * as productLineActionCreators from '../../actions/product-line';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, cartActionCreators, productLineActionCreators, userActionCreators);

export class App extends Component {

  componentDidMount() {
    
    // Don't check if data already present; should try to pick up any changes

    // Get menu content
    this.props.productLineFetchMenus()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    // Get enabled product lines
    this.props.productLineFetchEnabled()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    // Get cart, if one exists
    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    // Get store data
    this.props.userFetchStore()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    this.props.usersFetchMe()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    if(APP_IS_PROD || true) {
      for(const gaId of STORE_GA_TRACKING_IDS) {
        loadGoogleAnalytics(gaId);
      }
      for(const gtmId of STORE_GTM_IDS) {
        loadGoogleTagManager(gtmId);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check for login, and refire appropriate requests
    if(!this.showMaintenance() && prevProps.auth.loginTime !== this.props.auth.loginTime) {

      // Get cart, if one exists
      this.props.cartFetchCart()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });
    }
  }

  showMaintenance() {
    if(STORE_MAINTENANCE_MODE === true) {
      const path = window.location.pathname;
      if(path.startsWith(`/${URL_NS_ADMIN}`)) {
        return false;
      }
      return true;
    }
    return false;
  }

  render() {
    if(this.showMaintenance()) {
      return <div className='App appMaintenance'>
        <div className='appWrapper maintenanceWrapper'>{this.props.children}</div>
      </div>;
    } else {
      return <div className='App'>
        <Header />
        <div className='appWrapper'>{this.props.children}</div>
        <Footer />
        <MenuScreen />
        <Alert />
      </div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, allActionCreators)(App);