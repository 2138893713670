import React from 'react';

import { ICON_MENU } from '../../../constants/icons';

import useHeaderMenu from '../../../hooks/useHeaderMenu';

import Icon from '../../Icons/Icon';

import styles from './_styles/HeaderElementHamburger.module.scss';

export const HeaderElementHamburger = (props) => {
  
  const {
    menuType,
  } = props;

  // Hooks
  const {
    toggleMenu,
  } = useHeaderMenu();

  // Methods
  const handleClick = () => {
    toggleMenu(menuType);
  };

  // Render
  return (
    <div className={styles.HeaderElementHamburger} onClick={handleClick}>
      <Icon 
        value={ICON_MENU} 
        iconClass={styles.hamburgerIconWrapper} />
    </div>
  );
};

export default HeaderElementHamburger;




