import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { MENU_TYPE_USER } from '../../../constants/menu';
import {
  TX_CREATE_ACCOUNT,
  TX_HELLO_EXCLAIMATION,
  TX_HELLO_NAME,
  TX_LOADING,
} from '../../../constants/strings';
import { URL_REGISTER } from '../../../constants/urls';

import { isLoggedIn } from '../../../utils/auth';

import styles from './_styles/HeaderHelloUser.module.scss';

import * as headerActionCreators from '../../../actions/header';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, headerActionCreators, userActionCreators);

export class HeaderHelloUser extends Component {

  componentDidMount() {

    if(this.props.user && this.props.user.user === null) {
      
      this.props.usersFetchMe()
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className={styles.HeaderHelloUser}>
      {isLoggedIn() ?
        <div>
          {!this.props.user.user ?
            <div className={`${styles.helloWrapper} ${styles.loadingWrapper}`}>
              <div className={'FlexCenter'}>{t(TX_LOADING)}</div>
            </div> :
            <div className={styles.helloWrapper}>
              <div className={styles.helloInnerWrapper}>
                <div className={`${styles.helloName} EllipsisElement`}>
                  {this.props.user.user.firstName ? t(TX_HELLO_NAME, {name: this.props.user.user.firstName}) : t(TX_HELLO_EXCLAIMATION)}
                </div>
                <div className={`${styles.helloEmail} EllipsisElement`}>
                  {this.props.user.user.email ? this.props.user.user.email : ''}
                </div>
              </div>
            </div>
          }
        </div> :
        <div className={styles.helloWrapper}>
          <div className={styles.helloInnerWrapper}>
            <div className={`${styles.helloName} EllipsisElement`}>
              {t(TX_HELLO_EXCLAIMATION)}
            </div>
            <Link 
              to={URL_REGISTER} 
              onClick={() => this.props.headerSetMenu(this.props.header.menuActive ? null : MENU_TYPE_USER)} 
              className={styles.helloLink}>
              <div className={`${styles.helloEmail} EllipsisElement`}>{t(TX_CREATE_ACCOUNT)}</div>
            </Link>
          </div>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header,
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderHelloUser));


