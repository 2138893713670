import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import { PokemonCardImage } from '../../models/syncs/pokemon';

import StaticImage from '../Image/StaticImage';
import ProgressiveImage from '../Image/ProgressiveImage';

import './style/_pokemon.scss';

let allActionCreators = Object.assign({});

export class PokemonImage extends Component {

  render() {

    const { t } = this.props;
    const { PokemonCardImage } = require('../../models/syncs/pokemon');

    return <div className={'PokemonImage'}>      
      <div className='pokemonObjImageWrapper'>
        {this.props.thumbnail ?
          <StaticImage
            imgObj={{
              src: this.props.pokemonObj.thumbnailImageSrc(), 
              alt: this.props.pokemonObj.localizedName, 
              noTranslate: true,
            }}
            onImgLoad={this.props.onLoadLow} /> :
          <ProgressiveImage
            alt={this.props.pokemonObj.imageAlt(t)}
            className='pokemonObjImageImg'
            src={this.props.pokemonObj.primaryImageSrc()}
            thumbnailSrc={this.props.pokemonObj.thumbnailImageSrc()}
            loadDelay={this.props.loadDelay}
            placeholder={PokemonCardImage.defaultThumbnailSrc()}
            onLoadPlaceholder={this.props.onLoadLow} />
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PokemonImage));