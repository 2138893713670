import 'whatwg-fetch';

import * as x from '../constants/actions';
import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

// import { 
//   PaymentMethod,
//   PaymentRecord, 
// } from '../models/payment-methods';
import { ShippingMethod } from '../models/shipping-methods';

import { stringFormat } from '../utils/formatting';
import { 
  authReq,
  getUrlParams,
  isOk,
  parseJSON, 
} from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/



export function checkoutSetStepData(payload) {
	return {
    type: x.ACTION_CHECKOUT_SET_STEP_DATA,
    payload
  };
}

export function checkoutClearStepData() {
  return {
    type: x.ACTION_CHECKOUT_CLEAR_DATA,
  }
}





/******************************
*******  Asynchronous  ********
******************************/



export function checkoutFetchAdminShippingMethods(signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_CHECKOUT_GET_ADMIN_SHIPPING_METHODS;
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const smArray = [];
        for(const sm of resp.data) {
          smArray.push(new ShippingMethod(sm));
        }
        return Promise.resolve(smArray);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}

export function checkoutFetchShippingMethods(shippingAddressUuid = null, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const getData = shippingAddressUuid ? { "shipping_address_uuid": shippingAddressUuid } : {};

    const url = a.API_TITAN_API + n.ENDPOINT_CHECKOUT_GET_SHIPPING_METHODS + getUrlParams(getData);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}

export function checkoutAddShippingMethod(addData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_CHECKOUT_ADD_SHIPPING_METHOD;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(addData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}

export function checkoutUpdateShippingMethod(editData, uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CHECKOUT_UPDATE_SHIPPING_METHOD, { uuid: uuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(editData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}

export function checkoutShippingMethodDelete(uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CHECKOUT_DELETE_SHIPPING_METHOD, { uuid: uuid });
    return fetch(url, {
      method: 'delete',
      headers: authReq(a.API_HEADERS),
      signal, 
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutFetchSingleShippingMethods(uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CHECKOUT_FETCH_SINGLE_SHIPPING_METHOD, { uuid: uuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutFetchPaymentMethods(signal = null) {
  return async (dispatch, getState) => {

    const { PaymentMethod } = require('../models/payment-methods');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_CHECKOUT_FETCH_PAYMENT_METHODS;
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const pmArray = [];
        for(const pm of resp.data) {
          pmArray.push(new PaymentMethod(pm));
        }
        return Promise.resolve(pmArray);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutFetchBuylistPaymentMethods(signal = null) {
  return async (dispatch, getState) => {

    const { PaymentMethod } = require('../models/payment-methods');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_CHECKOUT_FETCH_BUYLIST_PAYMENT_METHODS;
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const pmArray = [];
        for(const pm of resp.data) {
          pmArray.push(new PaymentMethod(pm));
        }
        return Promise.resolve(pmArray);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutFetchAdminPaymentMethods(signal = null) {
  return async (dispatch, getState) => {

    const { PaymentMethod } = require('../models/payment-methods');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_CHECKOUT_FETCH_ADMIN_PAYMENT_METHODS;
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const pmArray = [];
        for(const pm of resp.data) {
          pmArray.push(new PaymentMethod(pm));
        }
        return Promise.resolve(pmArray);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutAddPaymentMethod(addData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_CHECKOUT_ADD_PAYMENT_METHOD;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(addData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutUpdatePaymentMethod(editData, uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CHECKOUT_UPDATE_PAYMENT_METHOD, { uuid: uuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(editData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutPaymentMethodDelete(uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CHECKOUT_DELETE_PAYMENT_METHOD, { uuid: uuid });
    return fetch(url, {
      method: 'delete',
      headers: authReq(a.API_HEADERS),
      signal, 
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutFetchSinglePaymentMethod(uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CHECKOUT_FETCH_SINGLE_PAYMENT_METHOD, { uuid: uuid });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}



export function checkoutMakePaymentSquare(paymentData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_PAYMENT_SQUARE_MAKE_PAYMENT;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(paymentData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutInitiatePaymentTransbank(paymentData, signal = null) {
  return async (dispatch, getState) => {

    const { PaymentRecord } = require('../models/payment-methods');

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_PAYMENT_TRANSBANK_INITIATE_PAYMENT;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(paymentData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new PaymentRecord(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutPlaceOrder(orderData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_PLACE_ORDER;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(orderData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutPlaceBuylistOrder(orderData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_PLACE_BUYLIST_ORDER;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(orderData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function checkoutAdminPlaceBuylistOrder(orderData, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_ORDERS_ADMIN_PLACE_BUYLIST_ORDER;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(orderData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}



