import React from 'react';
import { useTranslation } from 'react-i18next';

import { parseContent } from '../../utils/cms/parseContent';
import transl from '../../utils/i18n/transl';
import { isTranslatedString } from '../../utils/i18n/translatedString';


const CmsElementRenderer = ({ element, styleModule }) => {
    
    // Internationalization
    const { t } = useTranslation();

    const { 
        tag, 
        content, 
        style, 
        className,
    } = element;

    // Resolve the className with the style module if available
    const resolvedClassName = className && styleModule ? styleModule[className] : className;

    // Check if content needs translation
    let renderedContent = content;

    if(isTranslatedString(content)) {
        renderedContent = transl(t, content);
    }

    if(typeof renderedContent === 'string') {
        // Simple content, directly render
        const parsedContent = parseContent(renderedContent);
        return React.createElement(tag, { style, className: resolvedClassName }, ...parsedContent);
    }

    if(Array.isArray(renderedContent)) {
        // Recursive rendering for nested elements
        return React.createElement(
            tag,
            { style, className },
            renderedContent.map((child, index) => (
                <CmsElementRenderer key={index} element={child} styleModule={styleModule} />
            ))
        );
    }

    // If content is undefined or not handled, render an empty tag
    return React.createElement(tag, { style, className });
};

export default CmsElementRenderer;