

import { 
	STORE_CODE, 
	STORE_NAME, 
} from '../../../constants/store';



export class ContentBanner {

	constructor(props) {
		
		if(!props) { 
			props = {}; 
		}

		this.backgroundUrl = props.backgroundUrl || props.background_url || `/store/${STORE_CODE}/home/banner.jpg`;
		this.backgroundUrlTablet = props.backgroundUrlTablet || props.background_url_tablet || '';
		this.backgroundUrlMobile = props.backgroundUrlMobile || props.background_url_mobile || `/store/${STORE_CODE}/home/banner-mobile.jpg`;

		this.backgroundAlt = props.backgroundAlt || props.background_alt || STORE_NAME;

		this.linkSrc = props.linkSrc || props.link_src || '';
		this.linkFilters = props.linkFilters || props.link_filters || {};
	}

	get linkState() {
		const stateResp = {};

		if(this.linkFilters && Object.keys(this.linkFilters).length > 0) {
			stateResp['filters'] = this.linkFilters;
		}
		return stateResp;
	}

	get backgroundDesktop() {
		return this.backgroundUrl;
	}

	get backgroundTablet() {
		return this.backgroundUrlTablet || this.backgroundUrl;
	}

	get backgroundMobile() {
		return this.backgroundUrlMobile || this.backgroundUrlTablet || this.backgroundUrl;
	}
}








