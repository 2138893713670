
import * as x from '../constants/actions';


/******************************
*******  Synchronous  *********
******************************/


export function headerSetMenu(payload, config = {}) {
  return {
    type: x.ACTION_HEADER_SET_MENU,
    payload, 
    config,
  };
}



/******************************
*******  Asynchronous  ********
******************************/


// None yet
