import React from 'react';
import { Link } from 'react-router-dom';

import styles from './_styles/PrimaryNavLink.module.scss';

export const PrimaryNavLink = (props) => {
  
  // Props
  const {
    to,
    className,
    children,
  } = props;

  // Render
  return (
    <div className={`${styles.PrimaryNavLink}`}>
      <Link to={to} className={`${styles.linkElement} ${className || ''}`}>
        {children}
      </Link>
    </div>
  );
};

export default PrimaryNavLink;




