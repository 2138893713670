import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ANALYTICS_DURATION_UNIT_MONTH } from '../../../../constants/analytics';
import {
  API_KEY_DURATION, 
  API_KEY_IS_BUYLIST, 
} from '../../../../constants/api';
import * as tx from '../../../../constants/strings';

import { AnalyticsDataSalesByDate } from '../../../../models/analytics/sales-by-date';

import { formatServerError } from '../../../../utils/formatting';

import usePrevious from '../../../../hooks/usePrevious';

import { productLinesFetchAll } from '../../../../actions/product-line';
import { ordersAnalyticsSalesByDate } from '../../../../actions/order';

import LineGraph from '../../../Graph/LineGraph';
import AnalyticsGraphConfigMenu from './graph/AnalyticsGraphConfigMenu';
import AnalyticsGraphDateRange from './graph/AnalyticsGraphDateRange';

import styles from '../../style/BlockAnalyticsSalesGraph.module.scss';


export const BlockAnalyticsSalesGraph = (props) => {

  // Dispatch
  const dispatch = useDispatch();
  
  // Constants
  const graphHeight = 400;

  // Props
  const {
    block,
  } = props;

  // State
  const [data, setData] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [disabledSegments, setDisabledSegments] = useState([]);
  const [duration, setDuration] = useState(block && block.config && block.config.init && block.config.init.duration ? block.config.init.duration : `1${ANALYTICS_DURATION_UNIT_MONTH}`);
  const [productLines, setProductLines] = useState(null);
  const [productLinesError, setProductLinesError] = useState(null);
  const [productLinesLoading, setProductLinesLoading] = useState(false);

  // Previous data
  const prevDuration = usePrevious(duration);

  // Refs
  const controllerRef = useRef(null);

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const fetchData = useCallback(async (requestConfig) => {

    setDataError(null);
    setDataLoading(true);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const getData = {
      [API_KEY_DURATION]: duration,
      [API_KEY_IS_BUYLIST]: false,
    };
  
    // Fetch data
    const resp = await dispatch(ordersAnalyticsSalesByDate(getData, requestConfig, controller.signal))
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        setDataError(formatServerError(errResp));
        setDataLoading(false);
      });

    if(!resp) {
      return null;
    }

    setData(resp);
    setDataLoading(false);

  }, [ dispatch, duration ]);


  const fetchProductLines = useCallback(async () => {

    setProductLinesError(null);
    setProductLinesLoading(true);
  
    // Fetch product lines
    const resp = await dispatch(productLinesFetchAll())
      .catch((errResp) => {
        console.error(errResp);
        setProductLinesError(formatServerError(errResp));
        setProductLinesLoading(false);
      });

    if(!resp) {
      return null;
    }

    setProductLines(resp);
    setProductLinesLoading(false);

  }, [ dispatch ]);


  const getDataDisplayConfig = () => {
    return {
      disabledSegments: disabledSegments,
      duration: duration,
      productLines: productLines,
      t: t,
    };
  };


  const toggleDisabledSegment = (segmentLabel) => {
    if(disabledSegments.includes(segmentLabel)) {
      const filteredSegments = disabledSegments.filter(s => s !== segmentLabel);
      setDisabledSegments(filteredSegments);
    } else {
      const clonedDisabledSegments = [ ...disabledSegments ];
      clonedDisabledSegments.push(segmentLabel);
      setDisabledSegments(clonedDisabledSegments);
    }
  };


  const setRequestConfig = (key, value) => {
    if(!data) { return null; }
    data.setConfigValue(key, value);
    setData(new AnalyticsDataSalesByDate(data));
  };


  // Effects
  useEffect(() => {
    return () => {
      if(controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);


  useEffect(() => {
    fetchProductLines();
  }, [ fetchProductLines ]);


  useEffect(() => {
    if(data === null) {
      fetchData({});
    }
  }, [ data, fetchData ]);


  useEffect(() => {
    if(data !== null && prevDuration && duration !== prevDuration) {
      fetchData({});
    }
  }, [ data, duration, fetchData, prevDuration ]);


  // Render
  return (
    <div className={styles.BlockAnalyticsSalesGraph}>
      <div className={styles.salesGraphWrapper}>
        <div className={styles.sgHeaderWrapper}>
          <div className={styles.sgTitleWrapper}>{t(tx.TX_ANALYTICS_SALES_TITLE)}</div>
          {!dataError && !productLinesError ?
            <div className={styles.sgControlsWrapper}>
              <div className={styles.sgRangeWrapper}>
                <AnalyticsGraphDateRange
                  dataLoading={dataLoading || productLinesLoading}
                  dataModel={data}
                  duration={duration}
                  setDuration={setDuration} />
              </div>
              <div className={styles.sgConfigWrapper}>
                <AnalyticsGraphConfigMenu
                  dataLoading={dataLoading || productLinesLoading}
                  dataModel={data}
                  duration={duration}
                  setConfigValue={setRequestConfig}
                  setDuration={setDuration} />
              </div>
            </div> :
            null
          }
        </div>
        <div className={styles.sgBodyWrapper}>
          {dataError || productLinesError ?
            <div className={styles.sgErrorWrapper}>{t(dataError || productLinesError)}</div> :
            <div className={styles.sgGraphWrapper}>
              <LineGraph
                adminTheme={true}
                colorMapping={data ? data.getColorObject(getDataDisplayConfig()) : {}}
                data={data ? data.getLineGraphData(getDataDisplayConfig()) : []}
                dataModel={data}
                dataLoading={dataLoading || productLinesLoading}
                disabledSegments={disabledSegments}
                toggleDisabledSegment={toggleDisabledSegment}
                height={graphHeight}
                productLines={productLines}
                showLegend={data ? data.shouldShowLegend() : false} />
            </div>
          }
        </div>

      </div>
    </div>
  );
};

export default BlockAnalyticsSalesGraph;




