import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_USER } from '../../../constants/icons';
import { MENU_TYPE_USER } from '../../../constants/menu';
import * as tx from '../../../constants/strings';
import { 
  URL_HOME, 
  URL_LOGIN, 
} from '../../../constants/urls';

import { isLoggedIn, logout } from '../../../utils/auth';

import { Icon } from '../../Icons/Icon';
import HeaderHelloUser from '../elements/HeaderHelloUser';

import styles from './_styles/HeaderFullMenuFooter.module.scss';

import * as headerActionCreators from '../../../actions/header';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, headerActionCreators, userActionCreators);

export class HeaderFullMenuFooter extends Component {

  logoutUser() {
    this.props.usersLogout()
    .then(() => {
      window.location.href = URL_HOME;
    })
    .catch((errResp) => {
      console.error(errResp);
      window.location.href = URL_HOME;
    });
    logout();
  }

  render() {

    const {t} = this.props;

    return <div className={styles.HeaderFullMenuFooter}>
      <div className={styles.hfmfFooterWrapper}>
        <div className={styles.hfmfIconWrapper}>
          <Icon 
            value={ICON_USER} 
            iconClass={styles.iconElement} 
            iconLabel='' />
        </div>
        <div className={styles.hfmfBody}>
          <div className={styles.hfmfBodyWrapper}>
            <div className={styles.bodyContent}>
              <div className={styles.helloWrapper}>
                <HeaderHelloUser />
              </div>
            </div>
            {isLoggedIn() ? 
              <div className={styles.actionWrapper}>
                <div 
                  className={styles.hfmfButton}
                  onClick={this.logoutUser.bind(this)}>
                  <div className={'FlexCenter'}>{t(tx.TX_LOGOUT)}</div>
                </div>
              </div> :
              <Link to={URL_LOGIN} onClick={() => this.props.headerSetMenu(this.props.header.menuActive ? null : MENU_TYPE_USER)}>
                <div className={styles.actionWrapper}>
                  <div className={styles.hfmfButton}>
                    <div className={'FlexCenter'}>{t(tx.TX_LOGIN_SHORT)}</div>
                  </div>
                </div>
              </Link>
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderFullMenuFooter));


