import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as _ from 'underscore';

import {
  TX_MIN,
  TX_MAX,
} from '../../../../constants/strings';

import useFormValidationPrice from '../../../../hooks/form-validation/useFormValidationPrice';
import usePrevious from '../../../../hooks/usePrevious';

import { 
  getCurrencyIncrement,
  getCurrencySymbol, 
} from '../../../../utils/currency';
import {
  getFilterMinKey,
  getFilterMaxKey,
  getFilterMinLabel,
  getFilterMaxLabel,
} from '../../../../utils/filters';
import { 
  safeParseFloat,
  simulateTab, 
} from  '../../../../utils/general';

import filterStyles from './_styles/FilterElement.module.scss';
import elementStyles from './_styles/FilterElementRangeInput.module.scss';

const FilterElementRangeInput = (props) => {

  // Constants
  const TIMEOUT_DURATION = 5*1000; // 5 seconds

  // Prop
  const {
    config,
    filters = {},
    isBuylist,
    removeFilter,
    setFilter,
  } = props;

  // State
  const [expanded, setExpanded] = useState(true);

  // Internationalization
  const { t } = useTranslation();

  // Form
  const { 
    inputPrice: inputPriceMin, 
    errorPrice: errorPriceMin, 
    changePrice: changePriceMin, 
    setPrice: setPriceMin,
    isValidPrice: isValidPriceMin,
  } = useFormValidationPrice(filters[getFilterMinKey(config, { isBuylist: !!isBuylist })]);

  const { 
    inputPrice: inputPriceMax, 
    errorPrice: errorPriceMax, 
    changePrice: changePriceMax, 
    setPrice: setPriceMax,
    isValidPrice: isValidPriceMax,
  } = useFormValidationPrice(filters[getFilterMaxKey(config, { isBuylist: !!isBuylist })]);

  // Refs
  const timeoutMinRef = useRef(null);
  const timeoutMaxRef = useRef(null);

  // Previous
  const prevFilters = usePrevious(filters);

  // Methods
  const submitMinValue = (val = null) => {

    const key = getFilterMinKey(config, { isBuylist: !!isBuylist });
    const submitValue = val === null ? inputPriceMin : val;

    if(submitValue === '') {
      removeFilter(key);
    } else if(isValidPriceMin(submitValue, { hideError: true, max: inputPriceMax })) {
      
      try {
        if(parseFloat(submitValue) !== parseFloat(filters[key])) {

          setFilter({
            [key]: safeParseFloat(submitValue),
          })
        }
      } catch(err) {
        console.error(err);
      }
    }

    clearTimeout(timeoutMinRef.current);
  };

  const handleChangeMin = (evt) => {

    changePriceMin(evt, { max: inputPriceMax });

    clearTimeout(timeoutMinRef.current);
    timeoutMinRef.current = setTimeout(() => submitMinValue(evt.target.value), TIMEOUT_DURATION);
  };

  const handleBlurMin = () => {
    if(isValidPriceMin(inputPriceMin, { hideError: false, allowEmpty: true, normalize: true, max: inputPriceMax })) {
      submitMinValue();
    } else if(inputPriceMin > inputPriceMax) {
      setPriceMin(inputPriceMax, { max: inputPriceMax });
      submitMinValue(inputPriceMax);
    }
  };

  const handleKeyPressMin = (evt) => {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      simulateTab();
    }
  };

  const submitMaxValue = (val = null) => {

    const key = getFilterMaxKey(config, { isBuylist: !!isBuylist });
    const submitValue = val === null ? inputPriceMax : val;

    if(submitValue === '') {
      removeFilter(key);
    } else if(isValidPriceMax(submitValue, { hideError: true, min: inputPriceMin })) {
      
      try {

        if(parseFloat(submitValue) !== parseFloat(filters[key])) {
          setFilter({
            [key]: safeParseFloat(submitValue),
          })
        }
      } catch(err) {
        console.error(err);
      }
    }

    if(timeoutMaxRef.current) {
      clearTimeout(timeoutMaxRef.current);
    }
  };

  const handleChangeMax = (evt) => {

    changePriceMax(evt, { min: inputPriceMin });

    clearTimeout(timeoutMaxRef.current);
    timeoutMaxRef.current = setTimeout(() => submitMaxValue(evt.target.value), TIMEOUT_DURATION);
  };

  const handleBlurMax = () => {
    if(isValidPriceMax(inputPriceMax, { hideError: false, allowEmpty: true, normalize: true, min: inputPriceMin })) {
      submitMaxValue();
    } else if(inputPriceMax < inputPriceMin) {
      setPriceMax(inputPriceMin, { min: inputPriceMin });
      submitMaxValue(inputPriceMin);
    }
  };

  const handleKeyPressMax = (evt) => {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      simulateTab();
    }
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Effects
  useEffect(() => {
    return () => {
      clearTimeout(timeoutMinRef.current);
      clearTimeout(timeoutMaxRef.current);
    };
  }, []);

  useEffect(() => {
    if(!_.isEqual(prevFilters, filters)) {
      setPriceMin(filters[getFilterMinKey(config, { isBuylist: !!isBuylist })] || '');
      setPriceMax(filters[getFilterMaxKey(config, { isBuylist: !!isBuylist })] || '');
    }
  }, [ config, filters, isBuylist, prevFilters, setPriceMin, setPriceMax ]);

  return (
    <div className={`${filterStyles.FilterElement} ${elementStyles.FilterElementRangeInput}`}>
      <div className={filterStyles.feFilterTitle}>
        <div className={filterStyles.feFilterTitleValue}>{t(config.label)}</div>
        <div className={filterStyles.feFilterTitleToggle} onClick={toggleExpanded}>
          <div className={`${filterStyles.feFilterTitleToggleElement} FlexCenter`}>
            {expanded ? <>&mdash;</> : <>+</>}
          </div>
        </div>
      </div>
      <div className={`${filterStyles.feFilterBody} ${expanded ? filterStyles.open : ''}`}>
        <div className={filterStyles.feFilterBodyLiner}>
          <div className={filterStyles.feFilterBodyLinerScroll}>
            <div className={elementStyles.rangeInput}>
              <div className={`${elementStyles.minMaxWrapper} ${elementStyles.minWrapper}`}>
                <div className={elementStyles.minMaxInput}>
                  <input
                    type='number'
                    className={errorPriceMin ? elementStyles.error : ''}
                    name={getFilterMinLabel(config, { isBuylist: !!isBuylist })}
                    value={inputPriceMin}
                    step={getCurrencyIncrement()}
                    min={0}
                    placeholder={t(TX_MIN)}
                    onChange={handleChangeMin}
                    onBlur={handleBlurMin}
                    onKeyPress={handleKeyPressMin} />
                  <div className={elementStyles.currencyOverlay}>{getCurrencySymbol()}</div>
                  {errorPriceMin ?
                    <div className={elementStyles.errorCopy}>{t(errorPriceMin)}</div> :
                    null
                  }
                </div>
              </div>
              <div className={elementStyles.separatorWrapper}>&mdash;</div>
              <div className={`${elementStyles.minMaxWrapper} ${elementStyles.maxWrapper}`}>
                <div className={elementStyles.minMaxInput}>
                  <input
                    type='number'
                    className={errorPriceMax ? elementStyles.error : ''}
                    name={getFilterMaxLabel(config, { isBuylist: !!isBuylist })}
                    value={inputPriceMax}
                    step={getCurrencyIncrement()}
                    min={0}
                    placeholder={t(TX_MAX)}
                    onChange={handleChangeMax}
                    onBlur={handleBlurMax}
                    onKeyPress={handleKeyPressMax} />
                  <div className={elementStyles.currencyOverlay}>{getCurrencySymbol()}</div>
                  {errorPriceMax ?
                    <div className={elementStyles.errorCopy}>{t(errorPriceMax)}</div> :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterElementRangeInput;
