

import { 
	ID_TITAN_HEADER, 
	ID_TITAN_NOTICE, 
} from '../constants/dom';



export const domHeaderHeight = () => {
	try {
		return document.getElementById(ID_TITAN_HEADER)?.getBoundingClientRect()?.height || 0;
	} catch(err) {
		return 0;
	}
}

export const domNoticeHeight = () => {
	try {
		return document.getElementById(ID_TITAN_NOTICE)?.getBoundingClientRect()?.height || 0;
	} catch(err) {
		return 0;
	}
}

