import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

// import { ShippingParcel } from '../../../../models/shipping-methods';

import { 
  getStoreDefaultDistanceUnit,
  getStoreDefaultWeightUnit,
} from '../../../../utils/general';
import { dynamicSort } from '../../../../utils/sort';

import IntegrationConfigParcelSizeRow from './IntegrationConfigParcelSizeRow';

const allActionCreators = Object.assign({});

export class IntegrationConfigParcelSize extends Component {

  constructor(props) {
    super(props);

    this.distanceUnit = getStoreDefaultDistanceUnit();
    this.weightUnit = getStoreDefaultWeightUnit();

    this.state = {
      
      addRowOpen: false,

      parcels: this.getConfigValue(),
    };
  }

  getConfigValue() {

    const { ShippingParcel } = require('../../../../models/shipping-methods');

    const respArray = [];
    const configArray = this.props.config && this.props.config.value ? this.props.config.value : [];

    for(const parcelJson of configArray) {
      respArray.push(new ShippingParcel(parcelJson));
    }
    return respArray;
  }

  openAddRow() {
    this.setState({ addRowOpen: true });
  }

  closeAddRow() {
    this.setState({ addRowOpen: false });
  }

  setParcel(parcel, idx, cb) {
    
    const newParcels = [];
    const saveData = [];

    this.getOrderedParcels().forEach((par, i) => {

      if(parcel === null && idx === i) { return; }

      if(idx === i) {
        saveData.push(parcel.toJson());
        newParcels.push(parcel);
      } else {
        saveData.push(par.toJson());
        newParcels.push(par);
      }
    });
    if(idx === null && parcel !== null) {
      saveData.push(parcel.toJson());
      newParcels.push(parcel);
    }

    // Call config
    this.props.setConfigAttribute(this.props.config, saveData);

    this.setState({ parcels: newParcels }, () => {
      if(cb) { cb(); }
    });
  }

  getOrderedParcels() {
    if(!this.state.parcels) { return []; }

    const allParcels = [ ...this.state.parcels ];

    return allParcels.sort(dynamicSort('minWeight'));
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationConfigParcelSize'}>
      <div className='parcelSizeLiner'>
        {this.state.parcels.length > 0 ?
          <>
            <div className='parcelSizeHeaderWrapper'>
              <div className='parcelSizeHeader'>
                <div className='headerLabel weightLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenterLeft'>{t(tx.TX_INTEGRATION_CONFIG_LABEL_PARCEL_WEIGHT, { unit: this.weightUnit.display })}</div>
                  </div>
                </div>
                <div className='headerLabel dimentionsLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenter'>{t(tx.TX_DIMENSIONS_UNIT, { unit: this.distanceUnit.display })}</div>
                  </div>
                </div>
                <div className='headerLabel actionsLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenterLeft'></div>
                  </div>
                </div>
              </div>
            </div>
            {this.getOrderedParcels().map((parcel, i) => {
              return <div key={i} className='parcelWrapper'>
                <IntegrationConfigParcelSizeRow
                  idx={i}
                  row={parcel}
                  saveParcel={this.setParcel.bind(this)} />
              </div>;
            })}
          </> :
          <>
            {this.state.addRowOpen === false ?
              <div className='parcelSizeNullWrapper'>
                <div className='parcelSizeNull'>
                  <div className='parcelSizeNullValue'>{t(tx.TX_NONE)}</div>
                </div>
              </div> :
              null
            }
          </>
        }
        {this.state.addRowOpen ?
          <div className='parcelEmptyRowWrapper'>
            <div className='parcelWrapper'>
              <IntegrationConfigParcelSizeRow
                idx={null}
                createDefault={this.state.parcels.length === 0}
                row={{}}
                saveParcel={this.setParcel.bind(this)}
                parentCloseMethod={this.closeAddRow.bind(this)} />
            </div>
          </div> :
          <div className='parcelAddWrapper'>
            <div className='parcelAdd'>
              <div className='parcelToggle' onClick={this.openAddRow.bind(this)}>{t(tx.TX_SETTINGS_INTEGRATIONS_PARCEL)}</div>
            </div>
          </div>
        }
        <div className='parcelCopy'>{t(tx.TX_SETTINGS_INTEGRATIONS_PARCEL_COPY)}</div>
        <div className='parcelCopy'>{t(tx.TX_SETTINGS_INTEGRATIONS_PARCEL_COPY_2)}</div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationConfigParcelSize));