import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import { breaksToBrs } from '../../../utils/formatting';

let allActionCreators = Object.assign({});

export class ProductPageDetailsStarWars extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getProductName() {
    return this.props.productObj.foreignModel.name;
  }

  getProductTypeLine() {
    // return '';
    return this.props.productObj.foreignModel.type ? this.props.productObj.foreignModel.type.name : '';
  }

  getProductDescription() {

    const { breaksToBrs } = require('../../../utils/formatting');

    const txt = this.props.productObj.foreignModel.bodyText;
    return breaksToBrs(txt, this.descriptionBreakStyle);
  }

  render() {

    if(!this.props.productObj || !this.props.productObj.foreignModel || !this.props.productObj.foreignModel.isStarwarsCard) { return null; }

    return <div className={'ProductPageDetailsStarWars ProductPageDetails'}>
      <div className='productPageDetailsWrapper'>
        <div className='ppStarwarsWrapper' lang={this.props.productObj.foreignModel.language}>
          <div className='ppStarwarsFaceWrapper'>
            <div className='ppHeaderWrapper'>
              <div className='ppmTitle' lang={this.props.productObj.foreignModel.language}>
                <span className='ppmTitleText'>{this.getProductName()}</span>
              </div>
              <div className='ppmTypeLine'>{this.getProductTypeLine()}</div>
            </div>
            <div className='ppBorderWrapper'>
              <div className='ppStarwarsDetails'>
                <div 
                  className='ppmDescription'
                  dangerouslySetInnerHTML={{ __html: this.getProductDescription() }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetailsStarWars));