


import { 
	FILTERS_ALL, 
	FV_TYPE_RANGE_INPUT,
	FV_TYPE_TEXT_INPUT,
	TV_VALUE_DISPLAY_TYPE_PRICE,
} from '../constants/filters';
import { TX_null } from '../constants/strings';




export function getFilterPlaceholder(filterConfig) {
	return filterConfig.placeholder ? filterConfig.placeholder : '';
}

export function getFilterDontTranslateTags(filterConfig) {
	return filterConfig.noTranslate ? filterConfig.noTranslate : false;
}

export function hasStaticFilterOptions(filterConfig) {
	// Want to return exactly true/false, not true-ish crap
	return filterConfig.options ? true : false;
}

export function getStaticFilterOptions(filterConfig) {
	return filterConfig.options ? filterConfig.options : [];
}

export function normalizeFilterValuesConditions(conditionsArray, isBuylist = false) {
	const valuesArray = [];
	for(const cd of conditionsArray) {

		if(isBuylist && cd.buylistExclude === true) {
			continue;
		}

		valuesArray.push({
			display: cd['name'],
			value: cd['value'],
			count: null,
		});
	}
	return valuesArray;
}

export function normalizeFilterValuesFinish(finishArray) {
	const valuesArray = [];
	for(const fn of finishArray) {
		valuesArray.push({
			display: fn['name'],
			value: fn['key'],
			count: null,
		});
	}
	return valuesArray;
}

export function normalizeFilterValuesProductLines(productLinesArray) {
	const valuesArray = [];
	for(const pl of productLinesArray) {
		valuesArray.push({
			display: pl['name'],
			value: pl['permalink'],
			count: null,
		});
	}
	return valuesArray;
}

export function normalizeFilterValuesLanguages(languagesArray) {
	const valuesArray = [];
	for(const ln of languagesArray) {
		valuesArray.push({
			display: ln['nameTranslation'],
			value: ln['code'],
			count: null,
		});
	}
	return valuesArray;
}

export function getFilterConfigByKey(filterKey) {
	if(!filterKey) {
		return null;
	}
	for(const filterConfig of FILTERS_ALL) {
		if(filterConfig.key === filterKey || getFilterConfigKeys(filterConfig).includes(filterKey)) {
			return filterConfig;
		}
	}
	return null;
}

export function isInputFilter(filterConfig) {
	
	if(!filterConfig) { return false; }

	const inputTypes = [
		FV_TYPE_RANGE_INPUT,
		FV_TYPE_TEXT_INPUT,
	];
	return inputTypes.includes(filterConfig.type)
}

export function getFilterMinKey(filterConfig, config = {}) {
	
	if(!filterConfig || !filterConfig.keyMin) { return null; }

	// Configs
	// isBuylist, boolean, default false

	const isBuylist = config.isBuylist || false;

	const altConfig = filterConfig.altConfig && filterConfig.altConfig.isBuylist && isBuylist ? filterConfig.altConfig.isBuylist : {};

	return altConfig.keyMin || filterConfig.keyMin;
}

export function getFilterMaxKey(filterConfig, config = {}) {
	
	if(!filterConfig || !filterConfig.keyMax) { return null; }

	// Configs
	// isBuylist, boolean, default false

	const isBuylist = config.isBuylist || false;

	const altConfig = filterConfig.altConfig && filterConfig.altConfig.isBuylist && isBuylist ? filterConfig.altConfig.isBuylist : {};

	return altConfig.keyMax || filterConfig.keyMax;
}

export function getFilterConfigKeys(filterConfig, config = {}) {
	
	if(!filterConfig) { return []; }

	const filterKeys = [];

	const minKey = getFilterMinKey(filterConfig, config);
	const maxKey = getFilterMaxKey(filterConfig, config);

	if(minKey) {
		filterKeys.push(minKey);
	}

	if(maxKey) {
		filterKeys.push(maxKey);
	}

	if(filterKeys.length === 0) {
		filterKeys.push(filterConfig.key);
	}
	return filterKeys;
}

export function getFilterMinLabel(filterConfig, config = {}) {
	
	if(!filterConfig || !filterConfig.labelMin) { return TX_null; }

	// Configs
	// isBuylist, boolean, default false

	const isBuylist = config.isBuylist || false;

	const altConfig = filterConfig.altConfig && filterConfig.altConfig.isBuylist && isBuylist ? filterConfig.altConfig.isBuylist : {};

	return altConfig.labelMin || filterConfig.labelMin;
}

export function getFilterMaxLabel(filterConfig, config = {}) {
	
	if(!filterConfig || !filterConfig.labelMax) { return TX_null; }

	// Configs
	// isBuylist, boolean, default false

	const isBuylist = config.isBuylist || false;

	const altConfig = filterConfig.altConfig && filterConfig.altConfig.isBuylist && isBuylist ? filterConfig.altConfig.isBuylist : {};

	return altConfig.labelMax || filterConfig.labelMax;
}

export function getFormattedDisplayValue(displayValue, filterConfig, displayConfig = {}) {
	
	if(!filterConfig.displayType) {
		return displayValue;
	}

	switch(filterConfig.displayType) {
		case TV_VALUE_DISPLAY_TYPE_PRICE:
			const { formatPrice } = require('./formatting');
			return formatPrice(displayValue, displayConfig);
		default:
			return displayValue;
	}
}





