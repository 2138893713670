import React from 'react';

import { ContentBanner } from '../../../../models/layouts/content/banners';

import BannerCarousel from './elements/BannerCarousel';
import BannerSlide from './elements/BannerSlide';

import styles from './_styles/BlockContentBanner.module.scss';

// Block configs; these should eventually be defined in TS once Next migration complete

  // defaultConfig: {
  //   banners: [
  //     {
  //       backgroundAlt: '',
  //       backgroundUrl: '',
  //       backgroundUrlTablet: '',
  //       backgroundUrlMobile: '',
  //       linkSrc: '',
  //       linkFilters: {},
  //     },
  //   ],
  //   carouselConfig: {
  //     autoplaySpeed: 5000,
  //     slideSpeed: 25,
  //     stopOnInteraction: false,
  //     loop: true,
  //     variableStart: false,
  //   },
  // },


export const BlockContentBanner = (props) => {
  
  // Props
  const {
    block,
  } = props;

  const getInitIndex = () => {

    const carouselConfig = block.getConfigAttr('carouselConfig') || {};
    const variableStart = carouselConfig.variableStart || false;

    return variableStart ? 0 : 0;
  };

  const getOrderedBanners = () => {
    
    const bannerPropsArray = block.getConfigAttr('banners') || [];
    const initIndex = getInitIndex();

    const reorderedArray = [
      ...bannerPropsArray.slice(initIndex),
      ...bannerPropsArray.slice(0, initIndex),
    ];

    return reorderedArray.map((elem) => new ContentBanner(elem));
  };

  const getEmblaOptions = () => {
    const carouselConfig = block.getConfigAttr('carouselConfig') || {};
    return { 
      loop: carouselConfig.loop || false, 
      duration: carouselConfig.slideSpeed || 40,
    };
  }

  const getEmblaAutoplayOptions = () => {
    const carouselConfig = block.getConfigAttr('carouselConfig') || {};
    return { 
      delay: carouselConfig.autoplaySpeed || 4000, 
      stopOnInteraction: carouselConfig.stopOnInteraction || false,
    };
  }

  // Banners; return null if no banners
  const banners = getOrderedBanners();
  if(banners.length === 0) {
    return null;
  }

  // Render
  return (
    <div className={`${styles.BlockContentBanner}`}>
      {banners.length === 1 ?
        <BannerSlide banner={banners[0]} /> :
        <BannerCarousel banners={banners} options={getEmblaOptions()} autoplayOptions={getEmblaAutoplayOptions()} />
      }
    </div>
  );
};

export default BlockContentBanner;




