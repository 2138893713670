import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import {
//   checkoutPrereqMet, 
//   isStepComplete, 
//   isStepValid, 
// } from '../../../../../utils/checkout';

import CheckoutStepBuylistConfirmInput from './CheckoutStepBuylistConfirmInput';

let allActionCreators = Object.assign({});

export class CheckoutStepBuylistConfirm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      lastUuid: null,
    }
  }

  setNewUuid(uuidValue) {
    this.setState({ lastUuid: uuidValue });
  }

  getStepComponent() {

    const {
      checkoutPrereqMet, 
      isStepComplete, 
      isStepValid, 
    } = require('../../../../../utils/checkout');

    // const { CheckoutStepBuylistConfirmInput } = require('./CheckoutStepBuylistConfirmInput').default;
    
    // If pre-requisites not met, do not display
    if(checkoutPrereqMet(this.props.config, this.props.checkout.stepData) === false) {
      return null;
    }

    if(isStepValid(this.props.config, this.props.checkout.stepData) && isStepComplete(this.props.config, this.props.checkout.stepData)) {
      return null;
    }
    return <CheckoutStepBuylistConfirmInput 
            config={this.props.config}
            setStepData={this.props.setStepData} />;
  }

  render() {
    return <div className={'CheckoutStepBuylistConfirm CheckoutStepComponent'}>{this.getStepComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepBuylistConfirm));