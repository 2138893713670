



import { 
	API_FOREIGN_MODEL_MAGIC_CARD,
	API_FOREIGN_MODEL_MAGIC_SET, 
} from '../../constants/api';
import { CONDITIONS_PRODUCT_TCG } from '../../constants/conditions';
import { FINISHES_MAGIC } from '../../constants/finishes';
// import { 
// 	PROD_PAGE_LAYOUT_CLASS_TCG,
// 	PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG,
// 	PROD_SKU_MANAGED_DEMARKATION, 
// } from '../../constants/product';
import { 
	TX_INV_PL_MAGIC,
	TX_MAGIC_CARD, 
} from '../../constants/strings';
import { 
	MAGIC_LAYOUT_DOUBLE_SIDED,
	MAGIC_CARD_SKU_PREFIX, 
} from '../../constants/syncs/magic';

import { 
	CatalogTag,
	Product,
	ProductForeignModel,
	ProductSet,
} from '../products';

import { getFinishObjectFromKey } from '../../utils/finish';
// import { dbStringToArray } from '../../utils/formatting';
import { getLanguageObjectFromScryfallCode } from '../../utils/language';

import MagicImage from '../../components/Magic/MagicImage';
import MagicThumbnail from '../../components/Gallery/blocks/elements/MagicThumbnail';
import ProductPageDetailsMagic from '../../components/ProductPage/blocks/ProductPageDetailsMagic';



export class MagicCard extends ProductForeignModel {

	constructor(props = {}) {

		super(props);

		const { dbStringToArray } = require('../../utils/formatting');

		const artistIds = props.artistIds || props.artist_ids || [];
		const colorIdentity = props.colorIdentity || props.color_identity || [];
		const frameEffects = props.frameEffects || props.frame_effects || [];

		this.id = props.id || null;
		this.tcgplayerId = props.tcgplayerId || props.tcgplayer_id || null;

		this.artist = props.artist || '';
		this.artistIds = dbStringToArray(artistIds);
		this.booster = props.booster || false;
		this.borderColor = props.borderColor || props.border_color || false;
		this.cardBackId = props.cardBackId || props.card_back_id || '';
		this.cmc = props.cmc || 0;
		this.collectorNumber = props.collectorNumber || props.collector_number || '';
		this.colorIdentity = dbStringToArray(colorIdentity);
		this.colors = dbStringToArray(props.colors);
		this.digital = props.digital || false;
		this.edhRank = props.edhRank || props.edh_rank || null;
		this.finishes = dbStringToArray(props.finishes);
		this.flavorText = props.flavorText || props.flavor_text || '';
		this.frame = props.frame || '';
		this.frameEffects = dbStringToArray(frameEffects)
		this.fullArt = props.fullArt || props.full_art || false;
		this.games = dbStringToArray(props.games);
		this.highresImage = props.highresImage || props.highres_image || false;
		this.illustrationId = props.illustrationId || props.illustration_id || '';
		this.language = props.language || props.lang || '';
		this.layout = props.layout || '';
		this.magicId = props.magicId || props.magic_id || '';
		this.manaCost = props.manaCost || props.mana_cost || '';
		this.name = props.name || '';
		this.oracleText = props.oracleText || props.oracle_text || '';
		this.oversized = props.oversized || false;
		this.printedName = props.printedName || props.printed_name || '';
		this.printedText = props.printedText || props.printed_text || '';
		this.printedTypeLine = props.printedTypeLine || props.printed_type_line || '';
		this.promo = props.promo || false;
		this.rarity = props.rarity || '';
		this.releasedAt = props.releasedAt || props.released_at || '';
		this.reprint = props.reprint || false;
		this.reserved = props.reserved || false;
		this.setName = props.setName || props.set_name || '';
		this.storySpotlight = props.storySpotlight || props.story_spotlight || false;
		this.textless = props.textless || false;
		this.typeLine = props.typeLine || props.type_line || '';
		this.variation = props.variation || false;

		this.face1 = props.face1 ? new MagicCardFace(props.face1) : null;
		this.face2 = props.face2 ? new MagicCardFace(props.face2) : null;

		this.images = new MagicCardImages(props.images);

		this.tags = [];
		const tagsArray = props.tags || [];
		for(const tg of tagsArray) {
			this.tags.push(new CatalogTag(tg));
		}
	}

	get isMagicCard() {
		return true;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_MAGIC_CARD;
	}

	get localizedName() {
		return this.printedName ? this.printedName : this.name;
	}

	get localizedText() {
		return this.printedText ? this.printedText : this.oracleText;
	}

	get localizedTypeLine() {
		return this.printedTypeLine ? this.printedTypeLine : this.typeLine;
	}

	get doubleSided() {
		return MAGIC_LAYOUT_DOUBLE_SIDED.includes(this.layout);
	}

	get multipleFaces() {
		return this.face1 !== null && this.face2 !== null;
	}

	get languageObj() {
		return getLanguageObjectFromScryfallCode(this.language);
	}

	get allowSealed() {
		return false;
	}

	get alwaysDisplayLanguage() {
		return true;
	}

	get allowOrphanCreation() {
		return true;
	}

	get exportInventoryDetails() {
		return true;
	}

	nameWithTags(t = null) {
		
		let nameString = this.name;
		if(!t) {
			return nameString;
		}

		for(const tag of this.tags) {
			if(tag.shouldDisplay) {
				nameString = `${nameString} (${tag.nameTranslationKey ? t(tag.nameTranslationKey) : tag.name})`;
			}
		}
		return nameString;
	}

	hasFinish() {
		return true;
	}

	allFinishes() {
		if(!this.finishes || !this.finishes.length) { return FINISHES_MAGIC; }
		
		const respArray = [];
		for(const fn of this.finishes) {
			respArray.push(getFinishObjectFromKey(fn));
		}
		return respArray;
	}

	allConditions() {
		return CONDITIONS_PRODUCT_TCG;
	}

	getSetName() {
		return this.setName;
	}

	primaryImageSrc(isBack = false) {
		if(this.doubleSided) {
			if(isBack === false) {
				if(this.face1) {
					return this.face1.images.primarySrc;
				}
			} else {
				if(this.face2) {
					return this.face2.images.primarySrc;
				}
			}
		} else {
			return this.images.primarySrc;	
		}
		return MagicCardImages.defaultPrimarySrc();
	}

	thumbnailImageSrc(isBack = false) {
		if(this.doubleSided) {
			if(isBack === false) {
				if(this.face1) {
					return this.face1.images.thumbnailSrc;
				}
			} else {
				if(this.face2) {
					return this.face2.images.thumbnailSrc;
				}
			}
		} else {
			return this.images.thumbnailSrc;
		}
		return MagicCardImages.defaultThumbnailSrc();
	}

	getPrimaryImage(props = {}) {
		return <MagicImage 
		          magicObj={this}
		          loadDelay={0}
		          {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <MagicImage 
		          magicObj={this}
		          loadDelay={0}
		          noFlip={true}
		          thumbnail={true}
		          {...props} />;
	}

	imageAlt(t = null) {
	
		const cardName = this.localizedName;
		const setName = this.setName;

		if(!cardName) {
			return t ? t(TX_MAGIC_CARD) : '';
		}

		const respArray = [ cardName ];
		if(setName) {
			respArray.push(setName);
		}
		if(t) {
			respArray.push(t(TX_INV_PL_MAGIC));
		}

		return respArray.join(' | ');
	}

	get componentThumbnailGallery() {
		return MagicThumbnail;
	}

	get componentProductPageDetails() {
		// const ProductPageDetailsMagic = require('../../components/ProductPage/blocks/ProductPageDetailsMagic').default;
		return ProductPageDetailsMagic;
	}

	get productPageLayoutClass() {
		const { PROD_PAGE_LAYOUT_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_CLASS_TCG;
	}

	get productPageDetailLayoutClass() {
		const { PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG;
	}

	createProduct(productLine) {
		return new Product({
			sku: this.productSku,
	    name: this.name,
	    permalink: this.productPermalink,
	    description: '',
	    isEnabled: true,
	    productLine: productLine,

	    foreignModelCode: this.foreignModelCode,
			foreignModel: this,
		});
	}

	get productSku() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');

		const idBase = '000000000';
		const idString = this.id.toString();
		const leadingZeros = idBase.substring(0, idBase.length - idString.length);

		return `${MAGIC_CARD_SKU_PREFIX}${PROD_SKU_MANAGED_DEMARKATION}${leadingZeros}${idString}`.toLowerCase();
	}

	get productPermalink() {
		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');
		return this.productSku.replaceAll(PROD_SKU_MANAGED_DEMARKATION, '');
	}
}


export class MagicSet extends ProductSet {

	constructor(props = {}) {

		super(props);
		
		this.id = props.id || null;
		this.code = props.code || this.code || '';
		this.name = props.name || this.name || '';
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_MAGIC_SET;
	}

	get lookupKey() {
		return this.code;
	}

	set lookupKey(val) {
		this.code = val;
	}

	toOption(config = {}) {

		const idValue = config.idValue || false;
		const selfValue = config.selfValue || false;

		let value = this.code;
		if(idValue) {
			value = this.id;
		} else if(selfValue) {
			value = this;
		}

		return {
			display: this.name,
			value: value,
			count: null,
		};
	}
}


export class MagicSealed {

	constructor(props = {}) {
		this.id = props.id || null;
	}
}


export class MagicCardFace {

	constructor(props = {}) {

		const { dbStringToArray } = require('../../utils/formatting');

		this.artist = props.artist || '';
		this.colorIndicator = props.colorIndicator || props.color_indicator || '';
		this.colors = dbStringToArray(props.colors);
		this.flavorText = props.flavorText || props.flavor_text || '';
		this.illustrationId = props.illustrationId || props.illustration_id || '';
		this.loyalty = props.loyalty || '';
		this.manaCost = props.manaCost || props.mana_cost || '';
		this.name = props.name || '';
		this.oracleText = props.oracleText || props.oracle_text || '';
		this.power = props.power || '';
		this.printedName = props.printedName || props.printed_name || '';
		this.printedText = props.printedText || props.printed_text || '';
		this.printedTypeLine = props.printedTypeLine || props.printed_type_line || '';
		this.toughness = props.toughness || '';
		this.typeLine = props.typeLine || props.type_line || '';
		this.watermark = props.watermark || '';

		this.images = new MagicCardImages(props.images || props);
	}

	get localizedName() {
		return this.printedName ? this.printedName : this.name;
	}

	get localizedText() {
		return this.printedText ? this.printedText : this.oracleText;
	}

	get localizedTypeLine() {
		return this.printedTypeLine ? this.printedTypeLine : this.typeLine;
	}
}


export class MagicCardImages {

	constructor(props = {}) {
		this.imageArtCrop = props.imageArtCrop || props.image_art_crop || '';
		this.imageBorderCrop = props.imageBorderCrop || props.image_border_crop || '';
		this.imageLarge = props.imageLarge || props.image_large || '';
		this.imageNormal = props.imageNormal || props.image_normal || '';
		this.imagePng = props.imagePng || props.image_png || '';
		this.imageSmall = props.imageSmall || props.image_small || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/magiccard/mtgback.png';
	}

	static defaultThumbnailSrc() {
		return '/img/product/magiccard/mtgback_small.png';
	}

	get primarySrc() {
		return this.imagePng || MagicCardImages.defaultPrimarySrc();
	}

	get thumbnailSrc() {
		return this.imageSmall || MagicCardImages.defaultThumbnailSrc();
	}
}








