import React, { useCallback } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import AutoHeight from 'embla-carousel-auto-height';
import useEmblaCarousel from 'embla-carousel-react';

import { DotButton, useDotButton } from './BannerCarouselDotButton';
import BannerSlide from './BannerSlide';

import styles from './_styles/BannerCarousel.module.scss';

const BannerCarousel = (props) => {

  const { 
    banners, 
    options, 
    autoplayOptions,
  } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay(autoplayOptions), AutoHeight()]);

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop

    resetOrStop()
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  );

  const bannerLoaded = () => {
    emblaApi?.reInit(options, [Autoplay(autoplayOptions), AutoHeight()]);
  }

  return (
    <section className={styles.BannerCarousel}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {banners.map((banner, i) => (
            <div key={i} className={styles.slideWrapper}>
              <BannerSlide banner={banner} onLoad={bannerLoaded} />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.controls}>
        <div className={styles.dots}>
          {scrollSnaps.map((_, j) => (
            <DotButton
              key={j}
              onClick={() => onDotButtonClick(j)}
              className={`${styles.dot} ${j === selectedIndex ? styles.selected : ''}`} />
          ))}
        </div>
      </div>
    </section>
  )
};

export default BannerCarousel;




